import {NotificationManager} from 'react-notifications';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Card, Heading} from '../common';
import {db} from '../../firebase';
import {getFullName} from '../../helpers/getFullName';
import AccountTabs from './tabs';
import Billing from './billing';
import withAuthorization from '../session/withAuthorization';

function PaymentPage({authUser}) {
  const [, setSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [stripe, setStripe] = useState({});

  const [stripeCard, setStripeCard] = useState({
    name: '',
    email: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'AU',
    },
    creditCard: 'XXXX XXXX XXXX XXXX',
    creditExp: 'MM / YY',
    creditCVC: 'CVC',
  });

  const creditCard = stripeCard.last4 ? `XXXX XXXX XXXX ${stripeCard.last4}` : stripeCard.creditCard;
  const creditExpMonth = ((stripeCard.exp_month ? stripeCard.exp_month : stripeCard.creditExp) || '').toString().substr(0, 2).padStart(2, '0');
  const creditExpYear = ((stripeCard.exp_year ? stripeCard.exp_year : stripeCard.creditExp) || '').toString().substr(-2).padStart(2, '0');

  const onAddressChange = event => {
    const {address} = stripeCard;
    console.log(address);
    const nextAddress = {...address};
    // eslint-disable-next-line fp/no-mutation
    nextAddress[event.target.name] = event.target.value;

    setStripeCard({
      ...stripeCard,
      address: nextAddress,
    });
  };

  const onInputChange = event => {
    const nextState = {...stripeCard};
    // eslint-disable-next-line fp/no-mutation
    nextState[event.target.name] = event.target.value;
    setStripeCard(nextState);
  };

  const onCreditCardChange = (event, newStripe) => {
    setStripe(newStripe);
  };

  const onSubscriptionSubmit = async values => {
    if (typeof stripe === 'object' && typeof stripe.createToken === 'function') {
      setSaving(true);

      // Tokenise the credit card with Stripe
      stripe
        .createToken()
        .then(response => {
          if (response.error) {
            NotificationManager.error(response.error.message);
            setSaving(false);
          } else {
            // Save the credit card to the database
            db.updateStripeCustomers(authUser.uid, {
              name: stripeCard.name,
              email: stripeCard.email,
              address: stripeCard.address,
              sources: response.token,
            })
              .then(() => {
                NotificationManager.success('Your billing details have been updated.');
                setSaving(false);
                setModalOpen(false);
              })
              .catch(error => {
                NotificationManager.error(error);
                setSaving(false);
              });
          }
        })
        .catch(error => {
          NotificationManager.error(error);
          setSaving(false);
        });
    } else {
      NotificationManager.error('Payments are not available at this time. Please try again late.');
      setSaving(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(stripeCard.email)) return;

    // Fetch the existing billing information from the database
    db.getStripeCustomers(authUser.uid, snapshot => {
      const newStripeCustomer = snapshot.val() || {};
      const newStripeCard = newStripeCustomer && newStripeCustomer.sources && newStripeCustomer.sources.card ? newStripeCustomer.sources.card : {};

      const newStripeCardFormatted = {
        ...stripeCard,
        ...newStripeCard,
        name: newStripeCustomer.name || getFullName(authUser),
        email: newStripeCustomer.email || authUser.email,
        address: newStripeCustomer.address || {},
      };

      // If billing information has been saved previously, set into state.
      if (!isEqual(stripeCard, newStripeCardFormatted)) setStripeCard(newStripeCardFormatted);
    });
  }, [authUser, authUser.uid, stripeCard]);

  return (
    <Card solo>
      <Heading primary>
        <h4>Account Payments</h4>
      </Heading>
      <AccountTabs />
      <Billing
        stripeCard={stripeCard}
        creditCard={creditCard}
        creditExpMonth={creditExpMonth}
        creditExpYear={creditExpYear}
        onSubscriptionSubmit={onSubscriptionSubmit}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        onCreditCardChange={onCreditCardChange}
        onInputChange={onInputChange}
        onAddressChange={onAddressChange}
        canEdit
      />
    </Card>
  );
}

PaymentPage.propTypes = {
  authUser: PropTypes.shape({
    uid: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

PaymentPage.defaultProps = {
  authUser: {
    uid: '',
    email: '',
    firstName: '',
    lastName: '',
  },
};

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(PaymentPage);
