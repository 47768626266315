/* eslint-disable fp/no-mutating-methods */
/* eslint-disable react/prop-types */
import React from 'react';
import '../../assets/scss/components/post/post.scss';

function PostGoalMentioned({data, profile}) {
  if (data.goalCompleted || !data.goals || !data.steps || data.newGoal) {
    return null;
  }
  const steps = [];
  const goals = Object.keys(data.goals);
  goals.forEach(goalId => {
    const goal = profile.goals[goalId];
    steps.push({id: goalId, name: goal.name});
    Object.keys(goal?.steps || {}).forEach(index => {
      const {id} = goal.steps[index];
      if (data.steps[id]) {
        steps.push({id, ...goal.steps[index]});
      }
    });
    // Remove goal if no steps are found
    if (steps[steps.length - 1].name) {
      steps.pop();
    }
  });
  if (!steps.length) {
    return null;
  }
  return (
    <section className="complete__container complete__steps-container">
      {steps.map(obj =>
        obj.name ? (
          <p className="complete__steps-header" key={obj.id}>
            {`Steps included in "${obj.name}":`}
          </p>
        ) : (
          <div className="complete__step-container complete__step-container-green" key={obj.id}>
            <button type="button" disabled>
              <i className="icon-check" />
            </button>
            <div className="content">
              <div className="complete__step-text">{obj.text}</div>
            </div>
          </div>
        )
      )}
    </section>
  );
}

export default PostGoalMentioned;
