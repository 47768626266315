/* eslint-disable fp/no-mutation */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

import flag from '../../assets/img/illustration.flag.png';
import hat from '../../assets/img/illustration.hat.png';
import step from '../../assets/img/illustration.step.png';
import hourglass from '../../assets/img/illustration.hourglass.png';
import goals from '../../assets/img/illustration.goals.png';

function Illustration({type}) {
  let img = flag;
  switch (type) {
    case 'flag':
      img = flag;
      break;
    case 'party hat':
      img = hat;
      break;
    case 'step':
      img = step;
      break;
    case 'hourglass':
      img = hourglass;
      break;
    case 'goals':
      img = goals;
      break;
    default:
      img = flag;
  }

  return (
    <div className="illustration">
      <img src={img} alt={`An illustration of a ${type}`} />
    </div>
  );
}

Illustration.propTypes = {
  type: PropTypes.string,
};

Illustration.defaultProps = {
  type: '',
};

export default Illustration;
