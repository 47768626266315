import {useState, useEffect} from 'react';
import {parsePhoneNumberFromString} from 'libphonenumber-js/min';

const usePhoneNumber = (phoneNumber, defaultCountry = 'AU') => {
  const [parsedNumber, setParsedNumber] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    try {
      const parsed = parsePhoneNumberFromString(phoneNumber, defaultCountry);
      setParsedNumber({
        number: parsed.number,
        display: parsed.formatNational(),
      });
      setError(false);
    } catch (e) {
      console.log(e.message);
      setError(e.message);
    }
  }, [phoneNumber, defaultCountry]);

  return [parsedNumber.number, parsedNumber.display, error];
};

export default usePhoneNumber;
