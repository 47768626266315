/* eslint-disable react/prop-types */
import React, {useCallback} from 'react';

import routes from '../../constants/routes';
import UserOnboard from '../onboarding/modal.onboard';

function ProfileOnboardIntro({match, history}) {
  const {profileId} = match.params;

  const onSkip = useCallback(() => {
    // eslint-disable-next-line react/prop-types
    history.push(routes.generate(routes.profile.home, {profileId}));
  }, [history, profileId]);

  return (
    <div className="container">
      <UserOnboard onSkip={onSkip} profileId={profileId} />
    </div>
  );
}

export default ProfileOnboardIntro;
