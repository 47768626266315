/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import routes from '../../constants/routes';

import {Card, Button} from '../common';
import Illustration from '../utilities/illustration';
import withAuthorization from '../session/withAuthorization';
import {db} from '../../firebase';

function AccountOnboardName({authUser, history}) {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);

  useEffect(() => {
    setFirstname(authUser.firstname || '');
    setLastname(authUser.lastname || '');
  }, [authUser, setFirstname, setLastname]);

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    await db.saveUser(authUser.uid, {firstname, lastname});
    history.push(routes.user.inviteOnboard.photo);
  };

  return (
    <div className="page -account">
      <div className="container">
        <Card focus>
          <section className="section">
            <Illustration type="flag" size="large" />

            <h3>{`Let's confirm your name`}</h3>
            <p>Your connections on Sameview can see this:</p>
            <form onSubmit={onSubmit}>
              <label htmlFor="firstname">
                First Name
                <input id="firstname" value={firstname} onChange={event => setFirstname(event.target.value)} type="text" />
              </label>

              <label htmlFor="lastname">
                Last Name
                <input id="lastname" value={lastname} onChange={event => setLastname(event.target.value)} type="text" />
              </label>

              <Button submit type="primary" loading={loading}>
                Next
              </Button>

              {error && <p>{error.message}</p>}
            </form>
          </section>
        </Card>
      </div>
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(inject('stores')(observer(AccountOnboardName)));
