/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, {useCallback} from 'react';
import {Input, TextArea, useFormApi} from 'informed';
import classnames from 'classnames';

import {DialogActions, DialogContent} from '@material-ui/core';
import {Button, Field} from '../common';
import {validateEmailnoSpaces, validateRequired} from '../common/validate';
import PermissionsSection from '../form/form.permissions';

import '../../assets/scss/components/invite/invite-form.scss';

function InviteForm({saving, relationshipExamples, onCancel, isOwner, hideRelationship = false}) {
  const {reset} = useFormApi();

  const memoizedOnCancel = useCallback(
    e => {
      e.preventDefault();
      reset();
      onCancel();
    },
    [reset, onCancel]
  );

  return (
    <>
      <DialogContent>
        <Field
          prefix="invite-form"
          label="First Name"
          field="first_name"
          wrapperClassName="invite-form__name"
          required
          validate={validateRequired}
          validateOnChange
        />

        <Field
          prefix="invite-form"
          label="Last Name"
          field="last_name"
          wrapperClassName="invite-form__name"
          required
          validate={validateRequired}
          validateOnChange
        />

        <Field prefix="invite-form" label="Email" field="email" placeholder="Email address" required validate={validateEmailnoSpaces} validateOnChange />

        <label htmlFor="message" className={classnames('invite-form__label')}>
          Message<span className="invite-form__optional">optional</span>
          <TextArea
            id="message"
            field="message"
            placeholder="We'll send an email to your invitee with all the details and why you invited them. If you want to add an extra message you can do that here"
          />
        </label>

        {!hideRelationship && (
          <label htmlFor="relationship" className={classnames('invite-form__label invite-form__relationship')}>
            Relationship to name on profile
            <span className="invite-form__optional">optional</span>
            <Input id="relationship" field="relationship" placeholder={`E.g., ${relationshipExamples}...`} />
          </label>
        )}

        <PermissionsSection field="permissions" prefix="invite-form" isOwner={isOwner} />
      </DialogContent>
      <DialogActions>
        <Button type="tertiary" onClick={memoizedOnCancel}>
          Cancel
        </Button>
        <Button type="primary" submit loading={saving}>
          Invite
        </Button>
      </DialogActions>
    </>
  );
}

export default InviteForm;
