/* eslint-disable react/prop-types */
import React from 'react';
import {observer} from 'mobx-react';

import {Card} from '../common';
import withProfile from './withProfile';
import GoalDetail from '../goal/goal.detail';
import ProfileAside from './profile.aside';
import GoalFeed from '../feed/feed.goal';
import NotFound from '../errors/notfound';

function ProfileGoalDetail({profile, goalId, goal}) {
  return (
    <div className="container">
      <aside className="aside">
        <ProfileAside profileId={profile.id} />
      </aside>
      <Card feed>
        {goal ? (
          <>
            <GoalDetail goal={goal} goalId={goalId} profileId={profile.id} profile={profile} />
            <GoalFeed title="Related Posts" goalId={goalId} />
          </>
        ) : (
          <NotFound text="Could not find this goal" />
        )}
      </Card>
    </div>
  );
}

export default withProfile(observer(ProfileGoalDetail));
