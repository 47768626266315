/* eslint-disable react/prop-types */
import React from 'react';
import {Link, withRouter} from 'react-router-dom';

import classNames from 'classnames';
import AuthUserContext from '../session/AuthUserContext';
import routes from '../../constants/routes';

import logo from '../../assets/img/brand.primary.png';
import logoInverse from '../../assets/img/brand.inverse.png';
import logoMini from '../../assets/img/brand.primary.mini.png';
import logoInverseMini from '../../assets/img/brand.inverse.mini.png';

import StatusBar from './status.bar';

function Navigation({isInverted, path}) {
  const navClassName = classNames({
    'primary-navigation': true,
    '-inverse': isInverted,
    [`-${path}`]: !!path,
  });

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <nav className={navClassName}>
          <div className="container">
            <div className="brand">
              <Link to={routes.home}>
                <img src={logo} alt="Sameview" />
                <img className="-inverse" src={logoInverse} alt="Sameview" />

                <img className="-mini" src={logoMini} alt="Sameview" />
                <img className="-inverse -mini" src={logoInverseMini} alt="Sameview" />
              </Link>
            </div>

            <div className="account">
              {authUser ? (
                <StatusBar />
              ) : (
                authUser === null && (
                  <ul className="menu-user">
                    <Link to={routes.user.log_in} className="button -small">
                      Log In
                    </Link>
                    <Link to={routes.user.sign_up} className="button -small -primary">
                      Sign Up
                    </Link>
                  </ul>
                )
              )}
            </div>
          </div>
        </nav>
      )}
    </AuthUserContext.Consumer>
  );
}

export default withRouter(Navigation);
