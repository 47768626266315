/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from './icon';

export function CardDismissButton({onClick}) {
  return (
    <button type="button" className="dismiss" onClick={onClick}>
      <Icon type="cancel" />
    </button>
  );
}

function Card({type, children, forwardedRef, onDismiss, style, className, ...props}) {
  return (
    <div
      ref={forwardedRef}
      className={classnames('card', className, type, {
        '-feed': props.feed,
        '-focus': props.focus,
        '-solo': props.solo,
        '-wide': props.wide,
        '-full': props.full,
        '-help': props.help,
        '-welcome': props.welcome,
        '-panels': props.panels,
        '-sections': props.sections,
        '-compact': props.compact,
        '-introduction': props.introduction,
        '-deemphasise': props.deemphasise,
      })}
      style={style}>
      {onDismiss && <CardDismissButton onClick={onDismiss} />}
      {children}
    </div>
  );
}

Card.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  forwardedRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  ]),
  onDismiss: PropTypes.bool,
  style: PropTypes.shape({}),
  className: PropTypes.string,
};

Card.defaultProps = {
  type: '',
  children: null,
  forwardedRef: null,
  onDismiss: false,
  style: {},
  className: '',
};

export default React.forwardRef((props, ref) => <Card {...props} forwardedRef={ref} />);
