/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-mutating-methods */
import {observable, action, decorate} from 'mobx';
import _uniq from 'lodash/uniq';
import {db} from '../firebase';

export class UsersStore {
  constructor(mainStore) {
    this.mainStore = mainStore;
  }

  mainStore = null;

  users = [];

  checkForUnfetchedUser = id => {
    const existingUser = this.getUserById(id);
    // First check if we already have this user in memory
    // to avoid a trip to the db
    if (existingUser) return;

    // Otherwise create a user with a fetching state
    // and go and fetch the required user.
    const placeholderUser = observable({
      id,
      isFetching: true,
    });
    this.users.push(placeholderUser);
    this.fetchUser(id);
  };

  fetchUser = async id => {
    const newUser = await db.getUserById(id);
    this.updateUser(newUser);
  };

  updateUser = updatedOrNewUser => {
    if (!updatedOrNewUser) return;
    // eslint-disable-next-line no-param-reassign
    updatedOrNewUser.isFetching = false;
    const existingUser = this.getUserById(updatedOrNewUser.id);

    if (existingUser) {
      Object.keys(updatedOrNewUser).forEach(key => {
        existingUser[key] = updatedOrNewUser[key];
      });
    } else {
      this.users.push(updatedOrNewUser);
    }
  };

  fetchUsersFromPostData = postData => {
    // To ensure we have the users in the user store.
    // We grab all the userIds from the various fields on a post
    // filter the unique ones.  And then tell the store to make
    // sure we have those in memory, otherwise go and grab them from
    // the db.
    const {authorId} = postData;
    const team = Object.keys(postData.members || {});
    const likers = Object.keys(postData.likes || {});
    const userIds = [authorId, ...team, ...likers];

    const uniqueUsers = _uniq(userIds);

    uniqueUsers.forEach(user => {
      this.checkForUnfetchedUser(user);
    });
  };

  fetchUsersFromCommentData = commentData => {
    // Fetch new users if needed
    // Todo tidy this up

    const userIds = Object.values(commentData || {}).map(c => c.authorId);

    const uniqueUsers = _uniq(userIds);

    uniqueUsers.forEach(user => {
      this.checkForUnfetchedUser(user);
    });
  };

  fetchUsersFromNotificationData = notificationData => {
    // Currently just assumes there's one user in a notification
    // under the notification.meta.authorId

    const user = notificationData.meta && notificationData.meta.authorId;

    if (user) {
      this.checkForUnfetchedUser(user);
    }
  };

  getUserById = id => this.users.find(u => u.id === id);
}

decorate(UsersStore, {
  users: observable,
  checkForUnfetchedUser: action,
  fetchUser: action,
  updateUser: action,
});

export default UsersStore;
