/* eslint-disable react/prop-types */
import React from 'react';
import {Form} from 'informed';

import {DialogTitle} from '@material-ui/core';
import InviteForm from './form.invite';

function InviteFamilyForm({saving, onSubmit, onCancel, isOwner}) {
  return (
    <>
      <DialogTitle>Invite Family</DialogTitle>
      <Form
        // eslint-disable-next-line react/jsx-props-no-spreading
        render={({...props}) => <InviteForm onCancel={onCancel} saving={saving} isOwner={isOwner} relationshipExamples="Mum, Uncle, Grandma" {...props} />}
        initialValues={{permissions: 'owner'}}
        onSubmit={values => onSubmit({...values, type: 'family'})}
      />
    </>
  );
}

export default InviteFamilyForm;
