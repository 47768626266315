/* eslint-disable react/prop-types */
import React from 'react';

import GettingStartedIcon from '../../assets/img/GettingStarted.png';

import '../../assets/scss/components/post/post.scss';

function PostNewProfile({data = {}}) {
  if (!data.newUserWelcome) {
    return null;
  }
  return (
    <div className="complete__container complete__container--profile">
      <div className="media">
        <img src={GettingStartedIcon} alt="Welcome to Sameview!" />
      </div>
    </div>
  );
}

export default PostNewProfile;
