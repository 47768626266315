/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import '../../assets/scss/components/_panels.scss';

function Panel({children, className, forwardedRef, ...props}) {
  return (
    <div
      ref={forwardedRef}
      className={classnames('panel', className, {
        '-primary': props.primary,
        '-secondary': props.secondary,
        '-content': props.content,
        '-two-third': props.twoThird,
        '-third': props.third,
        '-compact': props.compact,
      })}>
      {children}
    </div>
  );
}

Panel.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  content: PropTypes.bool,
};

Panel.defaultProps = {
  primary: false,
  secondary: false,
  content: false,
};

export default React.forwardRef((props, ref) => <Panel {...props} forwardedRef={ref} />);
