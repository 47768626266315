/* eslint-disable react/prop-types */
import React from 'react';
import '../../assets/scss/components/post/post.scss';

function PostGoalCreated({data, profile}) {
  if (data.goalCompleted || !data.goals || !data.steps || !data.newGoal) {
    return null;
  }
  const steps = [];
  const goals = Object.keys(data.goals);
  goals.forEach(goalId => {
    const goal = profile.goals[goalId];
    Object.keys(goal?.steps || {}).forEach(id => {
      // eslint-disable-next-line fp/no-mutating-methods
      steps.push({id, ...goal.steps[id]});
    });
  });
  if (!steps.length) {
    return null;
  }
  return (
    <section className="complete__container complete__steps-container">
      <p className="complete__steps-header">Steps to achieve goal:</p>
      {/* eslint-disable-next-line fp/no-mutating-methods */}
      {steps
        .sort((a, b) => a.sort - b.sort)
        .map(step => (
          <div className="complete__step-container" key={step.id}>
            <button type="button" disabled>
              <i className="icon-check" />
            </button>
            <div className="content">
              <div className="complete__step-text">{step.text}</div>
            </div>
          </div>
        ))}
    </section>
  );
}

export default PostGoalCreated;
