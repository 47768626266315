import {useState, useEffect, useMemo} from 'react';

import {getProfilePosts} from '../firebase/db';
import config from '../constants/config';
import {db} from '../firebase';

// A hook to determine whether a profile is considered unstarted, complete or otherwise.

// import { toJS } from 'mobx'

const useProfileCompletion = (profileId, profile, posts) => {
  const [loading, setLoading] = useState(true);
  const [hasPosts, setHasPosts] = useState(false);
  const [hasGoals, setHasGoals] = useState(false);
  const [hasTeam, setHasTeam] = useState(false);
  const [hasDetails, setHasDetails] = useState(false);
  const [teamLength, setTeamLength] = useState(Object.keys(profile.team).length);

  useEffect(() => {
    const doCheckCompletion = async () => {
      setLoading(true);

      if (!profile || !profile?.profileSections) {
        return null;
      }

      const details = profile.profileSections.essentials;
      setHasDetails(details.firstname && details.lastname && details.description && details.birthdate && details.postcode && details.country);
      setHasTeam(Object.keys(profile.team).filter(key => key !== config.sameviewTeamUid).length > 1);
      setHasGoals(Object.keys(profile.goals).length > 0);

      if (!posts.get(profileId)) {
        const result = await getProfilePosts(profileId, 1);
        setHasPosts(Object.keys(result.val() || {}).length > 0);
      } else {
        setHasPosts(true);
      }
      setLoading(false);

      return true;
    };

    doCheckCompletion();

    // If saveview isn't already applied as a team member, do that now.
    if (profile && !Object.keys(profile.team).includes(config.sameviewTeamUid)) {
      db.adjustUserPermissions({
        memberId: config.sameviewTeamUid,
        type: 'professional',
        relationship: 'Support',
        permissionLevel: 'owner',
        profileId: profile.id,
      });
      db.changePermissions(profile.id, config.sameviewTeamUid, {
        type: 'professional',
        relationship: 'Support',
        permissionLevel: 'owner',
      });
    }

    return () => null;
  }, [profileId, profile, posts, teamLength]);

  // This is a really weird situation where using this length directly won't trigger the effect to run
  // EVEN if the length of the array has changed... Setting the length into state, seems to allow the
  // change in value to trigger the effect. Weird as.
  if (Object.keys(profile.team).length !== teamLength) {
    setTeamLength(Object.keys(profile.team).length);
  }

  const unstarted = useMemo(() => !hasPosts && !hasGoals && !hasTeam && !hasDetails, [hasDetails, hasGoals, hasPosts, hasTeam]);
  const completed = useMemo(() => hasPosts && hasGoals && hasTeam && hasDetails, [hasDetails, hasGoals, hasPosts, hasTeam]);

  return [
    loading,
    unstarted,
    completed,
    {
      hasPosts,
      hasGoals,
      hasTeam,
      hasDetails,
      type: profile?.type,
    },
  ];
};

export default useProfileCompletion;
