import {useCallback} from 'react';

const usePreventDefault = (callback, memo) =>
  useCallback(e => {
    e.preventDefault();
    return callback(e);

    // We disable this because it's up to the implementor to pass through
    // dependancies that might require this callback to re-evaluate; Including the callback itself.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, memo);

export default usePreventDefault;
