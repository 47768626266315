/* eslint-disable react/prop-types */
import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import {Button} from '../common';

import GettingStartedIcon from '../../assets/img/GettingStarted.png';

import '../../assets/scss/components/profile/profile-completion.onboarding.scss';

const INIT_BODY = 'Welcome to Sameview! In Sameview you can share updates and help reaching goals.';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  textInputStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chip: {
    backgroundColor: '',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function OnboardingAddPost({onFinishPost, name}) {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [body, setBody] = useState(INIT_BODY);
  useEffect(() => {
    if (name?.length) {
      setBody(`Welcome to ${name}’s sameview profile! In sameview you can share updates and help ${name} to reach any goal.`);
    }
  }, [name]);

  const onCreatePost = useCallback(() => {
    onFinishPost({body, hasImage: true});
  }, [onFinishPost, body]);

  useEffect(() => {
    setDisabled(body.length === 0);
  }, [body]);

  const handleChangeBody = useCallback(e => setBody(e.target.value), []);

  return (
    <>
      <div className="user-onboard__section">
        <h3 className="user-onboard__heading">Write an introductory post</h3>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          fullWidth
          multiline
          className={classes.textInputStyle}
          placeholder={INIT_BODY}
          value={body}
          onChange={handleChangeBody}
        />
      </div>
      <div className="user-onboard__section">
        <h3>Attachments</h3>
        <img src={GettingStartedIcon} alt="Welcome to Sameview!" width="100%" height="auto" />
      </div>
      <div className="user-onboard__section">
        <Box flexDirection="row" display="flex" justifyContent="end">
          <Button type="primary" right onClick={onCreatePost} disabled={disabled}>
            Post
          </Button>
        </Box>
      </div>
    </>
  );
}

export default OnboardingAddPost;
