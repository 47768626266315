/* eslint-disable react/prop-types */
import React from 'react';
import blankProfile from '../../assets/img/blank-profile.png';
import {validateRequired} from '../common/validate';
import {Field, FieldTextarea, FieldImageUpload, FieldCountrySelect, DateField} from '../common';

export default function ProfileField({label, type, required, printing, canEdit, field, profileId, sectionKey, placeholder, helperText}) {
  if (type === 'date') {
    return (
      <DateField
        prefix="profile-form"
        field={field}
        label={label}
        placeholder="DD / MM / YYYY"
        dateFormat="dd/MM/yyyy"
        validate={required && validateRequired}
        validateOnChange={required}
        readOnly={!canEdit}
        printing={printing}
        required={required}
        helpertext={helperText}
      />
    );
  }
  if (type === 'image') {
    return (
      <div className="profile-form-full_width">
        <FieldImageUpload
          inputId={`profile_${sectionKey}_image_${profileId}`}
          inputName={`profile_${sectionKey}_image_${profileId}`}
          prefix="profile-form"
          label={label}
          field={field}
          wrapperClassName="profile-form__avatar"
          defaultImage={blankProfile}
          filePath={`/uploads/${profileId}/`}
          readOnly={!canEdit}
          printing={printing}
          helpertext={helperText}
        />
      </div>
    );
  }
  if (type === 'text') {
    return (
      <Field
        prefix="profile-form"
        label={label}
        field={field}
        placeholder={placeholder || ''}
        validate={required && validateRequired}
        validateOnChange={required}
        readOnly={!canEdit}
        printing={printing}
        required={required}
        helpertext={helperText}
      />
    );
  }
  if (type === 'country') {
    return (
      <FieldCountrySelect
        prefix="profile-form"
        label={label}
        field={field}
        validate={required && validateRequired}
        validateOnChange={required}
        readOnly={!canEdit}
        printing={printing}
        required={required}
        helpertext={helperText}
      />
    );
  }
  return (
    <div className="profile-form-full_width">
      <FieldTextarea
        prefix="profile-form"
        field={field}
        label={label}
        placeholder={placeholder}
        validate={required && validateRequired}
        validateOnChange={required}
        readOnly={!canEdit}
        printing={printing}
        required={required}
        helpertext={helperText}
      />
    </div>
  );
}
