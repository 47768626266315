/* eslint-disable react/prop-types */
/* eslint-disable fp/no-mutating-methods */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {withRouter} from 'react-router-dom';

import {onAuthStateChanged} from 'firebase/auth';
import AuthUserContext from './AuthUserContext';
import {firebase} from '../../firebase';
import routes from '../../constants/routes';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      onAuthStateChanged(firebase.auth, authUser => {
        if (
          !condition(authUser) &&
          ![routes.user.sign_up, routes.user.sign_up_family, routes.user.sign_up_individual, routes.user.sign_up_provider, routes.user.password_reset].includes(
            this.props.location.pathname
          )
        ) {
          this.props.history.push(routes.user.log_in);
        }
      });
    }

    render() {
      return <AuthUserContext.Consumer>{authUser => (authUser ? <Component {...this.props} authUser={authUser} /> : null)}</AuthUserContext.Consumer>;
    }
  }

  return withRouter(WithAuthorization);
};

export default withAuthorization;
