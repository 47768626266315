/* eslint-disable fp/no-mutation */
import _ from 'lodash';

const landingDomain = 'https://www.sameview.com.au';

const routes = {
  home: '/',

  profile: {
    create: '/create/profile',
    index: '/profile/:profileId/*',
    home: '/profile/:profileId/home/',
    post: {
      view: '/profile/:profileId/home/:postId',
    },
    about: '/profile/:profileId/about/',
    goals: '/profile/:profileId/goals/',
    documents: '/profile/:profileId/documents/',
    goal: {
      create: '/profile/:profileId/goals/create',
      detail: '/profile/:profileId/goals/:goalId',
      edit: '/profile/:profileId/goals/:goalId/edit',
    },
    team: '/profile/:profileId/team/',
    teamMember: '/profile/:profileId/team/:userId',
    calendar: '/profile/:profileId/calendar',
    invite: '/profile/:profileId/invite/',
    edit: '/profile/:profileId/edit/',
    onboard: {
      intro: '/profile/:profileId/onboard/intro',
      detail: '/profile/:profileId/onboard/detail',
      goals: '/profile/:profileId/onboard/goals',
      team: '/profile/:profileId/onboard/team',
      post: '/profile/:profileId/onboard/post',
      done: '/profile/:profileId/onboard/done',
    },
    test: '/profile/:profileId/test/',
  },
  user: {
    index: '/account/*',
    menu: '/account/menu',
    sign_up: '/account/signup',
    sign_up_individual: '/account/signup/individual',
    sign_up_provider: '/account/signup/provider',
    sign_up_family: '/account/signup/family',
    log_in: '/account/login',
    password_reset: '/account/password-reset',
    password_change: '/account/password-change',
    account: '/account/account',
    edit: '/account/edit',
    notifications: '/account/notifications',
    settings: '/account/settings',

    // Payment Gateway
    payment: '/account/payment',
    subscription: '/account/subscription/:profileId?',

    inviteOnboard: {
      intro: '/account/onboard/intro',
      name: '/account/onboard/name',
      photo: '/account/onboard/photo',
      about: '/account/onboard/about',
      launch: '/account/onboard/launch',
    },
  },
  professional: {
    index: '/clients/*',
    clients: '/clients/all',
    inviteClient: '/clients/invite',
    onboard: {
      intro: '/clients/onboard/intro',
    },
  },
  about: {
    home: `${landingDomain}`,
    privacy: `${landingDomain}/privacy-policy`,
    terms: `${landingDomain}/terms-of-service`,
    accessibility: `${landingDomain}/accessibility`,
    faq: `${landingDomain}/faq`,
    help: `${landingDomain}/help`,
  },
  generate: (path, replacements) => {
    let newPath = path;
    _.each(replacements, (replacement, original) => {
      newPath = newPath.replace(`:${original}`, replacement).replace(`:${original}?`, replacement);
    });
    return newPath;
  },
};

export default routes;
