import React from 'react';

import HomeAside from './home.aside';
import HomeFeed from '../feed/feed.home';
import {Card} from '../common';

function HomePage() {
  return (
    <div>
      <div className="page">
        <div className="container">
          <aside className="aside">
            <HomeAside />
          </aside>
          <Card feed>
            <HomeFeed title="" />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
