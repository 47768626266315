/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import {RadioGroup, Radio, useFieldApi} from 'informed';
import classnames from 'classnames';

const permissionDescriptions = {
  owner: 'Owners have full control of the profile, and can invite other team members.',
  admin: 'Contributors can create posts, like and comment, but can’t edit the profile.',
  viewer: 'Viewers can comment and like posts, but can’t post or edit the profile.',
};

const usePermissionDescription = permission => permissionDescriptions[permission];

function PermissionsSection({field, prefix = 'section', isOwner = false}) {
  const {getValue} = useFieldApi(field);
  const permissionValue = getValue();
  const permissionDescription = usePermissionDescription(permissionValue);

  return (
    <>
      <div className={`${prefix}__permissions`}>
        <label className={`${prefix}__label`}>Permissions</label>
        <RadioGroup field={field}>
          <Radio id={`${prefix}--owner`} value="owner" disabled={!isOwner} />
          <label
            htmlFor={`${prefix}--owner`}
            className={classnames(`${prefix}__radio`, {
              [`${prefix}__radio--selected`]: permissionValue === 'owner',
            })}>
            <span>Owner</span>
          </label>

          <Radio id={`${prefix}--admin`} value="admin" disabled={!isOwner} />
          <label
            htmlFor={`${prefix}--admin`}
            className={classnames(`${prefix}__radio`, {
              [`${prefix}__radio--selected`]: permissionValue === 'admin',
            })}>
            <span>Contributor</span>
          </label>

          <Radio id={`${prefix}--viewer`} value="viewer" disabled={!isOwner} />
          <label
            htmlFor={`${prefix}--viewer`}
            className={classnames(`${prefix}__radio`, {
              [`${prefix}__radio--selected`]: permissionValue === 'viewer',
            })}>
            <span>Viewer</span>
          </label>
        </RadioGroup>
      </div>
      {permissionDescription && <p className={`${prefix}__description`}>{permissionDescription}</p>}
    </>
  );
}

export default PermissionsSection;
