import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import loadingSrc from '../../assets/img/loading-white.svg';

export const buttonTypes = ['primary', 'primary-light', 'secondary', 'secondary-light', 'tertiary', 'link', 'icon'];

export const buttonSizes = ['large', 'standard', 'medium', 'small', 'tiny'];

function Button({type, size, left, right, center, loading, loadingMessage, disabled, onClick, submit, toggle, customClass, children}) {
  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={classnames('button', customClass, {
        // Button types
        '-primary': type === 'primary' || (loading && type === 'primary-light'),
        '-primary-light': type === 'primary-light' && !loading,
        '-secondary': type === 'secondary' || (loading && type === 'secondary-light'),
        '-secondary-light': type === 'secondary-light' && !loading,
        '-tertiary': type === 'tertiary',
        '-link': type === 'link',
        '-icon': type === 'icon',
        '-toggle': toggle,

        // Button sizes
        '-large': size === 'large',
        '-standard': size === 'standard',
        '-medium': size === 'medium',
        '-small': size === 'small',
        '-tiny': size === 'tiny',

        // Button positions
        '-left': left,
        '-right': right,
        '-center': center,

        // Loading state
        '-loading': loading,
        disabled: loading || disabled,
      })}
      onClick={onClick}>
      {loading ? (
        <>
          <img className="-rotating" src={loadingSrc} alt="Loading..." /> {loadingMessage}
        </>
      ) : (
        children
      )}
    </button>
  );
}

Button.propTypes = {
  /** Primary button display style */
  type: PropTypes.oneOf(buttonTypes).isRequired,
  /** Secondary button display style */
  size: PropTypes.oneOf(buttonSizes),
  /** This button should float left */
  left: PropTypes.bool,
  /** This button should be centered */
  center: PropTypes.bool,
  /** This button should float right */
  right: PropTypes.bool,
  /** Whether to add type=submit attribute */
  submit: PropTypes.bool,
  /** Display a loading spinner on the button, and disable it */
  loading: PropTypes.bool,
  /** Make the button appear disabled */
  disabled: PropTypes.bool,
  /** Make the button a toggle button */
  toggle: PropTypes.bool,
  customClass: PropTypes.string,

  loadingMessage: PropTypes.node,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

Button.defaultProps = {
  size: 'standard',
  customClass: '',
  left: false,
  right: false,
  center: false,
  submit: false,
  loading: false,
  disabled: false,
  toggle: false,
  loadingMessage: null,
  onClick: () => {},
  children: null,
};

export default Button;
