import React from 'react';
import PropTypes from 'prop-types';

import {Modal, Heading, Button} from '.';

function ModalDelete({title, body, isOpen, labelDismiss, onDismiss, labelConfirm, onConfirm}) {
  return (
    <Modal isOpen={isOpen} handleDismiss={onDismiss} showDismiss={!!onDismiss}>
      <Heading primary>
        <h4>{title}</h4>
      </Heading>
      <div className="body">{body}</div>
      <div className="action">
        <Button type="tertiary" onClick={onConfirm}>
          {labelConfirm}
        </Button>
        <Button type="primary" onClick={onDismiss}>
          {labelDismiss}
        </Button>
      </div>
    </Modal>
  );
}

ModalDelete.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,

  /** The onDismiss function. If this is passed, a dismiss button will be shown */
  labelDismiss: PropTypes.string,
  onDismiss: PropTypes.func,

  /** The onConfirm function. If this is passed, a confirm button will be shown */
  labelConfirm: PropTypes.string,
  onConfirm: PropTypes.func,
};

ModalDelete.defaultProps = {
  title: 'Manage your Subscription',
  body: "If you cancel your subscription you'll lose the Sameview benefits at the end of your billing period. Are you sure?",
  /** Defaults to no dismiss button */
  labelDismiss: 'Keep Subscription',
  onDismiss: false,
  /** Defaults to no confirm button */
  labelConfirm: 'Cancel Subscription',
  onConfirm: false,
};

export default ModalDelete;
