/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export const defaultBreakpoints = {
  mobile: 736,
  tablet: 1024,
  desktop: 1200,
};

const withBreakpoints =
  (breakpoints = defaultBreakpoints) =>
  Component => {
    class BreakpointComponent extends React.Component {
      state = {
        breakpoint: null,
      };

      componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
      }

      handleResize = () => {
        const found = Object.entries(breakpoints).find(([point, width]) => window.innerWidth <= width);

        if (!found) {
          const [breakpoint] = Object.keys(breakpoints).slice(-1);
          return this.setState({breakpoint});
        }

        const [breakpoint] = found;
        this.setState({breakpoint});
        return true;
      };

      render() {
        // eslint-disable-next-line react/prop-types
        const {forwardedRef, ...props} = this.props;
        const {breakpoint} = this.state;

        return <Component breakpoint={breakpoint} ref={forwardedRef} {...props} />;
      }
    }

    return React.forwardRef((props, ref) => <BreakpointComponent {...props} forwardedRef={ref} />);
  };

export default withBreakpoints;
