const permissions = {
  owner: 'Owner',
  admin: 'Contributor',
  viewer: 'Viewer',
};

export const getPermissionsValue = permission => {
  if (typeof permission === 'object') {
    return permission.permission;
  }

  return permission;
};

export const getPermissions = permission => permissions[getPermissionsValue(permission)];
