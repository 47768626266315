import React from 'react';
import PropTypes from 'prop-types';

import icon from '../../assets/img/loading.svg';

function Loading({text}) {
  return (
    <div className="loading-indicator">
      <img className="-rotating" src={icon} alt="A spinning loading symbol" />
      {text && (
        <h4 className="font-family: Helvetica; font-size: 22px; font-style: normal; font-weight: 700; line-height: 22px; letter-spacing: 0em; text-align: center;">
          {text}
        </h4>
      )}
    </div>
  );
}

Loading.propTypes = {
  text: PropTypes.string,
};

Loading.defaultProps = {
  text: null,
};

export default Loading;
