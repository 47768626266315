/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, {useEffect} from 'react';
import {observer, inject} from 'mobx-react';

import icon from '../../assets/img/check.png';
import routes from '../../constants/routes';

function UserOnboardComplete(props) {
  const {profileId} = props.match.params;

  const {
    stores: {profilesStore},
  } = props;

  const profile = profilesStore.getProfileById(profileId);
  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line fp/no-mutating-methods
      props.history.push(routes.generate(routes.profile.home, {profileId}));
    }, 2000);
  }, [props.history, profileId]);

  return (
    <div className="loading-indicator">
      <img src={icon} alt="" />
      <h5 className="font-family: Helvetica; font-size: 22px; font-style: normal; font-weight: 700; line-height: 22px; letter-spacing: 0em; text-align: center;">
        {profile.firstname} is all set for easier, and better care coordination
      </h5>
    </div>
  );
}

export default inject('stores')(observer(UserOnboardComplete));
