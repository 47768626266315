/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useState, useCallback} from 'react';
import {inject, observer} from 'mobx-react';

import {FormikProvider, useFormik, Form, Field} from 'formik';
import * as Yup from 'yup';

import dayjs from 'dayjs';
import {Card, Button} from '../common';
import {db} from '../../firebase';
import routes from '../../constants/routes';
import config from '../../constants/config';
import withAuthorization from '../session/withAuthorization';
import {createPost, createUUID, getUserById} from '../../firebase/db';

import TextField from '../Inputs/TextField';

const initialValues = {
  firstName: '',
  lastName: '',
  relationship: '',
  description: '',
  type: '',
};

function ProfileCreate({authUser}) {
  const [uid, setUid] = useState('');

  const [error, setError] = useState(null);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string()
      .required('Required')
      .when('type', {
        is: 'group',
        then: () => Yup.string().optional(),
      }),
    type: Yup.string().required('Required'),
    description: Yup.string().when('type', {
      is: 'group',
      then: () => Yup.string().required('Required'),
    }),
    relationship: Yup.string().when('type', {
      is: 'someone',
      then: () => Yup.string().required('Required'),
    }),
  });

  const onSubmit = useCallback(
    async submitValues => {
      const id = db.createUUID();

      try {
        if (submitValues.type === 'group') {
          // new funciton to create a group
          await db.createGroup(id, submitValues.firstName, submitValues.description, {type: submitValues.type});
          // Grant permissions to this user on the profile
          await db.updatePermissions(id, uid, 'owner', 'family', 'Group Owner');
        } else {
          // Create a new profile
          await db.createProfile(
            id,
            submitValues.firstName,
            submitValues.lastName,
            submitValues.type === 'myself',
            submitValues.type,
            submitValues.description
          );

          // Grant permissions to this user on the profile
          await db.updatePermissions(id, uid, 'owner', 'family', submitValues.relationship);
        }

        await db.adjustUserPermissions({
          memberId: config.sameviewTeamUid,
          type: 'professional',
          relationship: 'Support',
          permissionLevel: 'owner',
          profileId: id,
        });

        // Create a new plan with a trial subscription.
        const plan = await db.getStripePlanId();
        const customer = await db.getStripeCustomerId(uid);

        // Create or edit the subscription plan.
        const subscriptionData = {
          cancel_at_period_end: false,
          coupon: '',
          customer,
          id: '',
          item: '',
          order_id: id,
          plan,
          trial_from_plan: true,
          user_id: uid,
        };

        // Save the subscription to the database
        await db.updateStripeSubscriptions(id, subscriptionData);

        if (submitValues.type === 'group') {
          await createPost({
            created_at: dayjs().unix() * 1000 - 60000,
            id: createUUID(),
            profileId: id,
            authorId: uid,
            text: `Welcome to ${submitValues.firstName}!`,
            newUserWelcome: true,
          });
          // eslint-disable-next-line consistent-return, no-return-assign, fp/no-mutation
          return (window.location = routes.generate(routes.profile.home, {
            profileId: id,
          }));
        }
        // Navigate to the onboarding
        // eslint-disable-next-line consistent-return, no-return-assign, fp/no-mutation
        return (window.location = routes.generate(routes.profile.onboard.intro, {
          profileId: id,
        }));
      } catch (submitError) {
        setError(submitError);
        return null;
      }
    },
    [uid]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {values, setFieldValue, isSubmitting} = formik;

  const updateType = useCallback(
    value => {
      getUserById(authUser.uid).then(userData => {
        const userFirstName = userData.firstname || '';
        const userLastName = userData.lastname || '';
        // If the type changes, and the name is blank, fill in the name
        if (value === 'myself' && !values.firstName && !values.lastName) {
          setFieldValue('firstName', userFirstName);
          setFieldValue('lastName', userLastName);
          setFieldValue('relationship', 'Me');
        } else {
          setFieldValue('relationship', '');
        }
      });
    },
    [authUser.uid, setFieldValue, values.firstName, values.lastName]
  );

  useEffect(() => {
    setUid(authUser.uid);
    updateType(values.type);
  }, [authUser, updateType, values.type]);

  return (
    <div className="page -account">
      <div className="container">
        <Card focus>
          <div className="heading -primary">
            <h4>Create a profile</h4>
          </div>

          <FormikProvider value={formik}>
            <Form>
              <section className="section">
                <h3>I&apos;m creating a profile for</h3>

                <div className="select">
                  <Field name="type" as="select">
                    <option value="" disabled>
                      Select one
                    </option>
                    <option value="someone">Someone else (e.g. my child)</option>
                    <option value="myself">Myself</option>
                    <option value="group">Group</option>
                  </Field>
                </div>

                {values.type !== 'group' && (
                  <>
                    <TextField id="firstName" prefix="profile" required label="First Name" name="firstName" placeholder="First Name" />

                    <TextField id="lastName" prefix="profile" required label="Last Name" name="lastName" placeholder="Last Name" />
                  </>
                )}

                {values.type === 'someone' && (
                  <TextField
                    id="relationship"
                    label={`My relationship with ${values.firstName || 'this person'}`}
                    name="relationship"
                    placeholder="E.g., Dad, Grandma, Aunt"
                  />
                )}

                {values.type === 'group' && (
                  <>
                    <TextField id="firstName" required label="Group Name" name="firstName" placeholder="Group Name" />

                    <TextField id="description" required label="Group Description" name="description" placeholder="Group Description" />
                  </>
                )}
              </section>

              <section className="footer-action">
                <Button type="primary" loading={isSubmitting} right submit>
                  {values.type === 'group' ? 'Create Group' : 'Create Profile'}
                </Button>
              </section>

              {error && error.message && (
                <section className="article-errors">
                  <div>{error.message}</div>
                </section>
              )}
            </Form>
          </FormikProvider>
        </Card>
      </div>
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(inject('stores')(observer(ProfileCreate)));
