import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useField} from 'formik';
import dayjs from 'dayjs';
import classNames from 'classnames';
import {useFallback} from '../../hooks';
import getClassNames from './styleFields';

function DateField({name, helpertext, prefix, label, onFocus, placeholder, wrapperClassName, required, readOnly, ...rest}) {
  const [{value, onChange, onBlur}, {touched, error}, {setValue, setTouched}] = useField({name});
  const [focused, setFocus] = useState(false);

  const isError = touched && !!error;

  const memoizedFocus = useFallback(() => setFocus(true), onFocus, [setFocus]);
  const memoizedBlur = useFallback(
    () => {
      setTouched(true);
      setFocus(false);
    },
    onBlur,
    [setTouched, setFocus]
  );
  const memoizedChange = useFallback(date => setValue(date), onChange, [setValue]);

  const {labelClassName, invalidClassName, errorClassName, focusClassName, fieldClassName} = getClassNames(prefix);

  return (
    <label
      htmlFor="date"
      className={classNames(labelClassName, wrapperClassName, {
        [invalidClassName]: isError,
        [focusClassName]: focused,
      })}>
      {!readOnly && required && <span className={`required ${labelClassName}_required`}> * </span>}
      {label} {isError && <small className={errorClassName}>{error}</small>}
      <DatePicker
        id="date"
        shouldCloseOnSelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        className={fieldClassName}
        placeholderText={placeholder}
        selected={dayjs(value).unix() * 1000}
        onFocus={memoizedFocus}
        onBlur={memoizedBlur}
        onChange={memoizedChange}
      />
      {helpertext && <small>{helpertext}</small>}
    </label>
  );
}

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  helpertext: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
};

DateField.defaultProps = {
  prefix: '',
  helpertext: '',
  wrapperClassName: '',
  required: false,
  readOnly: false,
  onFocus: null,
  placeholder: '',
};

export default DateField;
