import React from 'react';
import {createRoot} from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';
import './assets/scss/base.scss';
import App from './app';

// Sentry - Error Monitoring

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://5a5a9c264cd14d2a817c3746e2355571@o439073.ingest.sentry.io/5406775',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

serviceWorker.unregister();
