/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import {NotificationManager} from 'react-notifications';
import {httpsCallable} from 'firebase/functions';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Button, Heading, Icon, Modal} from '../common';
import withAuthorization from '../session/withAuthorization';

import '../../assets/scss/components/profile/profile-form.scss';
import {functions} from '../../firebase/firebase';
import ToggleButton from '../common/ToggleButton';

dayjs.extend(utc);
dayjs.extend(timezone);

function Item({item, firstname, checked, setSections, disabled}) {
  const handleSection = () => {
    setSections(prev => (checked || disabled ? prev.filter(key => key !== item.key) : [...prev, item.key]));
  };
  return (
    <div key={item?.key} className="profile-list">
      <div className="profile-list-text">
        <div>
          {item?.addFirstNameToTitle ? `${firstname}'s ` : ''}
          {item?.name}
        </div>
        {disabled && <div className="profile-list-description">Please enter data to section and save to add this section to the report</div>}
      </div>
      <ToggleButton disabled={disabled} checked={checked && !disabled} onClick={handleSection} />
    </div>
  );
}

function ProfileExport({profile, optionalSections = [], availableProfileSections}) {
  const [exporting, setExporting] = useState(false);
  const [open, setOpen] = useState(false);
  const [sections, setSections] = useState(
    Object.keys(optionalSections)
      .filter(key => key !== 'id')
      .filter(key => Object.values(optionalSections[key]).some(value => value?.length))
  );

  const disabledSections = useMemo(
    () =>
      Object.keys(optionalSections)
        .filter(key => key !== 'id')
        .filter(key => !Object.values(optionalSections[key]).some(value => value?.length)),
    [optionalSections]
  );

  const listOfSections = useMemo(
    () =>
      // eslint-disable-next-line fp/no-mutating-methods
      Object.keys(availableProfileSections)
        .filter(key => Object.keys(optionalSections).includes(key) && !optionalSections[key].hiddenSection)
        .map(key => ({key, ...availableProfileSections[key]}))
        .sort((a, b) => a.index - b.index),
    [availableProfileSections, optionalSections]
  );

  useEffect(() => setSections(listOfSections.filter(obj => !disabledSections.includes(obj.key)).map(obj => obj.key)), [disabledSections, listOfSections]);

  const exportPDF = useCallback(
    async event => {
      event.preventDefault();
      NotificationManager.info('The profile report is being generated.');
      try {
        setOpen(false);
        setExporting(true);
        const obj = {
          profileId: profile.id,
          sections,
          date: dayjs().format('DD-MM-YY HH:mm'),
          timezone: dayjs.tz.guess(),
        };
        const res = await httpsCallable(functions, 'generateProfilePDF')(obj);
        window.open(res.data.url);
      } catch (error) {
        console.log(error);
      } finally {
        setExporting(false);
      }
    },
    [profile, sections]
  );

  const list = useMemo(
    () =>
      listOfSections.map(item => ({
        item,
        firstname: profile.firstname,
        checked: sections.includes(item.key),
        disabled: disabledSections.includes(item.key),
        setSections,
      })),
    [listOfSections, profile, sections, disabledSections]
  );

  const handleOpenModal = useCallback(() => setOpen(true), []);
  const handleCloseModal = useCallback(() => setOpen(false), []);

  return (
    <>
      <Button
        customClass="profile-export__button"
        right
        type="primary-light"
        size="tiny"
        loading={exporting}
        loadingMessage={<>&nbsp;Preparing Report</>}
        onClick={handleOpenModal}>
        <Icon type="mail" /> &nbsp; Profile Report
      </Button>
      <Modal isOpen={open} handleDismiss={handleCloseModal} showDismiss>
        <Heading primary>
          <h4>Choose sections for the PDF</h4>
        </Heading>
        <div>
          {list.map(props => (
            <Item key={props?.item?.key} {...props} />
          ))}
        </div>
        <div style={{paddingTop: 6}}>
          <Button disabled={!sections.length} right type="primary" size="standard" onClick={exportPDF}>
            Generate
          </Button>
        </div>
      </Modal>
    </>
  );
}

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(observer(ProfileExport));
