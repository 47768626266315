/* eslint-disable fp/no-mutation */
import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {Provider} from 'mobx-react';
import Intercom from 'react-intercom';
import {NotificationContainer} from 'react-notifications';

import Navigation from './components/navigation';
import SignUpPage from './components/account/signup';
import LogInPage from './components/account/login';
import PasswordResetPage from './components/account/password.reset';
import {Modal, Heading, Toastify} from './components/common';
import Privacy from './components/home/privacy';
import Terms from './components/home/terms';

import withAuthentication from './components/session/withAuthentication';
import AuthUserContext from './components/session/AuthUserContext';
import routes from './constants/routes';

import intercomConfig from './constants/intercom';
import config from './constants/config';
import {MainStore} from './stores/mainStore';
import AuthedRoutes from './AuthedRoutes';
import Analytics from './helpers/Analytics';

const mainStore = new MainStore();
window.mainStore = mainStore;

const transformUserToIntercom = authUser => {
  if (!authUser) {
    return {};
  }
  const user = {
    user_id: authUser.uid,
    email: authUser.email,
    phone: authUser.mobile,
  };
  if (authUser.firstname) user.name = `${authUser.firstname} ${authUser.lastname}`;
  return user;
};

class App extends React.Component {
  state = {
    privacyModalOpen: false,
    termsModalOpen: false,
    signupInProgress: false,
  };

  openPrivacyModal = e => {
    e.preventDefault();

    this.setState({
      privacyModalOpen: true,
      termsModalOpen: false,
    });
  };

  openTermsModal = e => {
    e.preventDefault();

    this.setState({
      privacyModalOpen: false,
      termsModalOpen: true,
    });
  };

  onStartSignup = () => {
    this.setState({
      signupInProgress: true,
    });
  };

  onStopSignup = () => {
    this.setState({
      signupInProgress: false,
    });
  };

  render() {
    const {privacyModalOpen, termsModalOpen, signupInProgress} = this.state;

    const googleAnalyticsRef = React.createRef();
    const segmentRef = React.createRef();

    return (
      <div className="app">
        <Provider stores={mainStore}>
          <Router>
            <Route
              render={({location, history}) => (
                <>
                  <Route path="*" component={Navigation} />
                  <AuthUserContext.Consumer>
                    {authUser =>
                      authUser === null || signupInProgress ? (
                        <div className="auth">
                          <Switch location={location}>
                            <Route exact path={routes.user.log_in} component={LogInPage} />
                            <Route exact path={routes.user.sign_up} render={() => <SignUpPage />} />
                            <Route exact path={routes.user.password_reset} component={PasswordResetPage} />
                            <Route
                              exact
                              path={routes.user.sign_up.individual}
                              render={() => (
                                <SignUpPage
                                  openPrivacyModal={this.openPrivacyModal}
                                  openTermsModal={this.openTermsModal}
                                  onStartSignup={this.onStartSignup}
                                  onStopSignup={this.onStopSignup}
                                />
                              )}
                            />
                            <Route
                              exact
                              path={routes.user.sign_up.provider}
                              render={() => (
                                <SignUpPage
                                  openPrivacyModal={this.openPrivacyModal}
                                  openTermsModal={this.openTermsModal}
                                  onStartSignup={this.onStartSignup}
                                  onStopSignup={this.onStopSignup}
                                />
                              )}
                            />
                            <Route
                              exact
                              path={routes.user.sign_up.family}
                              render={() => (
                                <SignUpPage
                                  openPrivacyModal={this.openPrivacyModal}
                                  openTermsModal={this.openTermsModal}
                                  onStartSignup={this.onStartSignup}
                                  onStopSignup={this.onStopSignup}
                                  defaultType="individual"
                                />
                              )}
                            />
                            <Route
                              exact
                              path={routes.user.sign_up_provider}
                              render={() => (
                                <SignUpPage
                                  openPrivacyModal={this.openPrivacyModal}
                                  openTermsModal={this.openTermsModal}
                                  onStartSignup={this.onStartSignup}
                                  onStopSignup={this.onStopSignup}
                                  defaultType="provider"
                                />
                              )}
                            />
                            <Route
                              exact
                              path={routes.user.sign_up_family}
                              render={() => (
                                <SignUpPage
                                  openPrivacyModal={this.openPrivacyModal}
                                  openTermsModal={this.openTermsModal}
                                  onStartSignup={this.onStartSignup}
                                  onStopSignup={this.onStopSignup}
                                  defaultType="family"
                                />
                              )}
                            />
                            <Redirect to={routes.user.log_in} />
                          </Switch>
                        </div>
                      ) : null
                    }
                  </AuthUserContext.Consumer>
                  {/* Authenticated Paths */}
                  {!signupInProgress && <AuthedRoutes location={location} history={history} />}

                  <AuthUserContext.Consumer>
                    {authUser => (
                      <Analytics
                        authUser={authUser}
                        googleAnalyticsRef={googleAnalyticsRef}
                        googleAnalyticsId={config.googleAnalyticsId}
                        segmentRef={segmentRef}
                        segmentSourceID={config.segmentSourceID}
                        segmentWriteKey={config.segmentWriteKey}
                        debug
                        trackPathnameOnly
                      />
                    )}
                  </AuthUserContext.Consumer>
                </>
              )}
            />
          </Router>
        </Provider>

        <Toastify />

        <div className="footer-menu">
          <hr />
          <a href={routes.about.home} target="_blank" rel="noopener noreferrer">
            <strong>Sameview</strong>
          </a>
          <a href={routes.about.privacy} target="_blank" rel="noopener noreferrer" onClick={e => this.openPrivacyModal(e)}>
            Privacy
          </a>
          <a href={routes.about.terms} target="_blank" rel="noopener noreferrer" onClick={e => this.openTermsModal(e)}>
            Terms &amp; Conditions
          </a>
          {privacyModalOpen && (
            <Modal type="full" isOpen={privacyModalOpen} handleDismiss={() => this.setState({privacyModalOpen: false})} showDismiss>
              <Heading primary>
                <h4>Sameview - Privacy Policy</h4>
              </Heading>
              <Privacy />
            </Modal>
          )}
          {termsModalOpen && (
            <Modal type="full" isOpen={termsModalOpen} handleDismiss={() => this.setState({termsModalOpen: false})} showDismiss>
              <Heading primary>
                <h4>Sameview - Terms of Service</h4>
              </Heading>
              <Terms />
            </Modal>
          )}
        </div>

        <NotificationContainer />
        <AuthUserContext.Consumer>
          {/* React-intercom will automatically send updates to the user object to Intercom */}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {authUser => authUser && <Intercom appID={intercomConfig.app_id} {...transformUserToIntercom(authUser)} />}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

export default withAuthentication(App);
