/* eslint-disable react/prop-types */
import React, {useRef, useCallback, useState} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {RiArrowUpDownLine} from 'react-icons/ri';

function GoalStep({id, goalId, index, text, complete, count, onToggleComplete, onMoveGoalStep}) {
  const ref = useRef(null);

  const [stepCompleted, setStepCompleted] = useState(complete);

  const [{handlerId}, drop] = useDrop({
    accept: `goal-${goalId}-step`,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      onMoveGoalStep(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{isDragging}, drag] = useDrag({
    type: `goal-${goalId}-step`,
    item: () => ({id, index}),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleToggleComplete = useCallback(() => {
    setStepCompleted(!stepCompleted);
    onToggleComplete(goalId, id);
  }, [stepCompleted, onToggleComplete, goalId, id]);

  const opacity = isDragging ? 0.3 : 1;

  drag(drop(ref));

  return (
    <div className="step step__draggable" ref={ref} style={{opacity}} data-handler-id={handlerId}>
      <div className="action">
        <button type="button" className={`button -toggle ${stepCompleted ? '-complete' : ''}`} onClick={handleToggleComplete}>
          <i className="icon-check" />
        </button>
      </div>
      <div className="content">
        <RiArrowUpDownLine className="draggable" />
        <div className="title">{text}</div>
        <div className="meta">
          <div className="item -steps -primary">
            <i className="icon-star" />
            <span>{count}</span>
          </div>
        </div>
      </div>
      <i className="down-open-mini" />
    </div>
  );
}

export default GoalStep;
