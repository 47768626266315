import React from 'react';
import PropTypes from 'prop-types';
import Notification from './notification';
import spaceSrc from '../../assets/svg/space.svg';

function Notifications({profiles, notifications, notificationLength, selectedProfile, onProfileSelect, onClear, onClick}) {
  const showingLength = Object.keys(notifications).length;
  const hasNotifications = showingLength > 0;
  const hasMoreNotifications = showingLength < notificationLength;

  return (
    <div className="section-notifications">
      <div className="section-notifications-header">
        <h6>View notifications for</h6>
        {hasNotifications && (
          <button type="button" onClick={onClear}>
            <span>Mark all read</span>
          </button>
        )}
      </div>
      <div className="notifications-filter">
        <div className="select">
          <form>
            <select value={selectedProfile} onChange={onProfileSelect}>
              <option value="everyone">Everyone</option>
              {profiles &&
                profiles.map(profile => (
                  <option key={`notification-profile-${profile.id}`} value={profile.id}>{`${
                    profile.profileSections?.essentials?.firstname || profile.firstname
                  } ${profile.profileSections?.essentials?.lastname || profile.lastname}`}</option>
                ))}
            </select>
          </form>
        </div>
      </div>

      {hasNotifications &&
        Object.entries(notifications).map(([id, notification]) => (
          <Notification
            key={`notification-${notification.id}`}
            type={notification.type}
            link={notification.link}
            avatar={notification.avatar}
            time={notification.time}
            name={notification.name}
            profile={notification.profile}
            text={notification.text}
            onClick={onClick}
          />
        ))}

      {hasMoreNotifications && <div className="more">Plus {notificationLength - showingLength} other notifications</div>}

      {!hasNotifications && (
        <div className="empty">
          <div className="empty-inner">
            <span>No new notifications</span>
            <img src={spaceSrc} alt="space.svg" />
          </div>
        </div>
      )}
    </div>
  );
}

Notifications.propTypes = {
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      profileSections: PropTypes.shape({}),
    })
  ),
  selectedProfile: PropTypes.string,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      type: 'TEAM_CREATE_POST',
      link: PropTypes.string,
      avatar: PropTypes.string,
      time: PropTypes.number,
      name: PropTypes.string,
      profile: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  notificationLength: PropTypes.number,
  onProfileSelect: PropTypes.func,
  onClear: PropTypes.func,
  onClick: PropTypes.func,
};

Notifications.defaultProps = {
  profiles: [],
  selectedProfile: 'everyone',
  notifications: [],
  notificationLength: 0,
  onProfileSelect: () => {},
  onClear: event => {
    event.preventDefault();
    console.log('CLEAR NOTIFICATIONS');
  },
  onClick: () => {},
};

export default Notifications;
