/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import classnames from 'classnames';

import {DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {Button} from '../common';

import familySrc from '../../assets/svg/family.svg';
import professionalSrc from '../../assets/svg/professional.svg';
import friendSrc from '../../assets/svg/extended-family.svg';

import logo from '../../assets/img/pattern.primary.png';

import '../../assets/scss/components/invite/invite-type.scss';

function InviteType({type, label, src, selected, onMouseEnter, onClick}) {
  return (
    <div
      className={classnames('invite-type__type', {
        'invite-type__type--selected': selected,
      })}
      onMouseEnter={() => onMouseEnter(type)}
      onClick={() => onClick(type)}>
      <figure className="invite-type__img-wrap">
        <img className="invite-type__img" src={src} alt={label} />
      </figure>
      <h3 className="invite-type__label">{label}</h3>
    </div>
  );
}

const typeDescriptions = {
  family: 'Give family members (spouses, parents, siblings, grandparents etc) the ability to help manage and create posts.',
  professional:
    'These are the team members you want updates from, not just medical professionals but the school teachers, support workers and other service providers. They can only create posts, comment and like.',
  friend: 'Keep other supporters up to date by sharing posts with them. They will be able to comment and like posts only, they are unable to create posts.',
};

function Invite({className, heading = 'Invite Team Member', onSelect, onCancel, forwardedRef}) {
  const [type, setTypeSelection] = useState('family');
  const typeDescription = typeDescriptions[type];

  return (
    <>
      {heading && <DialogTitle style={{textAlign: 'center', backgroundImage: `url(${logo})`}}>{heading}</DialogTitle>}
      <DialogContent className={heading ? '' : 'no-padding'}>
        <h2 className="invite-type__heading">Who would you like to invite?</h2>
        <div className="invite-type__selection">
          <InviteType type="family" label="Immediate Family" src={familySrc} selected={type === 'family'} onMouseEnter={setTypeSelection} onClick={onSelect} />
          <InviteType
            type="professional"
            label="Professional"
            src={professionalSrc}
            selected={type === 'professional'}
            onMouseEnter={setTypeSelection}
            onClick={onSelect}
          />
          <InviteType type="friend" label="Other Supports" src={friendSrc} selected={type === 'friend'} onMouseEnter={setTypeSelection} onClick={onSelect} />
        </div>
        <p className="invite-type__description">{typeDescription}</p>
      </DialogContent>
      {heading && (
        <DialogActions>
          <Button type="tertiary" left onClick={onCancel}>
            Cancel
          </Button>
        </DialogActions>
      )}
    </>
  );
}

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <Invite {...props} forwardedRef={ref} />);
