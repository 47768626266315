/* eslint-disable react/prop-types */
import * as React from 'react';
import {withRouter} from 'react-router-dom';

class Analytics extends React.Component {
  constructor(props) {
    super(props);

    this.sendPageViewGoogleAnalytics = this.sendPageViewGoogleAnalytics.bind(this);
    this.initializeGoogleAnalytics = this.initializeGoogleAnalytics.bind(this);
    this.initializeGoogleAnalytics(props.googleAnalyticsId);

    this.setUserSegment = this.setUserSegment.bind(this);
    this.sendPageViewSegment = this.sendPageViewSegment.bind(this);
    this.initializeSegment = this.initializeSegment.bind(this);
    this.initializeSegment(props.segmentWriteKey);
  }

  componentDidMount() {
    const {location, history, authUser} = this.props;

    this.sendPageViewGoogleAnalytics(location);
    history.listen(this.sendPageViewGoogleAnalytics);

    this.sendPageViewSegment(location);
    history.listen(this.sendPageViewSegment);

    this.setUserSegment(authUser);
  }

  componentDidUpdate(prevProps) {
    const {authUser} = this.props;
    this.setUserSegment(authUser);
  }

  setUserSegment(authUser) {
    const {debug} = this.props;

    // Do nothing if Segment was not initialized due to a missing tracking ID.
    if (!window.analytics) {
      console.info(`[segment] Not Initialised`);
      return;
    }

    // Do nothing if trackPathnameOnly is enabled and the pathname didn't change.
    if (!authUser || !authUser.email || !authUser.lastname) {
      console.info(`[segment] User not set`);
      return;
    }

    const user = {
      firstname: authUser.firstname || '',
      lastname: authUser.lastname || '',
      email: authUser.email || '',
      phone: authUser.mobile || authUser.phoneNumber || '',
      sameviewUid: authUser.uid || '',
      about: authUser.description || '',
      organisation: (authUser.organisation && authUser.organisation.name) || '',
      type: authUser.type || '',
      invitedById: authUser.invitedBy || '',
    };

    if (JSON.stringify(user) === JSON.stringify(this.lastUserSegment)) {
      console.info(`[segment] User same as last`);
      return;
    }

    this.lastUserSegment = user;

    // Set User Identify
    window.analytics.identify(authUser.uid, user);

    if (debug) {
      console.info(`[segment] Set user: `, user);
    }
  }

  initializeGoogleAnalytics() {
    const {googleAnalyticsId} = this.props;

    if (!googleAnalyticsId) {
      console.error('[google-analytics] Tracking ID is required.');
      return;
    }

    // Load Google Analytics
    /*eslint-disable */
    (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    /* eslint-enable */

    // Initialize Google Analytics
    window.ga('create', googleAnalyticsId, 'auto');
  }

  sendPageViewGoogleAnalytics(location) {
    // eslint-disable-next-line react/prop-types
    const {trackPathnameOnly, googleAnalyticsRef, basename, debug} = this.props;

    // Do nothing if GA was not initialized due to a missing tracking ID.
    if (!window.ga) {
      console.info(`[google-analytics] Not Initialised`);
      return;
    }

    // Do nothing if trackPathnameOnly is enabled and the pathname didn't change.
    // eslint-disable-next-line react/prop-types
    if (trackPathnameOnly && location.pathname === googleAnalyticsRef.current) {
      console.info(`[google-analytics] Path name same as last`);
      return;
    }

    // eslint-disable-next-line react/prop-types
    googleAnalyticsRef.current = location.pathname;

    // Sets the page value on the tracker. If a basename is provided, then it is prepended to the pathname.
    const page = basename ? `${basename}${location.pathname}` : location.pathname;

    window.ga('set', 'page', page);

    // Sending the pageview no longer requires passing the page
    // value since it's now stored on the tracker object.
    window.ga('send', 'pageview');

    if (debug) {
      console.info(`[google-analytics] Page view: ${page}`);
    }
  }

  initializeSegment() {
    // eslint-disable-next-line react/prop-types
    const {segmentWriteKey} = this.props;

    if (!segmentWriteKey) {
      console.error('[segment] Write Key is required.');
      return;
    }

    // Load Segment
    /*eslint-disable */
    // Create a queue, but don't obliterate an existing one!
    var analytics = (window.analytics = window.analytics || []);
    // If the real analytics.js is already on the page return.
    if (analytics.initialize) return;
    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error('[segment] snippet included twice.');
      }
      return;
    }

    console.info('[segment] initializeSegment');

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;
    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware',
    ];
    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function (method) {
      return function () {
        var args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };
    // For each of our methods, generate a queueing stub.
    for (var i = 0; i < analytics.methods.length; i++) {
      var key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }
    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = function (key, options) {
      // Create an async script element based on your key.
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
      // Insert our script next to the first script element.
      var first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
      analytics._loadOptions = options;
    };
    analytics._writeKey = segmentWriteKey;
    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = '4.13.2';
    // Load Analytics.js with your key, which will automatically
    // load the tools you've enabled for your account. Boosh!
    analytics.load(segmentWriteKey);
    /* eslint-enable */

    // Track Event
    // analytics.track('Article Bookmarked', {
    //   title: 'Snow Fall',
    //   subtitle: 'The Avalanche at Tunnel Creek',
    //   author: 'John Branch',
    // });
  }

  sendPageViewSegment(location) {
    // eslint-disable-next-line react/prop-types
    const {trackPathnameOnly, segmentRef, basename, debug} = this.props;

    // Do nothing if Segment was not initialized due to a missing tracking ID.
    if (!window.analytics) {
      console.info(`[segment] Not Initialised`);
      return;
    }

    // Do nothing if trackPathnameOnly is enabled and the pathname didn't change.
    // eslint-disable-next-line react/prop-types
    if (trackPathnameOnly && location.pathname === segmentRef.current) {
      console.info(`[segment] Path name same as last`);
      return;
    }

    // eslint-disable-next-line react/prop-types
    segmentRef.current = location.pathname;

    // Sets the page value on the tracker. If a basename is provided, then it is prepended to the pathname.
    const page = basename ? `${basename}${location.pathname}` : location.pathname;

    // Lodge the page view into Segment
    window.analytics.page();

    if (debug) {
      console.info(`[segment] Page view: ${page}`);
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const {children} = this.props;

    return children || null;
  }
}

Analytics.defaultProps = {
  debug: false,
};

export default withRouter(Analytics);
