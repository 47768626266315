import React from 'react';

import {Card} from '../common';
import PostCreate from '../post/create';

function HomeAside() {
  return (
    <div className="profile-aside">
      <Card full>
        <PostCreate />
      </Card>
    </div>
  );
}

export default HomeAside;
