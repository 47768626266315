import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function Heading({primary, secondary, modal, children}) {
  return (
    <div
      className={classnames('heading', {
        '-primary': primary,
        '-secondary': secondary,
        '-modal': modal,
      })}>
      {children}
    </div>
  );
}

Heading.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  modal: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Heading.defaultProps = {
  primary: false,
  secondary: false,
  modal: false,
};

export default Heading;
