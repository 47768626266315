/* eslint-disable react/prop-types */
import React from 'react';
import {Prompt} from 'react-router';
import {useFormState} from 'informed';

function FormDirtyPrompt({isSaving = false, message = 'You have unsaved changes. Are you sure you want to leave without saving?'}) {
  const {dirty, touched} = useFormState();

  return <Prompt when={dirty && Object.keys(touched).length > 0 && !isSaving} message={message} />;
}

export default FormDirtyPrompt;
