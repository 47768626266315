import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import routes from '../../constants/routes';
import {Avatar} from '../common';

function ProfileHeader(props) {
  const {profile} = props;
  const {profileImage, firstname, lastname} = profile.profileSections?.essentials || {};
  const {id: profileId} = profile;
  const {url} = profileImage || {};

  return (
    <div className="container">
      <div className="profile-header">
        <div className="container">
          <div className="image">
            <Avatar className="profile-image" src={url} />
            &nbsp;
          </div>
          <div className="name">
            <h1>
              {firstname} {lastname}
            </h1>
          </div>
        </div>
      </div>
      <div className="profile-nav">
        <div className="container">
          <ul className="menu-secondary">
            <li>
              <Link
                to={routes.generate(routes.profile.home, {
                  profileId,
                })}>
                Home
              </Link>
            </li>
            <li>
              <Link
                to={routes.generate(routes.profile.about, {
                  profileId,
                })}>
                About
              </Link>
            </li>
            <li>
              <Link
                to={routes.generate(routes.profile.goals, {
                  profileId,
                })}>
                Goals
              </Link>
            </li>
            <li>
              <Link
                to={routes.generate(routes.profile.documents, {
                  profileId,
                })}>
                Documents
              </Link>
            </li>
            <li>
              <Link
                to={routes.generate(routes.profile.team, {
                  profileId,
                })}>
                Team
              </Link>
            </li>
            {process.env.REACT_APP_CALENDAR_ENABLED && (
              <li>
                <Link
                  to={routes.generate(routes.profile.calendar, {
                    profileId,
                  })}>
                  Calendar
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

ProfileHeader.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.string,
    profileSections: PropTypes.shape({
      essentials: PropTypes.shape({
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        profileImage: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
};

ProfileHeader.defaultProps = {
  profile: {
    id: '',
    profileSections: {
      essentials: {
        firstname: '',
        lastname: '',
        profileImage: {
          url: '',
        },
      },
    },
  },
};

export default ProfileHeader;
