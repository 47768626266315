import React from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {getFullName} from '../../helpers/getFullName';
import routes from '../../constants/routes';

import UserNamePlaceholder from '../utilities/usernamePlaceholder';
import {errorLogger} from '../../helpers/errorLogger';

export const UserNameLink = observer(({profileId, user}) => {
  if (!user) {
    errorLogger(new Error('No user passed to UserNameLink'));
    return null;
  }
  if (user.isFetching) {
    return <UserNamePlaceholder />;
  }

  return (
    <Link
      className="user-link"
      to={routes.generate(routes.profile.teamMember, {
        profileId,
        userId: user.id,
      })}>
      {getFullName(user)}
    </Link>
  );
});

export default UserNameLink;
