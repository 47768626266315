/* eslint-disable react/prop-types */
import React from 'react';
import {TiUserAddOutline} from 'react-icons/ti';
import {FaPencilAlt, FaAward} from 'react-icons/fa';

import '../../assets/scss/components/onboarding/progress.scss';

const DONE_COLOR = '#40BDAF';
const UNDONE_COLOR = '#BBBBBB';

function OnboardingSteps({step}) {
  return (
    <div>
      <div className="progress">
        <div className="progress__circle-done">
          <TiUserAddOutline size={38} color={DONE_COLOR} />
          <p>Add your team</p>
        </div>
        <div className={step >= 2 ? 'progress__line-done' : 'progress__line'} />
        <div className={step >= 2 ? 'progress__circle-done' : 'progress__circle'}>
          <FaPencilAlt size={38} color={step >= 2 ? DONE_COLOR : UNDONE_COLOR} />
          <p>Write your first post</p>
        </div>
        <div className={step >= 3 ? 'progress__line-done' : 'progress__line'} />
        <div className={step >= 3 ? 'progress__circle-done' : 'progress__circle'}>
          <FaAward size={38} color={step >= 3 ? DONE_COLOR : UNDONE_COLOR} />
          <p>Set some goals</p>
        </div>
      </div>
    </div>
  );
}

export default OnboardingSteps;
