// eslint-disable-next-line import/prefer-default-export
export const getMentionsFromPost = text =>
  text.split('@[').map((str = '') => {
    if (str.length === 0) return {text: ''};
    if (str.split('](').length === 1) return {text: str};
    const name = str.split('](')[0];
    const id = str.split('](')[1].split(')')[0];
    return {
      name,
      id,
      text: str.replace(name, '').replace(id, '').replace(']()', ''),
    };
  });

export const getTextFromPost = (text = '') =>
  getMentionsFromPost(text)
    .map(mention => `${mention.name ? `${mention.name} ` : ''}${mention.text}`)
    .join('');

export const addResizeSuffixToImage = (path, suffix = '_1024x1024') => {
  // eslint-disable-next-line no-useless-escape
  const lastDotRegex = /\.(?=[^\.]+$)/;
  return `${path.split(lastDotRegex)[0]}${suffix}.${path.split(lastDotRegex)[1]}`;
};
