/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';

import {inject, observer} from 'mobx-react';
import Post from '../post';
import Feed from './index';
import withProfile from '../profile/withProfile';

function GoalFeed(props) {
  const {
    title,
    mode,
    profile,
    goalId,
    stores: {postsStore, profilesStore},
  } = props;

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    setPosts(postsStore.goalPosts);
  }, [postsStore.goalPosts, setPosts]);

  useEffect(() => {
    postsStore.fetchGoalPosts(profile.id, goalId);
  }, [profile.id, goalId, postsStore]);

  // eslint-disable-next-line fp/no-mutating-methods
  const dateSortedFeedData = posts
    .slice()
    .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
    .map((post, idx) => {
      const newProfile = profilesStore.getProfileById(post.profileId);

      // Show this post if there is a profile and the post is related to this goal.
      if (!newProfile || !post.goals[goalId]) {
        return null;
      }
      return <Post mode={mode} data={post} profile={newProfile} key={post.id} postClass={idx === 0 ? 'post-single--first' : ''} />;
    });

  const handleClickMorePosts = () => {
    // todo: do we need this one? probably not, we should just load all feed posts
    postsStore.getMorePosts(profile.id);
  };

  return <Feed goalId={goalId} title={title} posts={dateSortedFeedData} handleClickMorePosts={handleClickMorePosts} />;
}

export default inject('stores')(withProfile(observer(GoalFeed)));
