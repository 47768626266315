import React from 'react';
import PropTypes from 'prop-types';
import {useField} from 'formik';
import DateField from './DateField';

function DateRangeField({name}) {
  const {value: timeframeBegin} = useField(`${name}.begin`);
  const {value: timeframeEnd} = useField(`${name}.end`);

  return (
    <>
      <DateField
        prefix="goal-edit"
        name={`${name}.begin`}
        label="Time Frame"
        placeholder="DD/MM/YY"
        dateFormat="dd/MM/yyyy"
        selectsStart
        startDate={timeframeBegin}
        endDate={timeframeEnd}
      />
      -
      <DateField
        prefix="goal-edit"
        name={`${name}.end`}
        placeholder="DD/MM/YY"
        dateFormat="dd/MM/yyyy"
        selectsEnd
        startDate={timeframeBegin}
        endDate={timeframeEnd}
        minDate={timeframeBegin}
        popperPlacement="bottom-end"
      />
    </>
  );
}

DateRangeField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DateRangeField;
