/* eslint-disable react/prop-types */
import React from 'react';
import Modal from '../utilities/modal';

export function PostDeleteButton({className, onDeleteClick}) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div className={`item -delete ${className ?? ''}`} onClick={onDeleteClick}>
      <i className="icon-trash" />
    </div>
  );
}

export function PostDeleteModal({handleDismiss, onDeleteConfirm}) {
  return (
    <Modal handleDismiss={handleDismiss}>
      <div className="header">Are you sure you want to delete this post?</div>

      <div className="action">
        <button type="button" className="button -tertiary" onClick={handleDismiss}>
          Cancel
        </button>
        <button type="button" className="button -primary" onClick={onDeleteConfirm}>
          Delete Post
        </button>
      </div>
    </Modal>
  );
}
