/* eslint-disable react/prop-types */

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {Button} from '../common';

import '../../assets/scss/components/payment/billing-details.scss';

function BillingDetails({cardName, creditCard, creditExpMonth, creditExpYear, address, email, onEditCard, canEdit}) {
  const addressDisplay = useMemo(
    () =>
      address
        ? (address.line1 ? address.line1 : '') +
          (address.line2 ? `, ${address.line2}` : '') +
          (address.city ? `, ${address.city}` : '') +
          (address.state ? `, ${address.state}` : '') +
          (address.postal_code ? `, ${address.postal_code}` : '')
        : '',
    [address]
  );

  return (
    <section className="billing-details">
      <h4>Billing Details</h4>

      <div className="billing-details__section">
        <p>{cardName}</p>
        <p>{addressDisplay}</p>
        <p>{email}</p>
        <p className="billing-details__card">
          <span>{creditCard}</span>
          <span>
            {creditExpMonth} / {creditExpYear}
          </span>
          {canEdit && (
            <Button type="tertiary" size="small" right onClick={onEditCard}>
              Edit Billing
            </Button>
          )}
        </p>
      </div>
    </section>
  );
}

BillingDetails.propTypes = {
  cardName: PropTypes.string,
  creditCard: PropTypes.string,
  creditExpMonth: PropTypes.string,
  creditExpYear: PropTypes.string,
  address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postal_code: PropTypes.string,
  }),
  email: PropTypes.string,
  onEditCard: PropTypes.func,
  canEdit: PropTypes.bool,
};

BillingDetails.defaultProps = {
  cardName: '',
  creditCard: '',
  creditExpMonth: '',
  creditExpYear: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postal_code: '',
  },
  email: '',
  onEditCard: () => {},
  canEdit: false,
};

export default BillingDetails;
