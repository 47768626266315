/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useCallback, useState} from 'react';
import {uploadFile} from '../../firebase/db';
import {errorLogger} from '../../helpers/errorLogger';
import LoadingWithLabel from '../utilities/LoadingWithLabel';

function ImageUpload({inputName, inputId, value, filePath, onComplete}) {
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [error, setError] = useState(false);

  const onUploadProgress = useCallback((_, percentage) => setUploadPercentage(percentage), []);

  const onImageUploadError = useCallback((_, err) => {
    errorLogger(err);
    setError(true);
    setUploadPercentage(0);
  }, []);

  const onImageUploadComplete = useCallback((file, url) => onComplete(file, url), [onComplete]);

  const deleteImageUploadFile = useCallback(
    e => {
      e.preventDefault();
      onComplete('');
      setUploadPercentage(0);
      setError(false);
    },
    [onComplete]
  );

  const handleImageUpload = useCallback(
    e => {
      const file = e.target.files[0];
      const uniqFileName = `${new Date().getTime()}-${file.name}`;
      uploadFile(`${filePath}${uniqFileName}`, file, onUploadProgress, onImageUploadError, onImageUploadComplete);
      setUploadPercentage(0.1); // Start the progressbar before the first callback
    },
    [filePath, onImageUploadComplete, onImageUploadError, onUploadProgress]
  );

  return (
    <div className="file-upload">
      {value && (
        <button type="button" className="button -primary file-remove" onClick={deleteImageUploadFile}>
          <i className="icon-camera" />
          <i className="icon-cancel" />
        </button>
      )}
      {!value && error && (
        <span>
          Error uploading file{' '}
          <button type="button" onClick={deleteImageUploadFile}>
            (Retry)
          </button>
        </span>
      )}
      {!value && !error && uploadPercentage === 0 && (
        <span>
          <input type="file" onChange={handleImageUpload} name={inputName || `image_upload_name`} id={inputId || `image_upload_id`} className="inputfile" />
          <label htmlFor={inputName || `image_upload_name`} className="button -primary-light">
            <i className="icon-camera" />
          </label>
        </span>
      )}
      {!value && !error && uploadPercentage > 0 && (
        <button type="button" className="button -primary-light file-progress" onClick={deleteImageUploadFile}>
          <LoadingWithLabel progress={uploadPercentage} />
        </button>
      )}
    </div>
  );
}

export default ImageUpload;
