/* eslint-disable no-nested-ternary */
/* eslint-disable func-names */
/* eslint-disable fp/no-mutation */
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useInView} from 'react-intersection-observer';

import '../../assets/scss/components/gallery.scss';

const SMALLEST_WIDTH = 200; /* px */

const loadedImages = new Set();

function MasonryImage({widest, ratio, orientation, name, lqipSrc, src}) {
  const [preloading, setPreloading] = useState(lqipSrc && !loadedImages.has(src));
  const [ref, inView] = useInView({triggerOnce: true});

  useEffect(() => {
    if (!inView || loadedImages.has(src)) return () => null;
    if (lqipSrc) {
      setPreloading(true);
      const img = new Image();
      img.onload = () => {
        setPreloading(false);
        loadedImages.add(src);
      };
      img.src = src;
    }
    return () => null;
  }, [inView, src, lqipSrc]);

  let flex = 1 / (widest / ratio);
  flex = flex !== 0 ? flex : 1;
  const orientationClass = orientation;
  const width = SMALLEST_WIDTH * flex;

  return (
    <a className="gallery-figure" style={{flex, minWidth: width}} href={src} target="_blank" rel="noopener noreferrer" ref={ref}>
      <img
        className={`gallery-image ${orientationClass}`}
        src={preloading ? lqipSrc : src}
        alt={name}
        style={{width: '100%', height: 'auto', filter: `blur(${preloading ? '50' : '0'}px)`}}
      />
    </a>
  );
}

MasonryImage.propTypes = {
  src: PropTypes.string.isRequired,
  ratio: PropTypes.number.isRequired,
  widest: PropTypes.number.isRequired,
  orientation: PropTypes.string.isRequired,
};

function MasonryVideo({src, thumbSrc, lqipSrc, mp4Src, oggSrc, widest, ratio}) {
  const [preloading, setPreloading] = useState(lqipSrc && !loadedImages.has(thumbSrc));
  const [ref, inView] = useInView({triggerOnce: true});

  useEffect(() => {
    if (!inView || !thumbSrc || loadedImages.has(thumbSrc)) return () => null;
    if (lqipSrc) {
      setPreloading(true);
      const img = new Image();
      img.onload = () => {
        setPreloading(false);
        loadedImages.add(thumbSrc);
      };
      img.src = thumbSrc;
    }
    return () => null;
  }, [inView, thumbSrc, lqipSrc]);

  let flex = 1 / (widest / ratio) || 1;
  flex = flex !== 0 ? flex : 1;
  const width = SMALLEST_WIDTH * flex;
  return (
    <div className="gallery-figure" style={{flex, minWidth: width}} ref={ref}>
      {mp4Src && oggSrc && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          controls
          className="gallery-video"
          poster={preloading ? lqipSrc : thumbSrc}
          preload={thumbSrc ? 'none' : 'metadata'}
          style={{filter: `blur(${preloading ? '50' : '0'}px)`}}>
          <source src={mp4Src} type="video/mp4" />
          <source src={oggSrc} type="video/ogg" />
          <a href={src} target="_blank" rel="noopener noreferrer">
            View the video
          </a>
        </video>
      )}
      {!(mp4Src && oggSrc) && (
        <a href={src} target="_blank" rel="noopener noreferrer" className="gallery-video-loading">
          The video is ready to be downloaded and viewed
        </a>
      )}
    </div>
  );
}

MasonryVideo.propTypes = {
  src: PropTypes.string.isRequired,
  thumbSrc: PropTypes.string,
  lqipSrc: PropTypes.string,
  mp4Src: PropTypes.string,
  oggSrc: PropTypes.string,
  widest: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
};

MasonryVideo.defaultProps = {
  thumbSrc: null,
  lqipSrc: null,
  mp4Src: null,
  oggSrc: null,
};

const load = ({
  type,
  postFilePublic: url,
  postFilePublicMp4: mp4Url,
  postFilePublicOgg: oggUrl,
  postFilePublicLqip: lqipUrl,
  postFilePublicThumb: thumbUrl,
}) => {
  if (type.match(/video.*/)) {
    return new Promise((resolve, reject) => {
      if (thumbUrl && lqipUrl) {
        const img = new Image();
        img.onload = () => {
          resolve({
            url,
            mp4Url,
            oggUrl,
            thumbUrl,
            lqipUrl,
            type,
            ratio: img.naturalWidth / img.naturalHeight,
          });
        };
        img.src = lqipUrl;
        return;
      }

      const video = document.createElement('video');
      video.addEventListener(
        'loadedmetadata',
        function () {
          // send back result
          resolve({
            url,
            mp4Url,
            oggUrl,
            thumbUrl,
            lqipUrl,
            type,
            ratio: this.videoWidth / this.videoHeight,
          });
        },
        false
      );
      video.src = url;
    });
  }

  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve({
        url,
        type,
        lqipUrl,
        orientation: img.naturalWidth > img.naturalHeight ? 'landscape' : 'portrait',
        ratio: img.naturalWidth / img.naturalHeight,
        ratioHeight: img.naturalHeight / img.naturalWidth,
      });
    };

    img.src = lqipUrl || url;
  });
};

const descentOrder = (a, b) => {
  const ratioA = a.ratio;
  const ratioB = b.ratio;

  return ratioA === ratioB ? 0 : ratioA < ratioB ? 1 : -1;
};

function MasonryGallery({mode, files}) {
  const [galleryFiles, setGalleryFiles] = useState([]);
  const [widest, setWidest] = useState(null);

  useEffect(() => {
    Promise.all(files.map(load))
      // eslint-disable-next-line fp/no-mutating-methods
      .then(ratios => ratios.sort(descentOrder))
      .then(newGalleryFiles => {
        setGalleryFiles(newGalleryFiles);
        setWidest(newGalleryFiles.length ? newGalleryFiles[0].ratio : null);
      });
  }, [files, setGalleryFiles, setWidest]);

  return (
    <div className="gallery show">
      {galleryFiles.map(file => {
        switch (file.type.split('/')[0]) {
          case 'image':
            return (
              <MasonryImage
                key={file.url}
                src={file.url}
                lqipSrc={mode === 'pdf' ? null : file.lqipUrl}
                ratio={file.ratio}
                widest={widest}
                orientation={file.orientation}
                ratioHeight={file.ratioHeight}
              />
            );
          case 'video':
            if (mode === 'pdf') {
              return (
                <a key={file.url} className="gallery-figure" href={file.url} target="_blank" rel="noopener noreferrer">
                  <img className="gallery-image landscape" src={file.thumbUrl} alt={file.url} />
                </a>
              );
            }
            return (
              <MasonryVideo
                key={file.url}
                src={file.url}
                oggSrc={file.oggUrl}
                mp4Src={file.mp4Url}
                thumbSrc={file.thumbUrl}
                lqipSrc={mode === 'pdf' ? null : file.lqipUrl}
                ratio={file.ratio}
                widest={widest}
                type={file.type}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
}

MasonryGallery.propTypes = {
  mode: PropTypes.string.isRequired,
  files: PropTypes.shape({}).isRequired,
};

export default MasonryGallery;
