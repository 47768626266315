/* eslint-disable react/prop-types */
import React from 'react';
import {Redirect} from 'react-router-dom';
import {observer, inject} from 'mobx-react';

import {Card} from '../common';
import {useProfileCompletion} from '../../hooks';
import ProfileAside from './profile.aside';
import ProfileFeed from '../feed/feed.profile';
import SinglePostFeed from '../feed/feed.single';
import Loading from '../utilities/loading';
import getIsViewer from '../../helpers/getIsViewer';

function ProfileHome({postId, profileId, posts, profile, history}) {
  const [loading, unstarted, complete] = useProfileCompletion(profileId, profile, posts);

  const isViewer = getIsViewer(profile);

  if (loading) {
    return <Loading />;
  }

  if (unstarted && !profile.onboardingCompleted) {
    return <Redirect to={`/profile/${profileId}/onboard/intro`} />;
  }

  return (
    <div className="container">
      <aside className="aside">
        <ProfileAside showProgress={!complete && !isViewer} profileId={profileId} />
      </aside>
      <Card feed>{postId ? <SinglePostFeed postId={postId} /> : <ProfileFeed />}</Card>
    </div>
  );
}

// This is a workaround... react hook based function's don't like to be
// directly made reactive. Passing observed props down achieves the same thing.
function Observe(props) {
  const {
    stores: {profilesStore, postsStore},
    match,
    history,
  } = props;
  const {profileId, postId} = match.params;

  const profile = profilesStore.getProfileById(profileId);

  return <ProfileHome profileId={profileId} postId={postId} profile={profile} posts={postsStore.posts} history={history} />;
}

export default inject('stores')(observer(Observe));
