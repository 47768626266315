/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';

import blankProfileSrc from '../../assets/img/blank-profile.png';
import '../../assets/scss/components/avatar.scss';

function Avatar({src, className, imageClassName, forwardedRef}) {
  return (
    <div className={classNames('profile', 'avatar', className)} ref={forwardedRef}>
      {src && <img className={classNames('image', imageClassName)} src={src} alt="User avatar" />}
      {!src && <img className={classNames('image', imageClassName)} src={blankProfileSrc} alt="User avatar" />}
    </div>
  );
}

export default React.forwardRef((props, ref) => <Avatar {...props} forwardedRef={ref} />);
