/* eslint-disable react/prop-types */
import React, {useRef} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import Goal from './index';

function ProfileGoal({goal, goalId, index, profileId, handleListItemsChange, onMoveGoal}) {
  const ref = useRef(null);

  const [{handlerId}, drop] = useDrop({
    accept: 'goal',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      onMoveGoal(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{isDragging}, drag] = useDrag({
    type: 'goal',
    item: () => ({goalId, profileId, index}),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.3 : 1;

  drag(drop(ref));

  return (
    <div className="step step__draggable" ref={ref} style={{opacity}} data-handler-id={handlerId}>
      <Goal
        goal={{...goal}}
        key={goalId}
        goalId={goalId}
        profileId={profileId}
        onListItemsChange={handleListItemsChange}
        ref={ref}
        style={{opacity}}
        data-handler-id={handlerId}
      />
    </div>
  );
}

export default ProfileGoal;
