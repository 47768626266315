/* eslint-disable react/prop-types */
import React from 'react';
import {ModalImage, Button} from '../common';

import endedSrc from '../../assets/svg/technology.svg';

function ModalExpired({onDismiss, isOpen, onSubscribe}) {
  return (
    <ModalImage type="primary" src={endedSrc} isOpen={isOpen} alt="Dancing Aliens" handleDismiss={onDismiss}>
      <h3>Your trial has ended</h3>
      <p>
        Phew... the last few days have flown by. Unfortunately this means your free trial is over. We hope you found sameview useful and want to keep us on your
        team.
      </p>
      <p>Upgrading to a sameview subscirption is really easy. Just hit the button below and enter some details, and away we go.</p>
      <Button right type="primary" size="medium" onClick={onSubscribe}>
        Subscribe
      </Button>
    </ModalImage>
  );
}

export default ModalExpired;
