/* eslint-disable react/prop-types */
import React from 'react';

import '../../assets/scss/components/post/post.scss';

function PostUserInvited({post, profile}) {
  if (!post.memberInvited || !post.members) {
    return null;
  }
  const text = [];

  Object.keys(post.members || {}).forEach(uid => {
    // eslint-disable-next-line fp/no-mutating-methods
    text.push(profile?.team[uid]?.firstname || '');
  });

  if (!text.length) return null;

  return (
    <div className="complete__container complete__container--invite">
      <div className="complete__message">
        <h4 className="complete__name">Hi {text.join(', ')}! 👋</h4>
      </div>
    </div>
  );
}

export default PostUserInvited;
