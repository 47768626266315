/* eslint-disable react/prop-types */
import React from 'react';

import {inject, observer} from 'mobx-react';
import {auth} from '../../firebase';

function LogoutButton({stores: {profilesStore, notificationsStore}}) {
  return (
    <button
      type="button"
      onClick={async () => {
        // Logout from Firebase
        await auth.doLogout();
        profilesStore.clearProfiles();
        notificationsStore.clearNotifications();
      }}>
      <span role="img" aria-label="Bye!">
        👋
      </span>
      Log Out
    </button>
  );
}

export default inject('stores')(observer(LogoutButton));
