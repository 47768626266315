/* eslint-disable react/prop-types */
import React, {useCallback} from 'react';

import {Card, HelpPanel, VimeoEmbed} from '../common';
import PostCreate from '../post/create';
import routes from '../../constants/routes';

function OnboardPost({profile, history}) {
  const onBackToOnboard = useCallback(() => {
    // eslint-disable-next-line react/prop-types
    history.push(routes.generate(routes.profile.onboard.intro, {profileId: profile.id}));
  }, [profile, history]);

  return (
    <div className="container">
      <Card help full>
        <div className="card__content">
          <HelpPanel defaultsOpen>
            <h3>Help you post</h3>
            <p>
              Posting is how you can share information on sameview with your team. You can share the outcomes of therapy appointments (eg. videos of exercises),
              or capture important moments, issues, or achievements.
            </p>
            <p>With each post you can tag the relevant goals, and attach any type of file you like (video, photo, pdf, voice, etc.)</p>
            <VimeoEmbed videoId="344929749" videoName="HOW TO POST" />
          </HelpPanel>
          <div className="card__body">
            <PostCreate onboard onCancel={onBackToOnboard} />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default OnboardPost;
