import {createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updatePassword} from 'firebase/auth';
import {auth} from './firebase';

// Sign Up
const doCreateUserWithEmailAndPassword = (email, password) => createUserWithEmailAndPassword(auth, email.trim(), password);

// Sign In
const doSignInWithEmailAndPassword = (email, password) => signInWithEmailAndPassword(auth, email.trim(), password);

// Sign out
const doLogout = () => signOut(auth);

// Password Reset
const doPasswordReset = email => sendPasswordResetEmail(auth, email.trim());

// Password Change
const doPasswordUpdate = password => updatePassword(auth.currentUser, password);

export {doCreateUserWithEmailAndPassword, doSignInWithEmailAndPassword, doLogout, doPasswordReset, doPasswordUpdate};
export default doLogout;
