import React from 'react';
import PropTypes from 'prop-types';

import Modal from './modal';
import Panel from './panel';

export const modalPanelTypes = ['primary', 'secondary'];

function ModalPanel({type, heading, isOpen, onDismiss, children}) {
  return (
    <Modal type="dual" isOpen={isOpen} handleDismiss={onDismiss} showDismiss={!!onDismiss}>
      <Panel primary={type === 'primary'} secondary={type === 'secondary'}>
        <h2>{heading}</h2>
      </Panel>
      <Panel content>{children}</Panel>
    </Modal>
  );
}

ModalPanel.propTypes = {
  /** The modal panel type */
  type: PropTypes.oneOf(modalPanelTypes),
  /** The panel heading content */
  heading: PropTypes.string.isRequired,
  /** The onDismiss function. If this is passed, a dismiss button will be shown */
  onDismiss: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

ModalPanel.defaultProps = {
  /** Defaults to no dismiss button */
  onDismiss: false,
  /** Defaults to no dismiss button */
  type: 'primary',
};

export default ModalPanel;
