/**
 * Custom Task model
 *
 * Taken from the vanilla dragfromgrid example
 */
// we import scheduler.umd for IE11 compatibility only. If you don't use IE import:
import {EventModel} from '@bryntum/calendar';

export default class Event extends EventModel {
  static get fields() {
    return [
      {name: 'location', type: 'string'},
      {name: 'note', type: 'string'},
    ];
  }
}
