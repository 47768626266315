/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-case-declarations */

import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {observer} from 'mobx-react';
import ProfileField from '../form/ProfileField';

function ProfileEssentials({id, section, questions, canEdit, firstname, printing, profile}) {
  // eslint-disable-next-line fp/no-mutating-methods
  const order = useMemo(() => Object.keys(questions.fields).sort((a, b) => questions.fields[a].index - questions.fields[b].index), [questions]);

  if (profile?.type === 'group' && questions?.name === 'Profile') {
    const orderGroup = ['profileImage', 'firstname', 'description', 'birthdate'];
    return (
      <div className="profile-form-container">
        {orderGroup.map(key => {
          let label = (questions.fields[key].label || '').replace('FIRST_NAME', firstname);
          const placeholder = (questions.fields[key].placeholder || '').replace('FIRST_NAME', firstname);
          // eslint-disable-next-line fp/no-mutation
          label = questions.fields[key].label === 'About me' ? 'About Group' : label;
          // eslint-disable-next-line fp/no-mutation
          label = questions.fields[key].label === 'First Name' ? 'Group Name' : label;
          // eslint-disable-next-line fp/no-mutation
          label = questions.fields[key].label === 'Date of Birth' ? 'Created at' : label;
          return (
            <ProfileField
              {...questions.fields[key]}
              profileId={id}
              answer={section[key]}
              label={label}
              placeholder={placeholder}
              key={key}
              field={key}
              canEdit={key === 'birthdate' ? false : canEdit}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className="profile-form-container">
      {order.map(key => {
        const label = (questions.fields[key].label || '').replace('FIRST_NAME', firstname);
        const placeholder = (questions.fields[key].placeholder || '').replace('FIRST_NAME', firstname);
        if (printing) {
          switch (key) {
            case 'image':
              return null;
            case 'date':
              const value = new Date(section[key].value);
              const formattedValue = `${value.getDate().toString().padStart(2, '0')}/${(value.getMonth() + 1)
                .toString()
                .padStart(2, '0')}/${value.getFullYear()}`;
              return (
                <label className="profile-form__label">
                  {label} <p>{formattedValue}</p>
                </label>
              );
            default:
              return (
                <label className="profile-form__label">
                  {label} <p>{section[key].value || ''}</p>
                </label>
              );
          }
        }
        return (
          <ProfileField
            {...questions.fields[key]}
            profileId={id}
            answer={section[key]}
            label={label}
            placeholder={placeholder}
            key={key}
            field={key}
            canEdit={canEdit}
          />
        );
      })}
    </div>
  );
}

ProfileEssentials.defaultProps = {
  printing: false,
  canEdit: false,
};

ProfileEssentials.propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape({}).isRequired,
  questions: PropTypes.shape({
    fields: PropTypes.arrayOf({}),
    name: PropTypes.string,
  }).isRequired,
  canEdit: PropTypes.bool,
  firstname: PropTypes.string.isRequired,
  printing: PropTypes.bool,
  profile: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
};

export default observer(ProfileEssentials);
