/* eslint-disable react/prop-types */
import React from 'react';
import {Checkbox, useFormState, useFieldState} from 'informed';

import {Button, Field, DateField, FieldCountrySelect} from '../common';
import {validateEmail, validateRequired, validateTerms, validatePassword, validateMobile} from '../common/validate';

import '../../assets/scss/components/signup/signup-form.scss';

function SignUpFormSelf({saving, onCancel, onStep, ...props}) {
  const {invalid} = useFormState();
  const {error: termsError} = useFieldState('terms-conditions') ?? {};

  return (
    <div className="signup-form">
      <div className="signup-form__account" style={{display: 'flex'}}>
        <Field
          prefix="signup-form"
          label="First Name"
          field="firstname"
          placeholder="First Name"
          wrapperClassName="signup-form__name"
          validate={validateRequired}
          validateOnChange
        />

        <Field
          prefix="signup-form"
          label="Last Name"
          field="lastname"
          placeholder="Last Name"
          wrapperClassName="signup-form__name"
          validate={validateRequired}
          validateOnChange
        />

        <DateField
          prefix="signup-form"
          field="birthdate"
          label="Date of Birth"
          placeholder="DD / MM / YYYY"
          dateFormat="dd/MM/yyyy"
          wrapperClassName="signup-form__date"
          validate={validateRequired}
          validateOnChange
        />

        <Field prefix="signup-form" label="Mobile" field="mobile" placeholder="Mobile Number" validate={validateMobile} validateOnChange />

        <FieldCountrySelect
          prefix="signup-form"
          field="country"
          label="Country"
          wrapperClassName="signup-form__country"
          validate={validateRequired}
          validateOnChange
        />

        <Field prefix="signup-form" field="postcode" label="Postcode" wrapperClassName="signup-form__postcode" validate={validateRequired} validateOnChange />

        <Field prefix="signup-form" label="Email" field="email" placeholder="Email address" validate={validateEmail} validateOnChange />

        <Field prefix="signup-form" type="password" label="Password" field="password" placeholder="Password" validate={validatePassword} validateOnChange />

        <div className="signup-form__terms">
          <small className="signup-form__error">{termsError}</small>
          <Checkbox field="terms-conditions" id="terms-conditions" validate={validateTerms} validateOnChange />
          <label className="signup-form__label" htmlFor="terms-conditions">
            <span>
              I agree to the sameview{' '}
              <a href="https://www.sameview.com.au/privacy-policy" onClick={e => props.openPrivacyModal(e)}>
                Privacy Policy
              </a>{' '}
              and{' '}
              <a href="https://www.sameview.com.au/terms-of-service" onClick={e => props.openTermsModal(e)}>
                Terms & Conditions
              </a>
            </span>
          </label>
        </div>

        <Button left type="tertiary" onClick={onCancel}>
          Back
        </Button>
        <Button right submit type="primary" loading={saving} disabled={invalid}>
          Done
        </Button>
      </div>
    </div>
  );
}

export default SignUpFormSelf;
