import {observable, decorate} from 'mobx';
import {UsersStore} from './UsersStore';
import {PostsStore} from './PostsStore';
import {ProfilesStore} from './ProfilesStore';
import {NotificationsStore} from './NotificationsStore';
import {UiStore} from './UiStore';

export class MainStore {
  constructor() {
    this.usersStore = new UsersStore(this);
    this.postsStore = new PostsStore(this);
    this.profilesStore = new ProfilesStore(this);
    this.notificationsStore = new NotificationsStore(this);
    this.uiStore = new UiStore(this);
  }

  usersStore = null;

  postsStore = null;

  profilesStore = null;

  notificationsStore = null;

  uiStore = null;
}

decorate(MainStore, {
  name: observable,
});

export default MainStore;
