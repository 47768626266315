/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {Button, Avatar, Icon} from '../common';
import {usePhoneNumber, useUrl} from '../../hooks';
import '../../assets/scss/components/team/professional-profile.scss';

function TeamProfessionalCard({
  name,
  relationship,
  avatarSrc,
  companyName,
  email,
  url,
  phoneNumber,
  companyAddress,
  nextAppointment = null,
  onAddAppointment,
  forwardedRef,
  showAppointment,
}) {
  const [parsedPhone, parsedPhoneDisplay, parsedPhoneError] = usePhoneNumber(phoneNumber);
  const [parsedUrl, parsedUrlDisplay, parsedUrlError] = useUrl(url);
  const parsedAppointment = dayjs(nextAppointment);

  return (
    <div className="professional-profile" ref={forwardedRef}>
      <Avatar className="professional-profile__profile" src={avatarSrc} />
      <div className="professional-profile__detail">
        <h3 className="professional-profile__name">{name}</h3>
        <h4 className="professional-profile__relationship">{relationship}</h4>
      </div>
      {(companyName || companyAddress) && (
        <div className="professional-profile__row">
          <Icon type="home" />
          <div>
            <h4>{companyName}</h4>
            {companyAddress}
          </div>
        </div>
      )}
      {!parsedPhoneError && (
        <div className="professional-profile__row">
          <Icon type="phone" />
          <a href={`tel:${parsedPhone}`}>{parsedPhoneDisplay}</a>
        </div>
      )}
      {email && (
        <div className="professional-profile__row">
          <Icon type="mail" />
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      )}
      {!parsedUrlError && (
        <div className="professional-profile__row">
          <Icon type="link" />
          <a href={parsedUrl} rel="noopener noreferrer" target="_blank">
            {parsedUrlDisplay}
          </a>
        </div>
      )}
      {parsedAppointment.isValid() && (
        <div className="professional-profile__row">
          <Icon type="clock" />
          <p>{parsedAppointment.format('DD/MM HH:mm')}</p>
        </div>
      )}

      {showAppointment && (
        <Button type="secondary-light" onClick={onAddAppointment}>
          Add appointment
        </Button>
      )}
    </div>
  );
}

TeamProfessionalCard.defaultProps = {
  avatarSrc: '',
  companyName: '',
  email: '',
  url: '',
  phoneNumber: '',
  companyAddress: '',
  nextAppointment: '',
  onAddAppointment: () => {},
  forwardedRef: {},
  showAppointment: false,
};

TeamProfessionalCard.propTypes = {
  name: PropTypes.string.isRequired,
  relationship: PropTypes.string.isRequired,
  avatarSrc: PropTypes.string,
  companyName: PropTypes.string,
  email: PropTypes.string,
  url: PropTypes.string,
  phoneNumber: PropTypes.string,
  companyAddress: PropTypes.string,
  nextAppointment: PropTypes.string,
  onAddAppointment: PropTypes.func,
  forwardedRef: PropTypes.shape({}),
  showAppointment: PropTypes.bool,
};

export default React.forwardRef((props, ref) => <TeamProfessionalCard {...props} forwardedRef={ref} />);
