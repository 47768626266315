/* eslint-disable react/prop-types */
import React from 'react';

import '../../assets/scss/components/progress-bar.scss';

function Progress({percent}) {
  const width = Math.min(Math.max(percent, 0), 100);

  return (
    <div className="progress-bar">
      <div className="progress-bar__progress" style={{width: `${width}%`}} />
    </div>
  );
}

export default Progress;
