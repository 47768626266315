/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {Scope, Checkbox, useFormState, useFormApi, useFieldState, TextArea} from 'informed';
import classnames from 'classnames';

import {Button, Field} from '../common';
import {usePreventDefault} from '../../hooks';
import {validateEmail, validateRequired, validateTerms, validatePassword, validateMobile} from '../common/validate';

import '../../assets/scss/components/signup/signup-form.scss';

function SignUpProfessionalForm({saving, onCancel, onStep, ...props}) {
  const [section, setSection] = useState(0);
  const {validate, getState} = useFormApi();
  const {invalid} = useFormState();
  const {error: termsError} = useFieldState('terms-conditions') ?? {};

  const onProfileBack = usePreventDefault(() => {
    setSection(0);
    onStep(0);
  }, [setSection, onStep]);
  const onAccountNext = usePreventDefault(() => {
    validate();
    if (!getState().invalid) {
      setSection(1);
      onStep(1);
    }
  }, [setSection, onStep, validate, getState]);

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        onAccountNext(event);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [onAccountNext]);

  return (
    <div className="signup-form">
      <div className="signup-form__account" style={{display: section === 0 ? 'flex' : 'none'}}>
        <Field
          prefix="invite-form"
          label="First Name"
          field="firstname"
          placeholder="First Name"
          wrapperClassName="invite-form__name"
          validate={validateRequired}
          validateOnChange
        />

        <Field
          prefix="invite-form"
          label="Last Name"
          field="lastname"
          placeholder="Last Name"
          wrapperClassName="invite-form__name"
          validate={validateRequired}
          validateOnChange
        />

        <Field prefix="invite-form" label="Organisation" field="organisation" placeholder="Organisation Name" validate={validateRequired} validateOnChange />

        <Field prefix="invite-form" label="Job Title" field="job_title" placeholder="Job Title" validate={validateRequired} validateOnChange />

        <Field prefix="invite-form" label="Work Mobile" field="mobile" placeholder="Work Mobile Number" validate={validateMobile} validateOnChange />

        <Field prefix="invite-form" label="Work Email" field="email" placeholder="Work Email Address" validate={validateEmail} validateOnChange />

        <Field prefix="invite-form" type="password" label="Password" field="password" placeholder="Password" validate={validatePassword} validateOnChange />

        <Button left type="tertiary" onClick={onCancel}>
          Back
        </Button>
        <Button right type="primary" onClick={onAccountNext} disabled={invalid}>
          Next
        </Button>
      </div>
      {section > 0 && (
        <div className="signup-form__provider">
          <Scope scope="provider">
            <h4 className="signup-form__heading">About my work...</h4>

            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="description" className={classnames('invite-form__label invite-form__relationship')}>
              I love working with
              <TextArea id="description" field="description" placeholder="Types of families and clients you work with" />
            </label>

            <Field prefix="invite-form" label="My website is" field="website" placeholder="www.yoursite.com" validate={validateRequired} validateOnChange />

            <Field prefix="invite-form" label="Business Address" field="address" placeholder="Business address" validate={validateRequired} validateOnChange />
          </Scope>

          <div className="signup-form__terms">
            {/* eslint-disable-next-line react/no-unknown-property */}
            {termsError && <small termsError="signup-form__error">{termsError}</small>}
            <Checkbox field="terms-conditions" id="terms-conditions" validate={validateTerms} validateOnChange />
            <label className="signup-form__label" htmlFor="terms-conditions">
              <span>
                I agree to the sameview{' '}
                <a href="https://www.sameview.com.au/privacy-policy" onClick={e => props.openPrivacyModal(e)}>
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="https://www.sameview.com.au/terms-of-service" onClick={e => props.openTermsModal(e)}>
                  Terms & Conditions
                </a>
              </span>
            </label>
          </div>

          <Button left type="tertiary" onClick={onProfileBack}>
            Back
          </Button>
          <Button submit right type="primary" loading={saving} disabled={invalid}>
            Next
          </Button>
        </div>
      )}
    </div>
  );
}

export default SignUpProfessionalForm;
