const notificationTypes = {
  SELF_CREATE_POST: 'SELF_CREATE_POST',
  TEAM_CREATE_POST: 'TEAM_CREATE_POST',
  SELF_CREATE_COMMENT: 'SELF_CREATE_COMMENT',
  TEAM_CREATE_COMMENT: 'TEAM_CREATE_COMMENT',

  ONBOARDING_INVITE_TEAM_MEMBER: 'ONBOARDING_INVITE_TEAM_MEMBER',
  ONBOARDING_ADD_FIRST_GOAL: 'ONBOARDING_ADD_FIRST_GOAL',
  ONBOARDING_UPDATE_PROFILE: 'ONBOARDING_UPDATE_PROFILE',
  ONBOARDING_UPDATE_USER_DETAILS: 'ONBOARDING_UPDATE_USER_DETAILS', // not used yet

  STATUS_ADDED_GOAL: 'STATUS_ADDED_GOAL',
  STATUS_ADDED_TEAM_MEMBER: 'STATUS_ADDED_TEAM_MEMBER',
  STATUS_ADDED_TO_TEAM: 'STATUS_ADDED_TO_TEAM',
};

export default notificationTypes;
