/* eslint-disable react/jsx-props-no-spreading */
import React, {useState} from 'react';
import {useField} from 'informed';
import classNames from 'classnames';
import Avatar from './avatar';
import {getClassNames} from './field';
import ImageUpload from '../file-upload/image-upload';
import {useFallback} from '../../hooks';

const FieldImageUpload = props => {
  const {fieldState, fieldApi, render, userProps} = useField(props);

  const [focused, setFocus] = useState(false);

  const {value, touched, error} = fieldState;
  const {setValue, setTouched, setError} = fieldApi;
  const {onChange, onBlur, onFocus, onError, onRemove, prefix, label, wrapperClassName, readOnly, defaultImage, ...rest} = userProps;

  const memoizedFocus = useFallback(() => setFocus(true), onFocus, [setFocus]);
  const memoizedBlur = useFallback(() => setTouched(true), onBlur, [setTouched]);

  const memoizedRemove = useFallback(
    () => {
      setValue(null);
    },
    onRemove,
    [setValue]
  );

  const memoizedChange = useFallback(
    (file, url) => {
      setValue({name: file.name, url});
    },
    onChange,
    [setValue]
  );

  const memoizedError = useFallback(
    e => {
      setValue(null);
      setError();
    },
    onError,
    [setValue]
  );

  const isError = touched && !!error;

  const {labelClassName, invalidClassName, errorClassName, focusClassName} = getClassNames(prefix);

  const imageClassName = `${prefix}__image`;
  const imageWrapperClassName = `${prefix}__image-wrapper`;
  const profileImage = typeof value === 'object' && value ? value.url : null;

  return render(
    <div
      className={classNames(labelClassName, wrapperClassName, {
        [invalidClassName]: isError,
        [focusClassName]: focused,
      })}>
      {label} {isError && <small className={errorClassName}>{error}</small>}
      {value || readOnly ? (
        <div className={imageWrapperClassName}>
          <Avatar className={imageClassName} src={profileImage} />
          {!readOnly && (
            <button type="button" className="button -tertiary -tiny" size="tiny" onClick={memoizedRemove}>
              Remove Image
            </button>
          )}
        </div>
      ) : (
        <ImageUpload
          inputId={props.inputId ? props.inputId : `field_image_image`}
          inputName={props.inputName ? props.inputName : `field_image_image`}
          onFocus={memoizedFocus}
          onBlur={memoizedBlur}
          onComplete={memoizedChange}
          onError={memoizedError}
          {...rest}
        />
      )}
      {props.helpertext && <small>{props.helpertext}</small>}
    </div>
  );
};

export default FieldImageUpload;
