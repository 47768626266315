/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import TextareaAutosize from 'react-autosize-textarea';
import routes from '../../constants/routes';

import {Card, Button} from '../common';
import Illustration from '../utilities/illustration';
import withAuthorization from '../session/withAuthorization';
import {db} from '../../firebase';

function AccountOnboardAbout({authUser, history}) {
  const [description, setDescription] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  useEffect(() => {
    setDescription(authUser.description || '');
    setOrganisation(authUser.organisation || '');
    setJobTitle(authUser.jobTitle || '');
  }, [authUser, setDescription, setOrganisation, setJobTitle]);

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    // Remove the invite flag on the user now that they've completed onboarding
    await db.saveUser(authUser.uid, {
      description,
      organisation,
      job_title: jobTitle,
      invited: false,
      onboarding: false,
    });
    history.push(routes.home);
  };

  return (
    <div className="page -account">
      <div className="container">
        <Card focus>
          <section className="section">
            <Illustration type="flag" size="large" />

            <h3>{`Let people you're connected to on Sameview know a little about yourself`}</h3>
            <form onSubmit={onSubmit}>
              <TextareaAutosize value={description} onChange={event => setDescription(event.target.value)} placeholder="About" />

              {authUser.type === 'professional' && (
                <>
                  <label htmlFor="organisation">
                    Organisation
                    <input id="organisation" value={organisation} onChange={event => setOrganisation(event.target.value)} type="text" />
                  </label>

                  <label htmlFor="jobTitle">
                    Job Title
                    <input id="jobTitle" value={jobTitle} onChange={event => setJobTitle(event.target.value)} type="text" />
                  </label>
                </>
              )}

              <Button submit type="primary" loading={loading}>
                Next
              </Button>

              {error && <p>{error.message}</p>}
            </form>
          </section>
        </Card>
      </div>
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(inject('stores')(observer(AccountOnboardAbout)));
