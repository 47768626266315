/* eslint-disable fp/no-mutating-methods */
/* eslint-disable fp/no-mutation */
/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import {observer} from 'mobx-react';

function PostActions({data, authUser, onLikeClick, toggleCommentEnabled, profile, commentLabel = 'Comment'}) {
  const whoLiked = useMemo(() => {
    let array = [];
    const ids = Object.keys(data?.likes || {});
    ids.forEach(id => {
      if (id === authUser.uid) {
        array = ['You', ...array];
      } else {
        const {firstname = '', lastname = ''} = profile?.team[id] || {};
        const name = `${firstname}${firstname.length && lastname.length ? ' ' : ''}${lastname}`;
        array.push(name);
      }
    });
    let more = '';
    if (array.length > 6) {
      more = ` and ${array.length - 6} more`;
      array.splice(6);
    }
    const str = array.join(', ');
    return `${str}${more}`;
  }, [data?.likes, authUser?.uid, profile?.team]);

  const howManyLiked = useMemo(() => Object.keys(data?.likes || {}).length, [data?.likes]);

  return (
    <div className="action">
      {profile.permission && (
        <button
          type="button"
          data-tooltip-liked={whoLiked || undefined}
          className={`button -medium ${data?.likes?.[authUser.uid] ? '-outline' : '-tertiary'}`}
          onClick={onLikeClick}>
          <i className="icon-thumbs-up" /> {howManyLiked || ''}
        </button>
      )}
      {profile.permission && (
        <button type="button" className="button -tertiary -medium" onClick={toggleCommentEnabled}>
          <i className="icon-comment" /> {commentLabel}
        </button>
      )}
    </div>
  );
}

export default observer(PostActions);
