import React, {useMemo} from 'react';
import {useField} from 'formik';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import getClassNames from './styleFields';

function FieldToggle({name, options, prefix, label, wrapperClassName, readOnly, required}) {
  const [{value, onChange, onBlur, onFocus}, {touched, error}, {setValue}] = useField(name);

  const isError = useMemo(() => touched && !!error, [touched, error]);

  const {labelClassName, invalidClassName, errorClassName, focusClassName, selectedClassName, buttonClassName, toggleClassName} = getClassNames(prefix);

  const optionElements = useMemo(
    () =>
      options.map(option => (
        <a
          key={option.value}
          href={`#${option.value}`}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={e => {
            e.preventDefault();
            setValue(option.value);
            if (onChange) {
              onChange(option.value);
            }
          }}
          className={classnames(buttonClassName, {
            [selectedClassName]: value === option.value,
          })}>
          {option.label}
        </a>
      )),
    [options, onFocus, onBlur, buttonClassName, selectedClassName, value, setValue, onChange]
  );

  return (
    <label
      htmlFor="toggle"
      className={classnames(labelClassName, wrapperClassName, {
        [invalidClassName]: isError,
        [focusClassName]: touched,
      })}>
      {!readOnly && required && <span className={`required ${labelClassName}_required`}> * </span>}
      {label}
      {isError && <small className={errorClassName}> {error}</small>}
      <div className={toggleClassName}>{optionElements}</div>
    </label>
  );
}

FieldToggle.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  prefix: PropTypes.string,
  label: PropTypes.string,
  wrapperClassName: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};

FieldToggle.defaultProps = {
  prefix: '',
  label: '',
  wrapperClassName: '',
  readOnly: false,
  required: false,
};

export default FieldToggle;
