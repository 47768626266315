/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react';
import {SectionsSpy} from 'react-smart-sections';
import classNames from 'classnames';
import withBreakpoints from '../utilities/withBreakpoints';

function AccountScrollTabs({breakpoint, availableProfileSections}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);
  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    setIsDesktop(breakpoint === 'desktop');
  }, [breakpoint, setIsDesktop]);

  const profilesClassNames = classNames({
    'profiles-select': true,
    '-open': isOpen,
  });

  if (isDesktop) {
    return (
      <SectionsSpy
        render={sections => (
          <div className="tabs">
            {sections.map(section => (
              <button type="button" key={section.name} onClick={section.scroll} className={`tab ${section.active ? '-active' : ''}`}>
                {availableProfileSections[section.name].name}
              </button>
            ))}
          </div>
        )}
      />
    );
  }

  return (
    <SectionsSpy
      render={sections => (
        <div className={`${profilesClassNames} tabs`}>
          {sections.map(section => (
            <div key={section.name} className={`profiles-single -toggle tab${section.active ? ' show' : ''}`} onClick={toggleOpen}>
              {section.name} <i className="icon-menu" />
            </div>
          ))}
          {isOpen && <div className="menu-underlay" onClick={handleClose} />}
          <ul className="list">
            {sections.map(section => (
              <li key={section.name} className="profiles-single -settings" onClick={handleClose}>
                <button type="button" onClick={section.scroll} className={`tab ${section.active ? '-active' : ''}`}>
                  {section.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    />
  );
}

export default withBreakpoints()(AccountScrollTabs);
