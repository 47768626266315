/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useState} from 'react';
import {observer, inject} from 'mobx-react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {Card, Icon} from '../common';
import User from '../user';
import ProfileAside from './profile.aside';
import getIsOwner from '../../helpers/getIsOwner';
import {getFullName} from '../../helpers/getFullName';

import ModalInviteTeamMember from '../invite/modal.invite';

function ProfileTeam({invite, profile: selectedProfile, stores: {profilesStore, uiStore}, match}) {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(invite);
  const [isOwner, setIsOwner] = useState(false);
  const [profile, setProfile] = useState([]);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const newProfile = profilesStore.getProfileById(uiStore.currentProfileId);

    if (!isEqual(newProfile, profile)) {
      setProfile(newProfile);
      setIsOwner(getIsOwner(newProfile));

      // eslint-disable-next-line fp/no-mutating-methods
      const newMembers = Object.values(newProfile.team || {})
        .filter(member => getFullName(member).toUpperCase() !== 'SAMEVIEW SUPPORT')
        .sort((a, b) => {
          const aFullname = getFullName(a).toUpperCase();
          const bFullname = getFullName(b).toUpperCase();
          return aFullname.localeCompare(bFullname);
        });

      const getPermission = member => newProfile?.permissions?.[member.id].permission || 'Other';

      const owners = newMembers.filter(member => getPermission(member) === 'owner');
      const contributors = newMembers.filter(member => getPermission(member) === 'admin');
      const viewers = newMembers.filter(member => getPermission(member) === 'viewer');
      const others = newMembers.filter(member => !['owner', 'admin', 'viewer'].includes(getPermission(member)));
      const supportMember = Object.values(newProfile.team || {}).filter(member => getFullName(member).toUpperCase() === 'SAMEVIEW SUPPORT');

      setMembers([...owners, ...contributors, ...viewers, ...others, ...supportMember]);
    }
  }, [profile, profilesStore, uiStore]);

  const openTeamInviteModal = () => {
    setIsInviteModalOpen(true);
  };

  const closeTeamInviteModal = () => {
    setIsInviteModalOpen(false);
  };

  if (isEmpty(profile)) return null;

  return (
    <div className="container">
      <aside className="aside">
        <ProfileAside profileId={profile.id} />
      </aside>
      <Card feed>
        <div className="section-team -with-page-action">
          <div className="team-users">
            {members.map(member => (
              <User user={member} profile={profile} key={member.id} isProfessional={false} open={member.id === match.params.userId} />
            ))}
          </div>
          <ModalInviteTeamMember profile={profile} isOpen={isInviteModalOpen} onDismiss={closeTeamInviteModal} />
          {isOwner && (
            <button type="button" className="page-action" onClick={openTeamInviteModal}>
              <span className="button -toggle -small -inverse">
                <Icon type="plus" />
              </span>
              Add Team Member
            </button>
          )}
        </div>
      </Card>
    </div>
  );
}

export default inject('stores')(observer(ProfileTeam));
