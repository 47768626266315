const intercomConfig = {
  app_id: process.env.NODE_ENV === 'production' ? 'nojize5s' : 'uwn4imog',
};

export const intercomEvents = {
  createdProfile: 'createdProfile',
  createdPost: 'createdPost',
  invitedTeamMember: 'invitedTeamMember',
  acceptedInvitation: 'acceptedInvitation',
  invitedClient: 'invitedClient',
};

export default intercomConfig;
