import React from 'react';
import PropTypes from 'prop-types';
import {CardElement, injectStripe} from 'react-stripe-elements';

const CardElementOptions = (fontSize, padding) => ({
  style: {
    base: {
      fontSize,
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontWeight: 400,
      '::placeholder': {
        color: '#989898',
      },
      ...(padding ? {padding} : {}),
    },
    invalid: {
      color: '#9e2146',
    },
  },
});

function CardFormElement({fontSize, stripe, onCreditCardChange}) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <CardElement {...CardElementOptions(fontSize)} onReady={element => element.focus()} onBlur={event => onCreditCardChange(event, stripe)} />;
}
const CardForm = injectStripe(CardFormElement);

CardFormElement.propTypes = {
  fontSize: PropTypes.string,
  stripe: PropTypes.shape({}),
  onCreditCardChange: PropTypes.func,
};

CardFormElement.defaultProps = {
  fontSize: '16px',
  stripe: {},
  onCreditCardChange: () => {},
};

export default CardForm;
