import React from 'react';
import {Link} from 'react-router-dom';
import routes from '../../constants/routes';

import {Card, Heading} from '../common';
import Illustration from '../utilities/illustration';

function AccountOnboardIntro() {
  return (
    <div className="page -account">
      <div className="container">
        <Card focus>
          <Heading secondary>
            <h1>Your account has been created!</h1>
          </Heading>
          <section className="section">
            <Illustration type="party hat" size="large" />

            <h3>Welcome to Sameview!</h3>
            <p>Next, we’ll confirm a few details and you can start using Sameview.</p>
            <Link to={routes.user.inviteOnboard.name} className="button -primary">
              Next
            </Link>
          </section>
        </Card>
      </div>
    </div>
  );
}

export default AccountOnboardIntro;
