/* eslint-disable react/prop-types */
import React from 'react';
import {ModalImage, Button} from '../common';

import holdSrc from '../../assets/svg/space.svg';

function ModalOwnerHold({isOpen, onDismiss, onSubscribe}) {
  return (
    <ModalImage type="primary" isOpen={isOpen} src={holdSrc} alt="Dancing Aliens" handleDismiss={onDismiss}>
      <h3>Your plan is on hold</h3>
      <p>We hope you found sameview useful and want to keep us on your team.</p>
      <p>To renew your sameview subscription simply click the button below and you’ll be back in no time at all.</p>
      <p>
        If you think something has gone wrong please contact us here <a href="mailto:support@sameview.com.au">support@sameview.com.au</a>
      </p>
      <Button right type="primary" size="medium" onClick={onSubscribe}>
        Subscribe
      </Button>
    </ModalImage>
  );
}

export default ModalOwnerHold;
