import notificationTypes from '../constants/notificationTypes';

export const getNotificationText = (type = '', profileName = '', authorName = '') => {
  switch (type) {
    case notificationTypes.SELF_CREATE_POST:
      return `You created a new post${profileName ? ` about ${profileName}` : ''}`;
    case notificationTypes.TEAM_CREATE_POST:
      return `${authorName || 'Somebody '} posted${profileName ? ` about ${profileName}` : ''}`;
    case notificationTypes.SELF_CREATE_COMMENT:
      return `You commented on a post${profileName ? ` about ${profileName}` : ''}`;
    case notificationTypes.TEAM_CREATE_COMMENT:
      return `${authorName || 'Somebody '} commented on a post you're following${profileName ? ` about ${profileName}` : ''}`;

    case notificationTypes.ONBOARDING_INVITE_TEAM_MEMBER:
      return `Invite team members to profile`;
    case notificationTypes.ONBOARDING_ADD_FIRST_GOAL:
      return `Add your first goal to profile`;
    case notificationTypes.ONBOARDING_UPDATE_PROFILE:
      return `Update the information about profile`;
    case notificationTypes.ONBOARDING_UPDATE_USER_DETAILS:
      return `Update your personal information`;

    case notificationTypes.STATUS_ADDED_GOAL:
      return `You added a new goal${profileName ? ` for ${profileName}` : ''}`;
    case notificationTypes.STATUS_ADDED_TEAM_MEMBER:
      return `You added ${authorName || 'a member'} to ${profileName || 'a profile'}'s team`;
    case notificationTypes.STATUS_ADDED_TO_TEAM:
      return `You were added to ${profileName || 'a profile'}'s team${authorName ? ` by ${authorName}` : ''}`;

    default:
      return ``;
  }
};

export default getNotificationText;
