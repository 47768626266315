/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {Avatar} from '../common';
import routes from '../../constants/routes';
import getIsDeleted from '../../helpers/getIsDeleted';

function ProfilesSingle({profile, onClick}) {
  if (!profile) return null;

  // If a profile has no goals (hasn't been through onboarding), no not display it here.
  if (!profile.goals) return null;

  // Don't return profiles with an empty id..
  // TODO: Establish why this is happening
  if (!profile.id || getIsDeleted(profile)) return null;

  const {profileImage, firstname, lastname} = profile.profileSections?.essentials || {};

  return (
    <li key={profile.id} className="profiles-single" onClick={onClick}>
      <Link to={routes.generate(routes.profile.home, {profileId: profile.id})}>
        <Avatar src={profileImage?.url} />
        <div className="name">
          {firstname} {lastname}
        </div>
      </Link>
    </li>
  );
}

ProfilesSingle.propTypes = {
  onClick: PropTypes.func,
  profile: PropTypes.shape({
    goals: PropTypes.shape({}),
    id: PropTypes.string,
    profileSections: PropTypes.shape({
      essentials: PropTypes.shape({
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        profileImage: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
};

ProfilesSingle.defaultProps = {
  onClick: () => {},
  profile: null,
};

export default observer(ProfilesSingle);
