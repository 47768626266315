/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';

function ToggleButton({checked, onClick, disabled}) {
  return (
    <div onClick={!disabled ? onClick : undefined} className={`wrg-toggle ${checked ? 'wrg-toggle--checked' : ''}`}>
      <div className="wrg-toggle-container">
        <div className="wrg-toggle-check">
          <span />
        </div>
        <div className="wrg-toggle-uncheck">
          <span />
        </div>
      </div>
      <div className="wrg-toggle-circle" />
      <input className="wrg-toggle-input" type="checkbox" aria-label="Toggle Button" />
    </div>
  );
}

export default ToggleButton;
