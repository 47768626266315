import {useState, useEffect} from 'react';

const useUrl = url => {
  const [parsed, setParsed] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    try {
      const parsedUrl = new URL(url);
      setParsed({
        url: parsedUrl.toString(),
        display: parsedUrl.hostname,
      });
    } catch (e) {
      console.log(e);
      setError(e.message);
      setParsed({});
    }
  }, [url]);

  return [parsed.url, parsed.display, error];
};

export default useUrl;
