/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import isEmpty from 'lodash/isEmpty';

function SubscriptionPlans({onPlanClick, selectedProfile, products, selectedPlan}) {
  useEffect(() => {
    if (!isEmpty(selectedPlan)) return;

    const selectedPlanID = selectedProfile && selectedProfile.subscription && selectedProfile.subscription.plan ? selectedProfile.subscription.plan.id : '';

    Object.values(products).forEach(product => {
      Object.values(product.plans).forEach(plan => {
        if ((selectedPlanID && selectedPlanID === plan.id) || (!selectedPlanID && plan.active && plan.interval === 'month')) {
          onPlanClick(plan);
        }
      });
    });
  }, [onPlanClick, products, selectedPlan, selectedProfile]);

  const onClick = async (event, plan) => {
    event.preventDefault();
    onPlanClick(plan);
  };

  return (
    <section className="section">
      <ul className="list">
        {products &&
          Object.values(products).map(product => (
            <React.Fragment key={product.id}>
              {Object.values(product.plans).map(
                plan =>
                  plan.nickname &&
                  plan.active === true && (
                    <li key={plan.id} className={`list__item${plan.id === selectedPlan.id ? ' active' : ''}`} onClick={event => onClick(event, plan)}>
                      <div className="list__item-description">{plan.nickname}</div>
                      <div className="list__item-total">${plan.amount.toFixed(2)}</div>
                    </li>
                  )
              )}
            </React.Fragment>
          ))}
      </ul>
    </section>
  );
}

export default SubscriptionPlans;
