/* eslint-disable fp/no-mutating-methods */
import {useCallback} from 'react';

const useFallback = (callback, fallback, memo = []) => {
  memo.push(fallback);

  return useCallback((...args) => {
    callback(...args);
    if (fallback) fallback(...args);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, memo);
};

export default useFallback;
