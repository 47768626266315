import React from 'react';
import {Box, CircularProgress, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';

const containerStyle = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

function LoadingWithLabel({progress}) {
  return (
    <Box display="inline-flex" position="relative">
      <CircularProgress variant="determinate" value={progress} color="secondary" />
      <Box sx={containerStyle}>
        <Typography variant="caption" component="div">
          {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LoadingWithLabel.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default LoadingWithLabel;
