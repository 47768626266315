/* eslint-disable react/prop-types */
import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import posed, {PoseGroup} from 'react-pose';

import Icon from './icon';

import '../../assets/scss/components/help-panel.scss';

const PosedContent = posed.div({
  enter: {opacity: 1, height: 'auto', flip: true},
  exit: {opacity: 0, height: 0},
});

function HelpPanel({defaultsOpen = false, open, children, onOpen, onClose}) {
  const [isOpen, setIsOpen] = useState(open || defaultsOpen);
  const [isControlled] = useState(typeof open !== 'undefined');
  const [isContentOpen, setIsContentOpen] = useState(open || defaultsOpen);

  const onToggleOpen = useCallback(
    e => {
      e.preventDefault();

      if (!isControlled) {
        setIsOpen(!isOpen);

        if (isOpen) {
          setIsContentOpen(false);
        } else {
          setTimeout(() => setIsContentOpen(!isOpen), 300);
        }
      } else if (open) {
        if (onClose) onClose();
      } else if (onOpen) onOpen();
    },
    [open, isOpen, setIsOpen, isControlled, onOpen, onClose, setIsContentOpen]
  );

  useEffect(() => {
    if (isControlled && open !== isOpen) {
      if (open) {
        console.log('OPEN!');
        setIsOpen(true);
        setTimeout(() => setIsContentOpen(true), 300);
      } else {
        console.log('CLOSE!');
        setIsOpen(false);
        setIsContentOpen(false);
      }
    }
  }, [open, isOpen, setIsOpen, isControlled, setIsContentOpen]);

  if (!isControlled && typeof open !== 'undefined') {
    throw new Error(
      'Warning: A component is changing an uncontrolled HelpPanel to be controlled. HelpPanels should not switch from uncontrolled to controlled (or vice versa). Decide between using a controlled or uncontrolled HelpPanel for the lifetime of the component. More Info: https://fb.me/react-controlled-components'
    );
  }

  return (
    <div className={classnames('help-panel', {'help-panel--open': isOpen})}>
      <button type="button" className="help-panel__button" onClick={onToggleOpen}>
        <Icon type={isOpen ? 'cancel' : 'help-circled'} />
      </button>
      <PoseGroup>
        {isContentOpen && (
          <PosedContent className="help-panel__content" key="content">
            {children}
          </PosedContent>
        )}
      </PoseGroup>
    </div>
  );
}

HelpPanel.propTypes = {
  defaultsOpen: PropTypes.bool,
};

HelpPanel.defaultProps = {
  defaultsOpen: false,
};

export default HelpPanel;
