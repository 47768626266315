/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import ProfileContext from './ProfileContext';

const withProfile = Component => {
  class withProfile extends React.Component {
    render() {
      return <ProfileContext.Consumer>{profile => (profile ? <Component {...this.props} profile={profile} /> : null)}</ProfileContext.Consumer>;
    }
  }

  return withProfile;
};

export default withProfile;
