import React from 'react';

const usernamePlaceholder = () => (
  <span>
    <div
      style={{
        display: 'inline-block',
        marginBottom: 4,
        width: '4rem',
        borderRadius: 4,
        backgroundColor: '#cbc9c9',
        marginRight: '1rem',
      }}>
      &nbsp;
    </div>
    <div
      style={{
        display: 'inline-block',
        marginBottom: 4,
        width: '6rem',
        borderRadius: 4,
        backgroundColor: '#cbc9c9',
      }}>
      &nbsp;
    </div>
  </span>
);

export default usernamePlaceholder;
