/* eslint-disable fp/no-mutation */
/* eslint-disable react/prop-types */
import React, {useState, useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {withRouter} from 'react-router';
import dayjs from 'dayjs';
import {auth} from '../../firebase/firebase';

import {Icon} from '../common';
import withBreakpoints from '../utilities/withBreakpoints';
import routes from '../../constants/routes';

import '../../assets/scss/components/_subscription-status.scss';

// const statuses = ['incomplete', 'incomplete_expired', 'trialing', 'active', 'past_due', 'canceled', 'unpaid']

/**
 * Stripe subscription object docs:
 * https://stripe.com/docs/api/subscriptions/object
 *
 * For billing=charge_automatically a subscription moves into incomplete if the initial payment attempt fails.
 * A subscription in this state can only have metadata and default_source updated. Once the first invoice is paid,
 * the subscription moves into an active state. If the first invoice is not paid within 23 hours, the subscription
 * transitions to incomplete_expired. This is a terminal state, the open invoice will be voided and no further invoices
 * will be generated.
 *
 * A subscription that is currently in a trial period is trialing and moves to active when the trial period is over.
 *
 * If subscription billing=charge_automatically it becomes past_due when payment to renew it fails and canceled or
 * unpaid (depending on your subscriptions settings) when Stripe has exhausted all payment retry attempts.
 *
 * If subscription billing=send_invoice it becomes past_due when its invoice is not paid by the due date, and canceled
 * or unpaid if it is still not paid by an additional deadline after that. Note that when a subscription has a status of
 * unpaid, no subsequent invoices will be attempted (invoices will be created, but then immediately automatically closed).
 * After receiving updated payment information from a customer, you may choose to reopen and pay their closed invoices.
 */

function SubscriptionStatus({breakpoint, stores}) {
  const {profilesStore, uiStore} = stores;
  const [loaded, setLoaded] = useState(false);
  const [status, setStatus] = useState(null);
  const [trialEnd, setTrialEnd] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const [profileId, setProfileId] = useState(uiStore.currentProfileId);

  if (profileId !== uiStore.currentProfileId) {
    setProfileId(uiStore.currentProfileId);
  }

  useEffect(() => {
    if (!profileId) {
      setLoaded(true);
      setStatus('none');
      setTrialEnd(null);
    }

    const profile = profilesStore.getProfileById(profileId);

    // Confirm the user still has access to this profile.
    if (typeof profile === 'undefined' || !profile.subscription) return () => null;
    const {subscription} = profile;

    setLoaded(true);
    setStatus(subscription.status || 'none');
    setTrialEnd(subscription.trial_end);

    // Update the trail countdown once an hour.
    const updateInterval = setInterval(() => setLastUpdated(Date.now()), 3600000);

    return () => clearInterval(updateInterval);
  }, [profileId, profilesStore, lastUpdated]);

  const [untilTrialEnds, untilTrialEndsLabel] = useMemo(
    () => {
      if (!trialEnd) return [null, null];

      const trialEndsDate = dayjs(Number(`${trialEnd}000`));

      if (!trialEndsDate.isValid()) return [null, null];

      const daysUntilTrialEnd = Math.max(Math.ceil(trialEndsDate.diff(dayjs(), 'days')), 0);
      const hoursUntilTrialEnd = Math.max(Math.ceil(trialEndsDate.diff(dayjs(), 'hours')), 0);

      let newUntilTrialEnds;
      let newUntilTrialEndsLabel;

      if (hoursUntilTrialEnd < 1) {
        const minutesUntilTrialEnd = Math.max(Math.ceil(trialEndsDate.diff(dayjs(), 'minutes')), 0);

        newUntilTrialEnds = minutesUntilTrialEnd;
        newUntilTrialEndsLabel = minutesUntilTrialEnd !== 1 ? 'minutes' : 'minute';
      } else if (daysUntilTrialEnd < 1) {
        newUntilTrialEnds = hoursUntilTrialEnd;
        newUntilTrialEndsLabel = hoursUntilTrialEnd !== 1 ? 'hours' : 'hour';
      } else {
        newUntilTrialEnds = daysUntilTrialEnd;
        newUntilTrialEndsLabel = daysUntilTrialEnd !== 1 ? 'days' : 'day';
      }

      return [newUntilTrialEnds, newUntilTrialEndsLabel];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trialEnd, lastUpdated]
  );

  if (!loaded) return null;

  const isMobile = breakpoint === 'mobile';
  const subscriptionRoute = routes.generate(routes.user.subscription, {
    profileId: uiStore.currentProfileId,
  });

  switch (status) {
    case 'past_due':
      return (
        <div className="subscription-status subscription-status--hold">
          <Link to={subscriptionRoute} className="subscription-status__badge subscription-status__badge--icon">
            <Icon type="attention" /> Plan on Hold
          </Link>
        </div>
      );

    case 'canceled':
    case 'unpaid':
      return (
        <div className="subscription-status subscription-status--cancelled">
          <Link to={subscriptionRoute} className="subscription-status__badge subscription-status__badge--icon">
            <Icon type="lock" /> Subscription Expired
          </Link>
        </div>
      );

    case 'incomplete':
    case 'incomplete_expired':
      return (
        <div className="subscription-status subscription-status--incomplete">
          <Link to={subscriptionRoute} className="subscription-status__badge subscription-status__badge--icon">
            <Icon type="attention" /> Trial Expired
          </Link>
        </div>
      );

    case 'trialing':
      return (
        <div className="subscription-status subscription-status--trialing">
          <Link to={subscriptionRoute} className="subscription-status__badge">
            {isMobile ? (
              `${untilTrialEnds} ${untilTrialEndsLabel}`
            ) : (
              <>
                Trial Account: <strong>{untilTrialEnds}</strong> {untilTrialEndsLabel} left
              </>
            )}
          </Link>
        </div>
      );

    default:
      // eslint-disable-next-line no-case-declarations
      const find = profilesStore?.profiles?.find(p => p?.subscription?.user_id && p.subscription.user_id === auth?.currentUser?.uid);
      if (!find || find.subscription.cancel_at_period_end || !find?.subscription?.current_period_end) {
        return null;
      }
      if (!['active', 'trialing'].includes(find.subscription.status)) {
        return null;
      }
      return (
        <div className="subscription-status">
          <Link to="/account/subscription" className="subscription-status__badge subscription-status__badge-mini">
            <strong>{dayjs.unix(find.subscription.current_period_end).format('DD/MM/YY')}</strong>
          </Link>
          <Link to="/account/subscription" className="subscription-status__badge subscription-status__badge-small">
            Renew: <strong>{dayjs.unix(find.subscription.current_period_end).format('DD/MM/YY')}</strong>
          </Link>
          <Link to="/account/subscription" className="subscription-status__badge subscription-status__badge-large">
            Account renewal: <strong>{dayjs.unix(find.subscription.current_period_end).format('DD/MM/YYYY')}</strong>
          </Link>
        </div>
      );
  }
}

export default inject('stores')(withBreakpoints()(withRouter(observer(SubscriptionStatus))));
