/* eslint-disable react/prop-types */
import React, {useState, useEffect, useCallback} from 'react';
import {inject, observer} from 'mobx-react';
import {Redirect} from 'react-router-dom';

import isEqual from 'lodash/isEqual';

import routes from '../../constants/routes';
import Post from '../post';
import Feed from './index';

function HomeFeed({title, stores: {postsStore, profilesStore}}) {
  const [posts, setPosts] = useState([]);
  const [dateSortedFeedData, setDateSortedFeedData] = useState([]);
  const [limit] = useState(5);
  const [offset, setOffset] = useState(0);

  const filterPosts = useCallback(
    allPosts => {
      const newPosts = [];
      allPosts.forEach(profilePosts => {
        // eslint-disable-next-line fp/no-mutating-methods
        newPosts.push(...profilePosts);
      });

      if (!isEqual(posts, newPosts)) setPosts(newPosts);
    },
    [posts]
  );

  useEffect(() => {
    postsStore.getPostListForAllProfiles(limit);
  }, [postsStore, limit]);

  useEffect(() => {
    // eslint-disable-next-line fp/no-mutating-methods
    const sortedFeedData = posts
      .slice()
      .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
      .slice(0, limit + offset)
      .map(post => {
        const newProfile = profilesStore.getProfileById(post.profileId);
        if (!newProfile) {
          return null;
        }
        return <Post data={post} profile={newProfile} key={post.id} />;
      });

    setDateSortedFeedData(sortedFeedData);
  }, [limit, offset, posts, profilesStore, setDateSortedFeedData]);

  const handleClickMorePosts = () => {
    postsStore.loadMorePostsForAllProfiles(limit);
    setOffset(offset + limit);
  };

  filterPosts(postsStore.posts);

  // If this is the users first/only profile, redirect straight there.
  if (Array.isArray(profilesStore.profiles) && profilesStore.profiles.length === 1) {
    return (
      <Redirect
        to={routes.generate(routes.profile.home, {
          profileId: profilesStore.profiles[0].id,
        })}
      />
    );
  }

  const showLoadMoreButton = true; // posts.length > limit + offset;

  return <Feed title={title} posts={dateSortedFeedData} handleClickMorePosts={handleClickMorePosts} showLoadMoreButton={showLoadMoreButton} />;
}

export default inject('stores')(observer(HomeFeed));
