/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-mutating-methods */
import React, {useState, useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {MdNotifications} from 'react-icons/md';
import {inject, observer} from 'mobx-react';
import Notifications from './notifications';
import notificationTypes from './constants/notificationTypes';
import routes from '../../constants/routes';
import withAuthorization from '../session/withAuthorization';
import {db} from '../../firebase';

const generateNotificationLink = notification => {
  switch (notification.type) {
    case notificationTypes.SELF_CREATE_POST:
    case notificationTypes.TEAM_CREATE_POST:
    case notificationTypes.SELF_CREATE_COMMENT:
    case notificationTypes.TEAM_CREATE_COMMENT:
      return routes.generate(routes.profile.post.view, {
        profileId: notification.profileId,
        postId: notification.meta.postId || notification.meta.id,
      });
    case notificationTypes.ONBOARDING_INVITE_TEAM_MEMBER:
      return routes.generate(routes.profile.onboard.team, {
        profileId: notification.profileId,
      });
    case notificationTypes.ONBOARDING_ADD_FIRST_GOAL:
      return routes.generate(routes.profile.onboard.goals, {
        profileId: notification.profileId,
      });
    case notificationTypes.ONBOARDING_UPDATE_PROFILE:
      return routes.generate(routes.profile.onboard.detail, {
        profileId: notification.profileId,
      });
    case notificationTypes.STATUS_ADDED_GOAL:
      return routes.generate(routes.profile.goal.detail, {
        profileId: notification.profileId,
        goal: notification.meta.goalId,
      });
    case notificationTypes.STATUS_ADDED_TO_TEAM:
      return routes.generate(routes.profile.team, {
        profileId: notification.profileId,
      });
    case notificationTypes.STATUS_ADDED_TEAM_MEMBER:
      return routes.generate(routes.profile.team, {
        profileId: notification.profileId,
      });
    default:
      return '/';
  }
};

function NotificationIcon({notifications, profiles, profilesStore, usersStore, userId}) {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('everyone');
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const notificationLength = notifications.length;

  const filteredNotifications = useMemo(
    () =>
      notifications
        .filter(notification => filter === 'everyone' || filter === notification.profileId)
        .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
        .slice(offset, limit)
        .reduce((a, notification) => {
          // Fetch author and profile info for the notification
          const profile = profilesStore.getProfileById(notification.profileId);
          const author = notification.meta && notification.meta.authorId && usersStore.getUserById(notification.meta.authorId);

          // eslint-disable-next-line no-param-reassign
          a[notification.id] = {
            id: notification.id,
            type: notification.type,
            link: generateNotificationLink(notification),
            avatar: author && author.profileImagePublic,
            time: notification.created_at,
            name: author && `${author.firstname} ${author.lastname}`,
            profile: profile && `${profile.firstname} ${profile.lastname}`,
            text: notification.text,
          };

          return a;
        }, []),
    [notifications, filter, limit, offset, profilesStore, usersStore]
  );

  const onNotificationToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const onNotificationClose = useCallback(() => {
    setIsOpen(false);
    setFilter('everyone');
  }, [setIsOpen, setFilter]);

  const onNotificationClear = useCallback(
    () => notifications.map(notification => db.setNotificationSeen(userId, notification.id, true)),
    [notifications, userId]
  );

  const onFilterSelect = useCallback(event => setFilter(event.target.value), [setFilter]);

  const onLoadNext = useCallback(() => {
    setOffset(offset + limit);
  }, [limit, offset]);

  const onSetLimit = useCallback(value => setLimit(value), [setLimit]);

  const notificationHolderClassNames = useMemo(
    () =>
      classNames({
        'notification-holder': true,
        '-active': isOpen,
      }),
    [isOpen]
  );

  return (
    <div className={notificationHolderClassNames}>
      <button type="button" className="notification-icon" onClick={onNotificationToggle}>
        <span className="notification-wrap">
          <MdNotifications />
          {notificationLength > 0 && <div className="notification-number">{notificationLength}</div>}
        </span>
      </button>

      {isOpen && <div className="menu-underlay" onClick={onNotificationClose} />}

      <div className="menu-notifications">
        {isOpen && (
          <Notifications
            profiles={profiles}
            notifications={filteredNotifications}
            selectedProfile={filter}
            onProfileSelect={onFilterSelect}
            onClear={onNotificationClear}
            onClick={onNotificationClose}
            onLoadNext={onLoadNext}
            onSetLimit={onSetLimit}
            notificationLength={notificationLength}
          />
        )}
      </div>
    </div>
  );
}

class NotificationIconReactive extends React.Component {
  componentDidMount() {
    const {
      authUser,
      stores: {notificationsStore},
    } = this.props;
    notificationsStore.fetchNotifications(authUser.uid);
  }

  render() {
    const {
      authUser,
      stores: {notificationsStore, profilesStore, usersStore},
    } = this.props;

    // Fetch all profiles to show in the notification filter
    const {profiles} = profilesStore;
    const {notifications} = notificationsStore;
    // const myProfiles = profiles.filter(
    //   profile => (profile.id && authUser.type !== 'professional') || (profile.id && profile.permission && profile.permission.type !== 'professional')
    // );

    const unreadNotifications = notifications.filter(notification => notification.seen === false);

    return (
      <NotificationIcon
        notifications={unreadNotifications}
        profiles={profiles}
        profilesStore={profilesStore}
        usersStore={usersStore}
        notificationsStore={notificationsStore}
        userId={authUser.uid}
      />
    );
  }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(inject('stores')(observer(NotificationIconReactive)));
