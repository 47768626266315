/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-mutating-methods */
/* eslint-disable class-methods-use-this */
import {observable, action, decorate, computed} from 'mobx';
import {db} from '../firebase';

export class NotificationsStore {
  constructor(mainStore) {
    this.mainStore = mainStore;
  }

  mainStore = null;

  notifications = [];

  get unreadNotifications() {
    return this.notifications.filter(n => !n.seen).length;
  }

  getNotificationById = id => this.notifications.find(n => n.id === id);

  getNotificationByPostId = id =>
    this.notifications.find(n => (n.meta && n.meta.postId === id && !n.meta.commentId) || (n.meta && n.meta.id === id && !n.meta.commentId));

  getNotificationByCommentId = id => this.notifications.find(n => n.meta && n.meta.commentId === id);

  fetchNotifications = uid =>
    db.getNotifications(uid, {
      createHandler: this.createNotification,
      changeHandler: this.updateNotification,
      deleteHandler: this.deleteNotification,
    });

  createNotification = snapshot => {
    const notification = {
      id: snapshot.key,
      ...snapshot.val(),
    };
    const exisitingNotification = this.getNotificationById(snapshot.key);
    if (!exisitingNotification) {
      this.mainStore.usersStore.fetchUsersFromNotificationData(notification);
      this.notifications.push(notification);
    }
  };

  updateNotification = snapshot => {
    const exisitingNotification = this.getNotificationById(snapshot.key);
    this.updateNotificationFields(exisitingNotification, snapshot.val());
  };

  deleteNotification = snapshot => {
    this.notifications = this.notifications.filter(n => n.id !== snapshot.key);
  };

  updateNotificationFields = (oldNotification, newNotification) => {
    if (!newNotification) return;
    Object.keys(newNotification).forEach(key => {
      // eslint-disable-next-line no-param-reassign
      oldNotification[key] = newNotification[key];
    });
  };

  clearNotifications = () => {
    this.notifications = [];
  };
}

decorate(NotificationsStore, {
  notifications: observable,
  unreadNotifications: computed,
  createNotification: action,
  updateNotification: action,
  deleteNotification: action,
  updateNotificationFields: action,
});

export default NotificationsStore;
