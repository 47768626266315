/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {NotificationManager} from 'react-notifications';
import {inject, observer} from 'mobx-react';

import {DialogTitle} from '@material-ui/core';
import {Form} from 'informed';
import routes from '../../constants/routes';
import {inviteUser} from '../../firebase/db';
import withAuthorization from '../session/withAuthorization';
import getIsOwner from '../../helpers/getIsOwner';

import '../../assets/scss/components/invite/invite-card.scss';
import InviteForm from './form.invite';

function GroupInvite(props) {
  const [saving, setSaving] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  const {
    authUser,
    profile,
    onboard,
    history,
    onInvited,
    onCancel,
    stores: {profilesStore},
  } = props;

  useEffect(() => {
    setIsOwner(getIsOwner(profile));
  }, [profile]);

  const onInvite = async values => {
    setSaving(true);
    const loginUrl = `${window.location.origin}${routes.user.log_in}`;

    try {
      const existingTeamMember = Object.keys(profile.team).find(id => Object.keys(profile.team[id]).find(key => profile.team[id][key] === values.email));

      if (existingTeamMember) {
        NotificationManager.success(`${values.first_name} is already signed up as a team member`);
        setSaving(false);
      } else {
        const result = await inviteUser({
          firstName: values.first_name,
          lastName: values.last_name,
          email: values.email,
          relationship: 'Group member',
          type: values.type,
          inviteMessage: values.message,
          permissionLevel: values.permissions,
          profileId: profile.id,
          loginUrl,
        });

        NotificationManager.success(`${values.first_name} has been invited!`);

        // Navigate to the onboarding
        if (onboard) {
          history.push(
            routes.generate(routes.profile.onboard.intro, {
              profileId: profile.id,
            })
          );
        } else if (result.data.existing) {
          setSaving(false);
          onInvited();
        } else {
          // Perform a refetch of profiles to include the new team member
          await profilesStore.fetchProfiles(authUser.uid);
          setSaving(false);
          onInvited();
        }
      }
    } catch (e) {
      console.warn('Error in cloud function', e);

      setSaving(false);
    }
  };

  return (
    <div>
      <DialogTitle>Invite group members</DialogTitle>
      <Form
        // eslint-disable-next-line react/jsx-props-no-spreading
        render={params => <InviteForm onCancel={onCancel} saving={saving} isOwner={isOwner} hideRelationship />}
        initialValues={{permissions: 'admin'}}
        onSubmit={onInvite}
      />
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(inject('stores')(observer(GroupInvite)));
