import React from 'react';

import '../../assets/scss/components/errors/_pagenotfound.scss';

function NotFound() {
  return (
    <section className="container page-not-found">
      <h1>Page Not Found</h1>
    </section>
  );
}

export default NotFound;
