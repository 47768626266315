/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import classNames from 'classnames';
import routes from '../../constants/routes';

import ProfileHome from './profile.home';
import ProfileGoals from './profile.goals';
import ProfileDocuments from './profile.documents';
import ProfileGoalDetail from './goals.detail';
import ProfileGoalEdit from './goals.edit';
import ProfileHeader from './profile.header';
import ProfileTeam from './profile.team';
import ProfileAbout from './profile.about';
import ProfileCalendar from './profile.calendar';
import ProfileOnboardIntro from './onboard.intro';
import ProfileOnboardPost from './onboard.post';
import ProfileOnboardTeam from './onboard.team';
import ProfileOnboardComplete from '../onboarding/modal.onboard-complete';

import withProfile from './withProfile';
import VerifyEmail from '../2FADialogs/VerifyEmail';
import withSection from './withSections';

function ProfileRoutes({profile, match, ...rest}) {
  const profileClasses = () => {
    let subpage = 'home';
    if (match.params) {
      // eslint-disable-next-line fp/no-mutation
      subpage = match.params[0];
      // eslint-disable-next-line fp/no-mutation
      subpage = subpage.split('/')[0];
    }
    return classNames({
      page: true,
      '-profile': true,
      [`-${subpage}`]: true,
    });
  };

  return (
    <div className={profileClasses()}>
      {/* Profile Header & Menu */}

      <Route
        // path={/^((?!onboard).)*$/}
        component={routeProps => <ProfileHeader profile={profile} {...rest} {...routeProps} />}
      />

      <Switch>
        {/* Onboarding */}

        <Route exact path={routes.profile.onboard.intro} component={routeProps => <ProfileOnboardIntro profile={profile} {...rest} {...routeProps} />} />
        <Route exact path={routes.profile.onboard.detail} component={routeProps => <ProfileAbout onboard profile={profile} {...rest} {...routeProps} />} />
        <Route
          exact
          path={routes.profile.onboard.goals}
          component={routeProps => <ProfileGoalEdit onboard profile={profile} goal={{}} newGoal {...rest} {...routeProps} />}
        />
        <Route
          exact
          path={routes.profile.onboard.team}
          component={routeProps => <ProfileOnboardTeam onboard profile={profile} invite {...rest} {...routeProps} />}
        />
        <Route exact path={routes.profile.onboard.post} component={routeProps => <ProfileOnboardPost onboard profile={profile} {...rest} {...routeProps} />} />
        <Route
          exact
          path={routes.profile.onboard.done}
          component={routeProps => <ProfileOnboardComplete onboard profile={profile} {...rest} {...routeProps} />}
        />
        <Route exact path={routes.profile.post.view} component={routeProps => <ProfileHome {...rest} {...routeProps} />} />
        <Route exact path={routes.profile.home} component={routeProps => <ProfileHome {...rest} {...routeProps} />} />
        <Route exact path={routes.profile.about} component={routeProps => <ProfileAbout profile={profile} {...rest} {...routeProps} />} />
        <Route exact path={routes.profile.goals} component={ProfileGoals} />
        <Route exact path={routes.profile.documents} component={routeProps => <ProfileDocuments profile={profile} {...rest} {...routeProps} />} />
        <Route exact path={routes.profile.team} component={routeProps => <ProfileTeam {...rest} {...routeProps} />} />
        <Route exact path={routes.profile.invite} component={routeProps => <ProfileTeam {...rest} {...routeProps} invite />} />
        <Route exact path={routes.profile.teamMember} component={routeProps => <ProfileTeam profile={profile} {...rest} {...routeProps} />} />
        {process.env.REACT_APP_CALENDAR_ENABLED && (
          <Route exact path={routes.profile.calendar} component={routeProps => <ProfileCalendar profile={profile} {...rest} {...routeProps} />} />
        )}
        <Route
          exact
          path={routes.profile.goal.create}
          component={routeProps => <ProfileGoalEdit profile={profile} goal={{}} newGoal {...rest} {...routeProps} />}
        />
        <Route
          exact
          path={routes.profile.goal.detail}
          component={routeProps => (
            <ProfileGoalDetail
              profile={profile}
              goal={profile.goals[routeProps.match.params.goalId]}
              goalId={routeProps.match.params.goalId}
              {...rest}
              {...routeProps}
            />
          )}
        />
        <Route
          exact
          path={routes.profile.goal.edit}
          component={routeProps => (
            <ProfileGoalEdit
              profile={profile}
              goal={profile.goals[routeProps.match.params.goalId]}
              goalId={routeProps.match.params.goalId}
              {...rest}
              {...routeProps}
            />
          )}
        />

        {/* Blank Profile Route */}

        <Route exact path={routes.profile.index} component={ProfileHome} />

        {/* Catch All */}
        {/* <Route exact path="*" component={(routeProps) => <ProfileHome profile={profile} {...rest} {...routeProps} />} /> */}
      </Switch>
      <VerifyEmail />
    </div>
  );
}

export default withSection(withProfile(ProfileRoutes));
