/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {useCallback, useEffect, useState} from 'react';
import {observer, inject} from 'mobx-react';
import {withRouter} from 'react-router';
import {Redirect} from 'react-router-dom';

import {useProfileCompletion} from '../../hooks';
import OnboardingAddTeam from './inviting';
import OnboardingAddPost from './posts';
import OnboardingCompleteGoal from './goal';

import '../../assets/scss/components/profile/profile-completion.onboarding.scss';

function ProfileCompletion({profileId, posts, profile, onChangeData, onComplete}) {
  const [loading, , complete] = useProfileCompletion(profileId, profile, posts);
  const [hasTeam, setHasTeam] = useState(false);
  const [hasPosts, setHasPosts] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    setHasTeam('invites' in data);
    setHasPosts('posts' in data);
    // eslint-disable-next-line no-nested-ternary
    onChangeData(data.invites === undefined ? 1 : data.posts === undefined ? 2 : 3);
  }, [data, onChangeData]);

  const onFinishInviting = useCallback(invites => setData(prev => ({...prev, invites})), []);
  const onFinishPost = useCallback(finishPost => setData(prev => ({...prev, posts: finishPost})), []);
  const onCompleteGoal = useCallback(
    goal => {
      const {invites, posts: completePosts} = data;
      onComplete({invites, posts: completePosts, goal});
    },
    [data, onComplete]
  );

  if (loading) return null;
  if (complete) return <Redirect to={`/profile/${profileId}/home`} />;
  if (!hasTeam) return <OnboardingAddTeam onFinishInviting={onFinishInviting} name={profile.firstname} />;
  if (!hasPosts) return <OnboardingAddPost onFinishPost={onFinishPost} name={profile.firstname} />;
  return <OnboardingCompleteGoal onCompleteGoal={onCompleteGoal} />;
}

function Observe(props) {
  const {
    stores: {profilesStore, postsStore},
    profileId,
  } = props;

  const profile = profilesStore.getProfileById(profileId);

  return <ProfileCompletion profileId={profileId} profile={profile} posts={postsStore.posts} onChangeData={props.onChangeData} onComplete={props.onComplete} />;
}

export default React.memo(withRouter(inject('stores')(observer(Observe))));
