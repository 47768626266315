/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
// Adapted from: https://reactjs.org/docs/portals.html

import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import {RemoveScrollBar} from 'react-remove-scroll-bar';
import Icon from '../common/icon';

const modalRoot = document.getElementById('modal-root');

function ModalDismissButton({onClick}) {
  return (
    <button type="button" className="dismiss" onClick={onClick}>
      <Icon type="cancel" />
    </button>
  );
}

export const modalTypes = ['dual-img', 'dual', 'flex', 'focus', 'mini', 'medium'];

const Modal = ({type, children, handleDismiss, showDismiss, forwardedRef}) => {
  const element = document.createElement('div');

  useEffect(() => {
    modalRoot.appendChild(element);
  }, [type, children, element]);

  const renderModalClasses = () =>
    classNames({
      modal: true,
      [`-${type}`]: !!type,
    });

  return ReactDOM.createPortal(
    <div className={renderModalClasses()} onClick={handleDismiss} ref={forwardedRef}>
      <RemoveScrollBar />
      <div className="modal-inner" onClick={e => e.stopPropagation()}>
        {showDismiss && <ModalDismissButton onClick={handleDismiss} />}
        {children}
      </div>
    </div>,
    element
  );
};

export default React.forwardRef((props, ref) => <Modal {...props} forwardedRef={ref} />);
