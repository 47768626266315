/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {Form} from 'informed';
import {withRouter} from 'react-router-dom';

import {updateProfile} from 'firebase/auth';
import {auth, db} from '../../firebase';
import {Card, Panel} from '../common';
import SignUpForm from './form.signup';
import SignUpProfessionalForm from './form.professional.signup';
import SignUpFormSelf from './form.self.signup';
import routes from '../../constants/routes';
import config from '../../constants/config';

import '../../assets/scss/components/signup/signup-page.scss';
import family from '../../assets/svg/family.svg';
import provider from '../../assets/svg/professional.svg';
import self from '../../assets/svg/space.svg';

function SignUpPage({history, onStartSignup, onStopSignup, openPrivacyModal, openTermsModal}) {
  const [type, setType] = useState(null);
  const [step, setStep] = useState(0);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const {location} = history;
    const newTypeUrl = location.pathname.replace('/account/signup', '');
    if (newTypeUrl.length === 0) {
      setType(null);
      setStep(0);
    } else {
      const newType = newTypeUrl.replace('/', '');
      setType(newType);
    }
  }, [history, setStep, setType]);

  const clearType = () => {
    history.goBack();
  };

  const setIndividual = () => {
    setType('family');
    setStep(0);
    history.push(routes.user.sign_up_family);
  };

  const setProfessional = () => {
    setType('provider');
    setStep(0);
    history.push(routes.user.sign_up_provider);
  };

  const setSelf = () => {
    setType('individual');
    setStep(0);
    history.push(routes.user.sign_up_individual);
  };

  const onSubmit = async values => {
    const {
      firstname,
      lastname,
      email,
      mobile,
      password,
      organisation,
      job_title,
      birthdate,
      country: selfCountry,
      postcode: selfPostcode,
      provider: {description, website, address} = {},
      profile: {firstname: profileFirstname, lastname: profileLastname, birthdate: profileBirthdate, relationship: profileRelationship, country, postcode} = {},
    } = values;

    const id = db.createUUID();
    onStartSignup();
    setSaving(true);

    try {
      const {user} = await auth.doCreateUserWithEmailAndPassword(email, password);
      let plan;
      let customer;
      let subscriptionData;
      await updateProfile(user, {displayName: `${firstname} ${lastname}`});
      switch (type) {
        case 'family':
          await db.createProfile(id, profileFirstname, profileLastname, false, 'family', '', {country, postcode});
          await db.doCreateUser(user.uid, firstname, lastname, email, mobile, {country});
          await db.updatePermissions(id, user.uid, 'owner', 'family', profileRelationship);
          await db.adjustUserPermissions({
            memberId: config.sameviewTeamUid,
            type: 'professional',
            relationship: 'Support',
            permissionLevel: 'owner',
            profileId: id,
          });
          await db.addStripeCustomers(user.uid, {
            name: `${firstname} ${lastname}`,
            email,
          });

          // eslint-disable-next-line fp/no-mutation
          plan = await db.getStripePlanId();
          // eslint-disable-next-line fp/no-mutation
          customer = await db.getStripeCustomerId(user.uid);

          await db.saveProfile(id, {birthdate: profileBirthdate});
          await db.saveUser(user.uid, {created: Date.now()});

          // Create or edit the subscription plan.
          // eslint-disable-next-line fp/no-mutation
          subscriptionData = {
            cancel_at_period_end: false,
            coupon: '',
            customer,
            id: '',
            item: '',
            order_id: id,
            plan,
            trial_from_plan: true,
            user_id: user.uid,
          };

          // Save the subscription to the database
          await db.updateStripeSubscriptions(id, subscriptionData);
          onStopSignup();
          return history.push(
            routes.generate(routes.profile.onboard.intro, {
              profileId: id,
            })
          );
        case 'individual':
          await db.createProfile(id, firstname, lastname, true, 'individual', '', {country: selfCountry, postcode: selfPostcode});
          await db.doCreateUser(user.uid, firstname, lastname, email, mobile, {country: selfCountry});
          await db.updatePermissions(id, user.uid, 'owner', 'individual', 'Own');
          await db.adjustUserPermissions({
            memberId: config.sameviewTeamUid,
            type: 'professional',
            relationship: 'Support',
            permissionLevel: 'owner',
            profileId: id,
          });
          await db.addStripeCustomers(user.uid, {
            name: `${firstname} ${lastname}`,
            email,
          });

          // eslint-disable-next-line fp/no-mutation
          plan = await db.getStripePlanId();
          // eslint-disable-next-line fp/no-mutation
          customer = await db.getStripeCustomerId(user.uid);

          await db.saveProfile(id, {birthdate});
          await db.saveUser(user.uid, {created: Date.now()});

          // Create or edit the subscription plan.
          // eslint-disable-next-line fp/no-mutation
          subscriptionData = {
            cancel_at_period_end: false,
            coupon: '',
            customer,
            id: '',
            item: '',
            order_id: id,
            plan,
            trial_from_plan: true,
            user_id: user.uid,
          };

          // Save the subscription to the database
          await db.updateStripeSubscriptions(id, subscriptionData);
          onStopSignup();
          return history.push(
            routes.generate(routes.profile.onboard.intro, {
              profileId: id,
            })
          );
        case 'provider':
          await db.doCreateProfessional(user.uid, firstname, lastname, email, mobile, job_title, description, {
            name: organisation,
            website,
            address,
            mobile,
          });
          await db.addStripeCustomers(user.uid, {
            name: `${firstname} ${lastname}`,
            email,
          });
          await db.saveUser(user.uid, {created: Date.now()});

          onStopSignup();

          return history.push(routes.professional.onboard.intro);
        default:
          return null;
      }
    } catch (e) {
      setError(e);
      setSaving(false);
      onStopSignup();
    }

    return null;
  };

  return (
    <div className="page -account">
      <div className="container">
        <Card panels full>
          {!type && (
            <Panel secondary compact third={!type}>
              <h2>Getting started</h2>
              <p>Sameview provides a single place to share information with your team and work towards your goals.</p>
              <p>Sameview is for families, allied health professionals, educators, and support providers</p>
            </Panel>
          )}

          {type === 'family' && step === 0 && (
            <Panel secondary compact>
              <h2>Create an account for your family</h2>
              <p>{`Let's get you started in sameview so you can have everyone in your team on the same page and be in control of better coordinated care.`}</p>
            </Panel>
          )}

          {type === 'family' && step === 1 && (
            <Panel secondary compact>
              <h2>Tell us a little about the person being cared for</h2>
              <p>{`Let's get you started in sameview so you can have everyone in your team on the same page and be in control of better coordinated care.`}</p>
            </Panel>
          )}

          {type === 'provider' && step === 0 && (
            <Panel secondary compact>
              <h2>Create your team member account</h2>
              <p>{`Let's get you started in sameview so you can provide your clients with a new customer centric care experience.`}</p>
            </Panel>
          )}

          {type === 'provider' && step === 1 && (
            <Panel secondary compact>
              <h2>Create a sameview profile</h2>
              <p>Please tell us a bit more about what you do, and where you work</p>
            </Panel>
          )}

          {type === 'individual' && step === 0 && (
            <Panel secondary compact>
              <h2>Create your individual account</h2>
              <p>{`Let's get you started in sameview so you can have everyone in your team on the same page and be in control of better coordinated care.`}</p>
              <p>Please tell us a little about yourself.</p>
            </Panel>
          )}

          <Panel content compact twoThird={!type}>
            {type && (
              <Form onSubmit={onSubmit}>
                {type === 'family' && (
                  <SignUpForm saving={saving} onStep={setStep} onCancel={clearType} openPrivacyModal={openPrivacyModal} openTermsModal={openTermsModal} />
                )}
                {type === 'provider' && (
                  <SignUpProfessionalForm
                    saving={saving}
                    onStep={setStep}
                    onCancel={clearType}
                    openPrivacyModal={openPrivacyModal}
                    openTermsModal={openTermsModal}
                  />
                )}
                {type === 'individual' && (
                  <SignUpFormSelf saving={saving} onStep={setStep} onCancel={clearType} openPrivacyModal={openPrivacyModal} openTermsModal={openTermsModal} />
                )}
                {error && (
                  <section className="article-errors">
                    <div>{error.message}</div>
                  </section>
                )}
              </Form>
            )}
            {!type && (
              <div>
                <h4 className="signup-page__title">Please select your account type:</h4>
                <div className="signup-page__main">
                  <div className="signup-page__container">
                    <button type="button" className="signup-page__selector" onClick={setSelf}>
                      <div className="signup-page__text-container">
                        <p>I manage my own care</p>
                        <img src={self} alt="OwnCare" className="signup-page__image" />
                      </div>
                      <span type="primary" className="button -primary -center">
                        <b>Individual</b>
                      </span>
                    </button>
                    <button type="button" className="signup-page__selector" onClick={setIndividual}>
                      <div className="signup-page__text-container">
                        <p>I care for someone in my family</p>
                        <img src={family} alt="Family" className="signup-page__image" />
                      </div>
                      {/* eslint-disable-next-line react/no-unknown-property */}
                      <span type="primary" right="true" className="button -primary -center">
                        <b>Family</b>
                      </span>
                    </button>
                    <button type="button" className="signup-page__selector" onClick={setProfessional}>
                      <div className="signup-page__text-container">
                        <p>I am a professional, educator, support provider</p>
                        <img src={provider} alt="Professional" className="signup-page__image" />
                      </div>
                      <span type="primary" style={{float: 'right'}} className="button -primary -center">
                        <b>Provider</b>
                      </span>
                    </button>
                  </div>
                </div>
                <p className="signup-page__helper">&#42; If you are a professional and care for someone else then please create a Provider account first. </p>
              </div>
            )}
          </Panel>
        </Card>
      </div>
    </div>
  );
}

export default withRouter(SignUpPage);
