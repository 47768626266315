/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

import {NotificationManager} from 'react-notifications';
import {Card, Heading} from '../common';
import {auth} from '../../firebase';
import routes from '../../constants/routes';

function PasswordForgotPage({history}) {
  return (
    <section className="page -account">
      <div className="container">
        <Card focus>
          <Heading secondary>
            <h1>{`Can't access your account?`}</h1>
          </Heading>
          <PasswordForgotForm history={history} />
        </Card>
      </div>
    </section>
  );
}

function PasswordForgotForm({history}) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  const onSubmit = event => {
    auth
      .doPasswordReset(email)
      .then(() => {
        setEmail('');
        setError(null);
        NotificationManager.success('Check your email for your login details');
      })
      .catch(e => {
        setError(e);
        NotificationManager.error("We weren't able to reset your password");
      });

    event.preventDefault();
  };

  const backToLogin = e => {
    e.preventDefault();
    history.push(routes.user.log_in);
  };

  const isInvalid = email === '';

  return (
    <form onSubmit={onSubmit}>
      <input value={email} onChange={event => setEmail(event.target.value)} type="text" placeholder="Email Address" />
      <button type="button" onClick={backToLogin} className="button -tertiary -left">
        Cancel
      </button>
      <button disabled={isInvalid} type="submit" className="button -primary -right">
        Reset My Password
      </button>
      {error && <p>{error.message}</p>}
    </form>
  );
}

function PasswordForgotLink() {
  return (
    <span>
      <Link to={routes.user.password_reset}>Reset your Password</Link>
    </span>
  );
}

export default withRouter(PasswordForgotPage);

export {PasswordForgotForm, PasswordForgotLink};
