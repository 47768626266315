/* eslint-disable react/prop-types */
import React, {useEffect, useState, useCallback} from 'react';
import {EmailAuthProvider, reauthenticateWithCredential, updatePassword, linkWithPhoneNumber, RecaptchaVerifier} from 'firebase/auth';
import {httpsCallable} from 'firebase/functions';
import {inject, observer} from 'mobx-react';
import {NotificationManager} from 'react-notifications';
import {Button, Heading} from '../common';
import {functions} from '../../firebase/firebase';
import countries from '../../assets/countryCodes.json';

function Security2FAForm({auth, authUser, onDismiss, stores: {profilesStore, notificationsStore}}) {
  const [passwordCurrent, setPasswordCurrent] = useState('');
  const [passwordOne, setPasswordOne] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [recaptchaVerifier, setRecaptcha] = useState();
  const [confirmationResult, setConfirmationResult] = useState('');
  const [code, setCode] = useState('');
  const handlePhoneNumberChange = useCallback(e => setPhoneNumber(e.target.value), []);
  const handleCode = useCallback(e => setCode(e.target.value), []);

  useEffect(() => {
    setRecaptcha(prev => prev || new RecaptchaVerifier('recaptcha-container-mfa', {size: 'invisible'}, auth));
  }, [auth]);

  useEffect(() => {
    if (authUser.mobile) {
      const {dialCode} = countries.find(obj => obj.name === authUser.country) || {};
      let number = authUser.mobile;
      if (dialCode) {
        // eslint-disable-next-line fp/no-mutation
        number = number[0] === '0' ? number.substring(1) : number;
        // eslint-disable-next-line fp/no-mutation
        number = number.includes('+') ? number : `${dialCode} ${number}`;
      }
      setPhoneNumber(number);
    }
  }, [authUser]);

  const logout = useCallback(async () => {
    profilesStore.clearProfiles();
    notificationsStore.clearNotifications();
    await auth.signOut();
  }, [profilesStore, notificationsStore, auth]);

  const handleSendSMS = useCallback(async () => {
    setLoading(true);
    setError('');
    linkWithPhoneNumber(auth.currentUser, phoneNumber, recaptchaVerifier)
      .then(result => {
        setConfirmationResult(result);
      })
      .catch(sendSmsError => {
        if (sendSmsError.code === 'auth/invalid-phone-number') {
          setError('Invalid phone number, please enter an international format (eg. +61 491 570 156)');
        } else {
          setError(sendSmsError.message);
        }
      })
      .finally(() => setLoading(false));
  }, [phoneNumber, auth, recaptchaVerifier]);

  const handleSubmitCode = useCallback(async () => {
    try {
      setLoading(true);
      setError('');
      await confirmationResult.confirm(code);
      const obj = {
        phoneNumber,
        displayName: auth.currentUser.displayName || authUser.firstname,
      };
      await httpsCallable(functions, 'enroll2FA')(obj);
      await logout(auth, profilesStore, notificationsStore);
    } catch (submitError) {
      setLoading(false);
      if (submitError.code === 'auth/invalid-verification-code') {
        setError('Invalid verification code, please try again');
      } else {
        setError(submitError.message);
      }
    }
  }, [confirmationResult, code, phoneNumber, auth, authUser.firstname, logout, profilesStore, notificationsStore]);

  const onSubmit = async event => {
    event.preventDefault();

    if (!passwordOne || !passwordTwo || passwordOne.length < 6 || passwordTwo.length < 6) {
      setError({message: 'Passwords must be six characters in length'});
      return;
    }
    if (passwordOne !== passwordTwo) {
      setError({message: 'Passwords must match'});
      return;
    }

    try {
      setLoading(true);
      const {currentUser} = auth;
      const cred = EmailAuthProvider.credential(currentUser.email, passwordCurrent);

      await reauthenticateWithCredential(currentUser, cred);

      await updatePassword(currentUser, passwordOne).then(() => {
        setPasswordCurrent('');
        setPasswordOne('');
        setPasswordTwo('');
        setError(null);
        NotificationManager.success('Password updated');
        onDismiss();
      });
    } catch (changePasswordError) {
      switch (changePasswordError.code) {
        case 'auth/wrong-password':
          // eslint-disable-next-line fp/no-mutation
          changePasswordError.message = 'Wrong password entered';
          break;
        case 'auth/multi-factor-auth-required':
          // eslint-disable-next-line fp/no-mutation
          changePasswordError.message = 'Please remove your 2FA first to update your email address, after adding you can restore your 2FA';
          break;
        default:
          break;
      }
      NotificationManager.error(changePasswordError.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="form-password" onSubmit={onSubmit}>
      <Heading primary>
        <h4>Validate phone number</h4>
      </Heading>

      <div id="recaptcha-container-mfa" />

      {!confirmationResult ? (
        <div>
          <p>Phone number:</p>
          <input id="phoneNumber" value={phoneNumber} onChange={handlePhoneNumberChange} type="text" placeholder="+61 491 570 156" />

          <Button disabled={loading} loading={loading} type="primary" className="button -primary -small" onClick={handleSendSMS}>
            Send SMS
          </Button>

          <p>
            After entering your phone number an SMS message will be sent to your phone with a verification code. Charges may apply by your service provider.
          </p>
        </div>
      ) : (
        <div>
          <p>Code:</p>
          <input value={code} onChange={handleCode} type="text" placeholder="123456" />
          <Button loading={loading} disabled={loading} type="primary" className="button -primary -small" onClick={handleSubmitCode}>
            Done
          </Button>
        </div>
      )}

      {error && (
        <section className="article-errors">
          <p>{error.message}</p>
        </section>
      )}
    </form>
  );
}

export default inject('stores')(observer(Security2FAForm));
