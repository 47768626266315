/* eslint-disable react/prop-types */
/* eslint-disable fp/no-mutation */
import React, {useEffect, useState, useCallback} from 'react';
import {inject, observer} from 'mobx-react';
import {NotificationManager} from 'react-notifications';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {db} from '../../firebase';
import withAuthorization from '../session/withAuthorization';
import {Button, Icon, Avatar} from '../common';
import ModalDelete from '../common/modal.delete';
import ModalSubscription from '../common/modal.subscription';
import {getFullName} from '../../helpers/getFullName';
import getIsOwner from '../../helpers/getIsOwner';
import config from '../../constants/config';

const Actions = ({stripeCustomerID, isOwner, isSubscriber, profile, onDeleteClick, onSubscriptionClick}) => {
  const {subscription} = profile;

  const activeButton = stripeCustomerID && (
    <Button type="tertiary" size="small" disabled>
      Plan is Active
    </Button>
  );
  const trialingButton = stripeCustomerID && (
    <Button type="tertiary" size="small" disabled>
      Plan in Trial
    </Button>
  );
  const editButton = stripeCustomerID && (
    <Button type="icon" size="small" onClick={() => onSubscriptionClick()}>
      <Icon type="cog" />
    </Button>
  );
  const deleteButton = (
    <Button type="icon" size="small" onClick={() => onDeleteClick()}>
      <Icon type="trash" />
    </Button>
  );
  const resubscriptionButton = stripeCustomerID && (
    <Button type="secondary" size="small" onClick={() => onSubscriptionClick()} disabled={!stripeCustomerID}>
      Re-Activate Subscription
    </Button>
  );
  const subscriptionButton = stripeCustomerID && (
    <Button type="secondary" size="small" onClick={() => onSubscriptionClick()} disabled={!stripeCustomerID}>
      {isOwner ? 'Subscribe' : 'Gift Subscription'}
    </Button>
  );
  let button;

  if (isSubscriber) {
    if (subscription.saving === true) {
      button = (
        <>
          <div className="description">Your subscription is being updated</div>
          <div className="buttons ">
            {editButton}
            {deleteButton}
          </div>
        </>
      );
    } else if (subscription.status === 'saving') {
      button = (
        <>
          <div className="description">Your subscription is being saved</div>
          <div className="buttons ">
            {editButton}
            {deleteButton}
          </div>
        </>
      );
    } else if (subscription.status === 'active' && subscription.cancel_at_period_end === true) {
      button = (
        <>
          <div className="description">
            Your subscription will cancel on {subscription.current_period_end ? dayjs.unix(subscription.current_period_end).format('DD/MM/YYYY') : ' - '}
          </div>
          <div className="buttons ">{resubscriptionButton}</div>
        </>
      );
    } else if (subscription.status === 'active' && subscription.cancel_at_period_end === false) {
      button = (
        <>
          <div className="description">
            Your next {`${subscription.plan.interval}ly`} payment will be on{' '}
            {subscription.current_period_end ? dayjs.unix(subscription.current_period_end).format('DD/MM/YYYY') : ' - '}
          </div>
          <div className="buttons ">
            {activeButton}
            {editButton}
            {deleteButton}
          </div>
        </>
      );
    } else if (subscription.status === 'trialing' && subscription.cancel_at_period_end === true) {
      button = (
        <>
          <div className="description">
            Your trial will stop on {subscription.current_period_end ? dayjs.unix(subscription.current_period_end).format('DD/MM/YYYY') : ' - '}
          </div>
          <div className="buttons ">{resubscriptionButton}</div>
        </>
      );
    } else if (subscription.status === 'trialing' && subscription.cancel_at_period_end === false) {
      button = (
        <>
          <div className="description">
            Your first {`${subscription.plan.interval}ly`} payment will be on{' '}
            {subscription.current_period_end ? dayjs.unix(subscription.current_period_end).format('DD/MM/YYYY') : ' - '}
          </div>
          <div className="buttons ">
            {trialingButton}
            {editButton}
            {deleteButton}
          </div>
        </>
      );
    } else {
      button = (
        <>
          <div className="description" />
          <div className="buttons">{subscriptionButton}</div>
        </>
      );
    }
  } else {
    switch (subscription.status) {
      case 'creating':
      case 'active':
      case 'trialing':
        button = (
          <>
            <div className="description" />
            <div className="buttons">
              <Button type="tertiary" size="small" disabled>
                Subscription paid by another user
              </Button>
            </div>
          </>
        );
        break;

      default:
        button = (
          <>
            <div className="description" />
            <div className="buttons">{subscriptionButton}</div>
          </>
        );
        break;
    }
  }

  return button;
};

function SubscriptionProfiles({authUser, stripeCustomerID, stripeCard, creditCard, creditExpMonth, creditExpYear, stores: {profilesStore}}) {
  const {profiles} = profilesStore;

  const [products, setStripeProduct] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [selectedPlan, setSelectedPlan] = useState({});
  const [validCoupon, setValidCoupon] = useState('');
  const [currentCoupon, setCurrentCoupon] = useState('');
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [couponValue, setCouponValue] = useState('');
  const [couponMessage, setCouponMessage] = useState('');
  const [couponClass, setCouponClass] = useState('');
  const [timeoutCoupon, setTimeoutCoupon] = useState(0);
  const [myProfiles, setMyProfiles] = useState([]);

  const fetchProductInformation = useCallback(async () => {
    if (!isEmpty(products)) return;

    // Fetch the existing billing information from the database
    await db.getStripeProducts(snapshot => {
      const snapshotStripeProduct = snapshot.val();

      // If billing information has been saved previously, set into state.
      if (snapshotStripeProduct) {
        // Remove the "update" item we don't need.
        // eslint-disable-next-line fp/no-delete
        delete snapshotStripeProduct.update;

        const {update, ...nextProduct} = snapshotStripeProduct;
        setStripeProduct({...snapshotStripeProduct, ...nextProduct});
      }
    });
  }, [products, setStripeProduct]);

  fetchProductInformation();

  const toggleCancelModal = (e, profile = null) => {
    if (e) e.preventDefault();
    setSelectedProfile(profile);
    setSubscriptionModalOpen(false);
    setCancelModalOpen(!cancelModalOpen);
  };

  const toggleSubscriptionModal = (e, profile = null) => {
    if (e) e.preventDefault();
    setSelectedProfile(profile);
    setCancelModalOpen(false);
    setSubscriptionModalOpen(!subscriptionModalOpen);
  };

  const onSubscriptionCancel = async (e, profile) => {
    // Validate that only the owner of the subscription can cancel it.
    if (authUser.uid !== profile.subscription.user_id) NotificationManager.error('Sorry, only the owner of the subscription can cancel it.');

    try {
      // Cancel the subscription.
      const subscriptionData = {
        cancel_at_period_end: true,
      };

      // Update the subscription to the database
      await db.updateStripeSubscriptions(profile.id, subscriptionData);

      NotificationManager.success('Thank you, your subscription has been cancelled. You can come back at any time and re-enable it.');
      setCancelModalOpen(false);
    } catch (error) {
      NotificationManager.error(error);
    }
  };

  // eslint-disable-next-line no-promise-executor-return
  const delay = ms => new Promise(res => setTimeout(res, ms));

  const onSubscriptionSubmit = async values => {
    const item =
      selectedProfile.subscription &&
      selectedProfile.subscription.items &&
      selectedProfile.subscription.items.data &&
      selectedProfile.subscription.items.data[0].id
        ? selectedProfile.subscription.items.data[0].id
        : '';

    try {
      setSaving(true);

      // Create or edit the subscription plan.
      const subscriptionData = {
        cancel_at_period_end: false,
        coupon: validCoupon,
        customer: stripeCustomerID,
        id: selectedProfile.subscription.id || '',
        item,
        order_id: selectedProfile.id,
        plan: selectedPlan.id,
        trial_from_plan: true,
        user_id: authUser.uid,
      };

      // Save the subscription to the database
      await db.updateStripeSubscriptions(selectedProfile.id, subscriptionData);

      await delay(2000);

      // Now fetch the user to put it in the profilesStore
      await profilesStore.fetchProfiles(authUser.uid);

      NotificationManager.success('Thank you for your subscription.');

      setSaving(false);
      setValidCoupon('');
      setCurrentCoupon('');
      toggleSubscriptionModal();
    } catch (error) {
      NotificationManager.error(error.message);
      setSaving(false);
    }
  };

  const onPlanClick = async plan => {
    if (!isEqual(selectedPlan, plan)) setSelectedPlan(plan);
    onCouponChange(currentCoupon);
  };

  const onCouponChange = newCoupon => {
    // Reset the coupon message.
    setCouponMessage('');
    setValidCoupon('');
    setCurrentCoupon(newCoupon);

    if (timeoutCoupon) clearTimeout(timeoutCoupon);
    if (!newCoupon) return null;

    // Check the coupon on 1 second of inactive typing
    const newTimeoutCoupon = setTimeout(() => {
      db.getStripeCoupon(newCoupon)
        .then(snapshot => {
          let newCouponMessage = '';

          if (Object.entries(snapshot).length > 0 && snapshot.constructor === Object) {
            if (snapshot.metadata && snapshot.metadata.plan !== selectedPlan.id) {
              setCouponMessage('This promo code is not valid for the selected plan.');
              setCouponClass('error');
            }

            // Generate a descriptive message for the valid coupon
            newCouponMessage += `Promo code applied: ${snapshot.name}`;
            newCouponMessage += snapshot.percent_off ? ` (${snapshot.percent_off}% off ${snapshot.duration})` : '';
            newCouponMessage += snapshot.amount_off ? ` ($${snapshot.amount_off / 100} off ${snapshot.duration})` : '';

            setValidCoupon(newCoupon);
            setCouponMessage(newCouponMessage);
            setCouponClass('success');
          } else {
            setCouponMessage('Invalid promo code');
            setCouponClass('error');
          }
        })
        .catch(error => {
          NotificationManager.error(error.message);
        });
    }, 3000);

    setTimeoutCoupon(newTimeoutCoupon);
    return true;
  };

  useEffect(() => {
    const newMyProfiles = profiles.filter(
      profile => (profile.id && authUser.type !== 'professional') || (profile.id && profile.permission && profile.permission.type !== 'professional')
    );

    setMyProfiles(newMyProfiles);
  }, [authUser.type, profiles, setMyProfiles]);

  return (
    <section className="section account-form">
      {stripeCustomerID === null && <div>Please enter a credit card before subscribing</div>}

      <ul className="list">
        {myProfiles.map(profile => (
          <li key={profile.id} className="list__item">
            <Avatar className="list__item-avatar" src={profile.profileImagePublic} />
            <div className="list__item-name">
              {getFullName(profile)}
              {config.debugging && (
                <div>
                  <small>{profile.id}</small>
                </div>
              )}
            </div>
            <div className="list__item-action right">
              <Actions
                isOwner={getIsOwner(profile)}
                isSubscriber={authUser.uid === profile.subscription.user_id}
                stripeCustomerID={stripeCustomerID}
                profile={profile}
                onDeleteClick={e => toggleCancelModal(e, profile)}
                onSubscriptionClick={e => toggleSubscriptionModal(e, profile)}
              />
            </div>
          </li>
        ))}
      </ul>

      <ModalDelete isOpen={cancelModalOpen} onDismiss={event => toggleCancelModal(event)} onConfirm={event => onSubscriptionCancel(event, selectedProfile)} />

      <ModalSubscription
        selectedProfile={selectedProfile}
        subscriptionModalOpen={subscriptionModalOpen}
        toggleSubscriptionModal={toggleSubscriptionModal}
        couponValue={couponValue}
        onSubscriptionSubmit={onSubscriptionSubmit}
        selectedPlan={selectedPlan}
        onPlanClick={onPlanClick}
        products={products}
        couponMessage={couponMessage}
        stripeCard={stripeCard}
        creditCard={creditCard}
        creditExpMonth={creditExpMonth}
        creditExpYear={creditExpYear}
        saving={saving}
        setCouponValue={setCouponValue}
        onCouponChange={onCouponChange}
        couponClass={couponClass}
      />
    </section>
  );
}

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(inject('stores')(observer(SubscriptionProfiles)));
