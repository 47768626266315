/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Modal from './modal';

export const modalImageTypes = ['primary', 'secondary'];

function ModalImage({isOpen, type, src, alt, onDismiss, children}) {
  return (
    <Modal type="dual-img" isOpen={isOpen} handleDismiss={onDismiss} showDismiss={!!onDismiss}>
      <img className="image" src={src} alt={alt} />
      <div className={`panel -${type}`}>{children}</div>
    </Modal>
  );
}

ModalImage.propTypes = {
  /** The modal panel type */
  type: PropTypes.oneOf(modalImageTypes),
  /** The image src */
  src: PropTypes.string.isRequired,
  /** The image alt */
  alt: PropTypes.string.isRequired,
  /** The onDismiss function. If this is passed, a dismiss button will be shown */
  onDismiss: PropTypes.func,
};

ModalImage.defaultProps = {
  /** Sets a the panel type as primary by default */
  type: 'primary',
  onDismiss: () => {},
};

export default ModalImage;
