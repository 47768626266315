/* eslint-disable react/prop-types */
import React from 'react';

import StarIcon from '../../assets/img/star.png';

import '../../assets/scss/components/post/post.scss';

function PostGoalComplete({profile, data = {}}) {
  const {goals, goalCompleted} = data;
  if (!goalCompleted) {
    return null;
  }
  let name;
  Object.keys(goals || {}).forEach(goalId => {
    const goal = profile.goals[goalId];
    // eslint-disable-next-line fp/no-mutation
    name = goal.name;
  });
  if (!name) return null;
  return (
    <div className="complete__container complete__container--goal">
      <div className="complete__message">
        <div className="complete__text-container">
          <img className="complete__text-icon" src={StarIcon} alt="" />
          <h4 className="complete__text-label">Goal complete</h4>
        </div>
        <h4 className="complete__name">{name}</h4>
      </div>
    </div>
  );
}

export default PostGoalComplete;
