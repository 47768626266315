/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/**
 * Calendar
 */
import React, {useRef, useEffect} from 'react';
import {BryntumCalendar} from '@bryntum/calendar-react';
import withBreakpoints from '../utilities/withBreakpoints';
import getIsViewer from '../../helpers/getIsViewer';

import calendarConfig from './CalendarConfig';
import './Calendar.scss';

function Calendar({breakpoint, profile}) {
  const calendarRef = useRef(null);
  const isMobile = breakpoint === 'mobile';
  const isViewer = getIsViewer(profile);

  // todo: hack fix for mobile. should be able to remove in upcoming update?
  // https://github.com/bryntum/support/issues/6522
  // https://forum.bryntum.com/viewtopic.php?p=121511#p121511
  // https://forum.bryntum.com/viewtopic.php?p=105826
  useEffect(() => {
    if (calendarRef && calendarRef.current && isMobile) {
      const calendar = calendarRef.current.instance;
      // ...
      // eslint-disable-next-line fp/no-mutating-assign
      Object.assign(calendar.eventEdit.editorConfig, {autoClose: false, modal: true, maximized: true});
    }
  }, [calendarRef, isMobile]);

  return <BryntumCalendar ref={calendarRef} {...calendarConfig(profile.id, isMobile, isViewer)} />;
}

export default withBreakpoints()(Calendar);
