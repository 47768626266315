/* eslint-disable react/prop-types */
/* eslint-disable fp/no-mutation */
import React, {useCallback} from 'react';
import {updateProfile} from '../../firebase/db';
import ToggleButton from '../common/ToggleButton';

export default function SectionItem({availableProfileSections, item, firstname, existingSections, profileId}) {
  const handleToggleSection = useCallback(async () => {
    let obj = {};
    if (!existingSections[item.key]) {
      const oneField = Object.keys(availableProfileSections[item.key].fields)[0];
      obj = {[item.key]: {[oneField]: ''}, hiddenSection: false};
    } else {
      obj = {[item.key]: existingSections[item.key]};
      obj[item.key].hiddenSection = !obj[item.key].hiddenSection;
    }
    await updateProfile(profileId, obj);
  }, [item, availableProfileSections, profileId, existingSections]);

  const isChecked = Object.keys(existingSections[item.key] || {}).length > 0 && !existingSections[item.key]?.hiddenSection;

  if (item.default) {
    return null;
  }

  return (
    <div key={item.key} className="profile-list">
      <div className="profile-list-text">
        <div>
          {item.addFirstNameToTitle ? `${firstname}'s ` : ''}
          {item.name}
        </div>
        <div className="profile-list-description">{item.description}</div>
      </div>
      <ToggleButton checked={isChecked} onClick={handleToggleSection} />
    </div>
  );
}
