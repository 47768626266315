/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';

import withProfile from '../profile/withProfile';
import Post from '../post';
import Feed from './index';
import Loading from '../utilities/loading';
import NotFound from '../errors/notfound';

function SinglePostFeed(props) {
  const {
    postId,
    profile,
    stores: {profilesStore, postsStore},
  } = props;

  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPost = async fetchPostId => {
      const post = await postsStore.fetchPost(profile.id, fetchPostId);

      setPosts(post ? [post] : []);
      setLoading(false);
    };

    fetchPost(postId);
  }, [postId, postsStore, profile.id, setLoading, setPosts]);

  if (loading || !posts) return <Loading />;

  // TODO_MB: if the dateSortedFeedData here is length=0 then we didn't find the post.
  // do we show an empty state here for that case
  if (posts.length === 0) return <NotFound text="Post not found" />;

  // TODO improve?
  const dateSortedFeedData = posts.map(post => {
    const newProfile = profilesStore.getProfileById(post.profileId);
    if (!newProfile) {
      return null;
    }
    return <Post data={post} profile={newProfile} key={post.id} />;
  });

  return <Feed title="Viewing Post" posts={dateSortedFeedData} />;
}

export default inject('stores')(withProfile(observer(SinglePostFeed)));
