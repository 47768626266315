/* eslint-disable react/prop-types */
import React, {useCallback, useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {inject, observer} from 'mobx-react';

import {RiFileCopy2Line} from 'react-icons/ri';

import getIsOwner from '../../helpers/getIsOwner';
import getIsContributor from '../../helpers/getIsContributor';
import withAuthorization from '../session/withAuthorization';

import {Card, HelpPanel, VimeoEmbed} from '../common';
import Calendar from '../calendar/Calendar';

import '../../assets/scss/components/profile/profile-form.scss';

function ProfileCalendar({profile, onboard, authUser}) {
  const canEdit = getIsContributor(profile) || getIsOwner(profile);
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(() => setCopied(true), []);

  return (
    <div className="container">
      <Card help full sections>
        <div className="card__content">
          {canEdit && (
            <HelpPanel defaultsOpen={onboard}>
              <h3>Help with adding events to your calendar</h3>
              <p>Use the calendar to share you weekly schedule, upcoming appointments, or recurring activities.</p>
              <VimeoEmbed videoId="557146627" videoName="HOW TO ADD CALENDAR EVENTS" />
              <h3>How to add the iCal feed to the calendar on my device?</h3>

              {profile?.id && authUser?.uid && (
                <CopyToClipboard
                  text={`${process.env.REACT_APP_FIREBASE_MASKED_URL || process.env.REACT_APP_FIREBASE_URL}calendarFeed?pid=${profile?.id}&uid=${
                    authUser?.uid
                  }`}
                  onCopy={handleCopy}>
                  <div className="profile-completion__box">
                    <RiFileCopy2Line />
                    <h5>{copied ? `COPIED` : `Copy Calendar Link to Clipboard`}</h5>
                  </div>
                </CopyToClipboard>
              )}

              <h4>For Mac</h4>
              <ol>
                <li>In the Calendar app on your Mac, choose File &gt; New Calendar Subscription.</li>
                <li>Enter the calendar&apos;s web address, then click Subscribe.</li>
                <li>Click the Auto-refresh pop-up menu, then choose how often to update the calendar.</li>
              </ol>
              <h4>For iPhone</h4>
              <ol>
                <li>Go to Settings &gt; Passwords & Accounts &gt; Add Account &gt; Other.</li>
                <li>Tap Add Subscribed Calendar.</li>
                <li>Enter the calendar&apos;s web address to subscribe to and any other required server information.</li>
              </ol>
              <h4>For Google Calendar (on Web)</h4>
              <ol>
                <li>
                  On your computer, open <a href="https://calendar.google.com/">Google Calendar</a>.
                </li>
                <li>On the left, next to &quot;Other calendars,&quot; click Add From URL.</li>
                <li>Enter the calendar&apos;s web address in the field provided.</li>
                <li>Click Add calendar.</li>
                <li>Copy the iCal feed address.</li>
              </ol>
              <h4>For Android</h4>
              <ol>
                <li>Follow the instructions for Google Calendar, from a computer.</li>
                <li>On your phone, open the Google Calendar</li>
                <li>Open the menu, and then go to Settings.</li>
                <li>Scroll to the calendar email where you added the new iCal feed</li>
                <li>Tap on Show more.</li>
                <li>Tap on your new calendar&apos;s name.</li>
                <li>Switch the Sync option on.</li>
                <li>You may also change your notifications for this calendar on this screen.</li>
              </ol>
              <h4>For Outlook.com</h4>
              <ol>
                <li>
                  Sign in to <a href="http://outlook.com/">Outlook.com</a>
                </li>
                <li>At the bottom of the page, select the calendar icon. </li>
                <li>In the navigation pane, select Import calendar. </li>
                <li>Under Import calendar, select From the web. Under Link to the calendar, copy the calendar&apos;s web address. </li>
                <li>Select Import.</li>
              </ol>
            </HelpPanel>
          )}
          <div className="calendar card__body profile-form__card">
            <Calendar profile={profile} />
          </div>
        </div>
      </Card>
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(inject('stores')(observer(ProfileCalendar)));
