/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import SectionContext from './SectionContext';

const withSection = Component => {
  class withSection extends React.Component {
    render() {
      return (
        <SectionContext.Consumer>
          {availableProfileSections => (availableProfileSections ? <Component {...this.props} availableProfileSections={availableProfileSections} /> : null)}
        </SectionContext.Consumer>
      );
    }
  }

  return withSection;
};

export default withSection;
