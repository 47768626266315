/* eslint-disable react/prop-types */
import React, {useCallback} from 'react';

import {Card, HelpPanel, VimeoEmbed} from '../common';
import InviteTeamMember from '../invite/card.invite';
import routes from '../../constants/routes';

import '../../assets/scss/components/onboarding/team-onboard.scss';

function OnboardTeam({profile, history, onboard}) {
  const onBackToOnboard = useCallback(() => {
    // eslint-disable-next-line react/prop-types
    history.push(routes.generate(routes.profile.onboard.intro, {profileId: profile.id}));
  }, [profile, history]);

  return (
    <div className="container">
      <Card help full>
        <div className="card__content">
          <HelpPanel defaultsOpen>
            <h3>Help adding your team</h3>
            <p>The people on your team include: doctors, therapists, educators, support workers, family, and friends.</p>
            <p>You can invite who you want, and set their permissions. You can also change the team whenever you need to.</p>
            <VimeoEmbed videoId="344929775" videoName="HOW TO ADD TEAM" />
          </HelpPanel>
          <div className="card__body team-onboard">
            <InviteTeamMember profile={profile} onboard={onboard} heading={false} onCancel={onBackToOnboard} onInvited={onBackToOnboard} />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default OnboardTeam;
