/* eslint-disable react/prop-types */
import React from 'react';
import {ModalImage, Button} from '../common';

import endingSrc from '../../assets/svg/technology.svg';

function ModalExpiring({onDismiss, isOpen, onSubscribe}) {
  return (
    <ModalImage type="primary" src={endingSrc} isOpen={isOpen} alt="Dancing Aliens" handleDismiss={onDismiss}>
      <h3>Your trial is almost over</h3>
      <p>
        Phew... the last few days have flown by. Unfortunately this means your free trial is nearly over. We hope you’re finding Sameview useful and want to
        keep us on your team.
      </p>
      <p>
        You can subscribe to sameview at anytime during or after your trial. Just click the button below or head over to your account settings for further
        details.
      </p>
      <Button right type="primary" size="medium" onClick={onSubscribe}>
        Subscribe
      </Button>
    </ModalImage>
  );
}

export default ModalExpiring;
