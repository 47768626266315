/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import {Dialog} from '@material-ui/core';
import {Heading} from '../common';

import BillingPaymentForm from './billing.payment.form';

function BillingModal({onDismiss, isOpen, stripeCard, onSubscriptionSubmit, setModalOpen, onCreditCardChange, onAddressChange, onInputChange}) {
  return (
    <Dialog scroll="body" fullWidth maxWidth="md" open={!!isOpen} onClose={onDismiss} className="modal__billing">
      <Heading primary modal>
        <h4>Edit Billing</h4>
      </Heading>
      <BillingPaymentForm
        onAddressChange={onAddressChange}
        onCreditCardChange={onCreditCardChange}
        onInputChange={onInputChange}
        onSubscriptionSubmit={onSubscriptionSubmit}
        setModalOpen={setModalOpen}
        stripeCard={stripeCard}
      />
    </Dialog>
  );
}

BillingModal.propTypes = {
  stripeCard: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      line1: PropTypes.string,
      line2: PropTypes.string,
      postal_code: PropTypes.string,
      state: PropTypes.string,
    }),
  }),
  isOpen: PropTypes.bool,
  onAddressChange: PropTypes.func,
  onCreditCardChange: PropTypes.func,
  onDismiss: PropTypes.func,
  onInputChange: PropTypes.func,
  onSubscriptionSubmit: PropTypes.func,
  setModalOpen: PropTypes.func,
};

BillingModal.defaultProps = {
  stripeCard: {
    name: '',
    email: '',
    address: {
      city: '',
      line1: '',
      line2: '',
      postal_code: '',
      state: '',
    },
  },
  isOpen: false,
  onAddressChange: () => {},
  onCreditCardChange: () => {},
  onDismiss: () => {},
  onInputChange: () => {},
  onSubscriptionSubmit: () => {},
  setModalOpen: () => {},
};

export default BillingModal;
