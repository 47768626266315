import React from 'react';

function Privacy() {
  return (
    <div>
      <h4>Sameview Website Privacy Policy</h4>
      <p>
        {`Same View Pty Ltd, trading as sameview, ABN 63 616 065 868 (sameview, we, our or us) is committed to protecting your privacy. We are bound by the
        Privacy Act 1988 (Cth), including the Australian Privacy Principles (APPs) and have set out in this Privacy Policy how we collect, hold, use, and
        disclose your personal information when you use the sameview website (this website or the website, the homepage of which can be accessed via the URL
        www.sameview.com.au) or sameview's software application (App). This Privacy Policy also explains how you can access and correct your personal
        information, and make a complaint about your privacy.`}
      </p>
      <p>
        {`"Personal information" is information or an opinion (in a recorded form or otherwise) about an identified, or reasonably identifiable, individual. This
        includes an individual's name, date of birth and contact details.`}
      </p>

      <h4>INFORMATION WE COLLECT</h4>
      <p>
        When you register with sameview, we collect your information that may personally identify you, such as an email address, payment information, current
        location (if the location service is enabled in the App), and other information that you upload on to your App account. We also collect the information
        in the chat messages on your App account. All messages are automatically deleted from your App account and our system once your account is closed.
      </p>

      <h4>OUR PURPOSES FOR HANDLING PERSONAL INFORMATION</h4>
      <p>We collect, hold, use and disclose personal information:</p>
      <p>to provide the App, including troubleshooting, technical support and handling complaints;</p>
      <p>to allow users to share and view their information;</p>
      <p>to contact users of the App;</p>
      <p>to maintain, protect, improve and promote the App, or to develop new products or services;</p>
      <p>to provide users with information about new products and services that may be of interest to them;</p>
      <p>to generate data for internal analysis;</p>
      <p>for any purpose required or authorised by law; and</p>
      <p>to otherwise run our business.</p>

      <h4>DISCLOSURES</h4>
      <p>
        As a general policy, we use information we collect for internal purposes only. We may disclose the information we collect about you to our service
        providers, our consultants and advisors, regulatory bodies and government authorities as required or authorised by law. We do not store or disclose your
        information overseas.
      </p>
      <p>
        We never sell your personal information. We will not disclose information to third parties without your consent except as explained in this Privacy
        Policy. If we disclose your personal information to our service providers, we only disclose what is necessary for them to provide their service to us,
        and we ensure that they have satisfactory privacy safeguards.
      </p>

      <h4>SECURITY OF PERSONAL INFORMATION</h4>
      <p>
        sameview is committed to protecting the security of your information.We employ security measures designed to protect your information from misuse,
        interference and loss and unauthorised access, modification and disclosure.
      </p>
      <p>
        {`The sameview app is hosted "in the cloud" using cloud infrastructure provided by Amazon Web Services.sameview data is encrypted at rest(on the server)
        and encrypted in transport by Secure Socket Layer(SSL) encryption.sameview data, particularly, sameview chat messages are only accessible with an
        account login and password.Messages are automatically deleted from your App account and our system once your account is closed, ensuring that there are
        no large troves of data that can be hacked or otherwise accessed without authorised.`}
      </p>
      <p>
        We maintain other electronic(e - security) measures for the purposes of securing personal information, such as passwords, firewalls and antivirus
        software.We also maintain physical security measures in our buildings and offices.The personal information we hold is treated as confidential and only
        accessed when necessary.When we disclose personal information to others, we seek to ensure that they are bound by obligations of privacy.Where we no
        longer need your personal information or are required to keep a record of it, we will de - identify or destroy the records.
      </p>

      <h4>ACCESSING AND CORRECTING YOUR PERSONAL INFORMATION</h4>
      <p>
        {' '}
        In most cases, all the personal information that we hold about App account holders and their associates will be accessible to those App account holders
        through the App, and can be corrected by them.If you think we may have other personal information that you would like to access or correct, you can
        contact us at info@sameview.com.au.
      </p>
      <p>
        If we refuse to provide you with access to your personal information, we will provide you with written notice stating our reasons.We may seek to recover
        our reasonable costs(if any) for providing you with access to any of the personal information we hold about you.
      </p>

      <h4>MARKETING</h4>
      <p>
        We may use and disclose your personal information collected from when you first register for an App account or through your ongoing use of the App, to
        provide you with information about products and services we think may be of interest to you, even if you are on the Do Not Call Register.
      </p>
      <p>
        You can opt out of receiving marketing communications from us at any time by contacting us at info@sameview.com.au, or through the opt out function in
        any applicable message sent to you.
      </p>

      <h4>COOKIES</h4>
      <p>
        We automatically collect usage information that allows us to collect information regarding how users access and use the App.For example, when you
        download and use the App, we automatically collect information on the type of device you use and its device identifier(or “UDID”).Additionally, each
        time you use the App or use our website, we automatically collect information regarding the type of web browser you use, your operating system, your
        internet service provider, your IP address, pages you view on our website, the time and duration of your visits to the website, messages you send and
        receive, log and contact data, crash logs and other related information relating to your use of the App.We also use cookies to gather information as
        users navigate through our website.
      </p>

      <h4>QUESTIONS AND COMPLAINTS</h4>
      <p>
        If you have any questions or you believe that we have not complied with our obligations under the Privacy Act, or you believe that the information we
        hold about you is incorrect or has been compromised, please contact us in writing at info@sameview.com.au.
      </p>
      <p>
        We will respond to any question or complaint as soon as possible.If you do not agree with or are dissatisfied with our response, you can contact the
        Office of the Australian Information Commissioner at enquiries@oaic.gov.au or on 1300 363 992 to lodge a complaint.
      </p>

      <h4>CHANGES TO THIS PRIVACY POLICY</h4>
      <p>
        We may make changes to this Privacy Policy from time to time without notice to you.Updated policies will be posted on our website on
        www.sameview.com.au.This Privacy Policy is current as of 30 / 11 / 2018.
      </p>
    </div>
  );
}

export default Privacy;
