/* eslint-disable react/prop-types */
import React, {useEffect, useState, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {isEqual, debounce} from 'lodash';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import routes from '../../constants/routes';

import getIsOwner from '../../helpers/getIsOwner';
import GoalSteps from './goal.steps';
import {db} from '../../firebase';

function GoalDetail({profileId, profile, goalId, goal}) {
  const isOwner = getIsOwner(profile);
  const [steps, setSteps] = useState(goal?.steps ?? []);
  const [listItems, setListItems] = useState([]);

  const fetchCounts = useCallback(async (fetchProfileId, fetchSteps) => {
    const starCounts = {};
    const stepsKeys = Object.keys(fetchSteps);
    const stepIds = Object.entries(fetchSteps).map(([key, step]) =>
      typeof key === 'string' && key.length > 6 && key !== step.id ? [key, step.id] : [step.id]
    );

    const counts = await Promise.all(stepIds.map(stepId => db.getStepStarCount(fetchProfileId, stepId)));
    // eslint-disable-next-line no-return-assign, fp/no-mutation
    stepsKeys.forEach((stepId, ix) => (starCounts[stepId] = counts[ix]));

    // eslint-disable-next-line fp/no-mutating-methods
    const fetchedListItems = stepsKeys.map((id, index) => ({...fetchSteps[id], id, count: counts[index]})).sort((a, b) => a.sort - b.sort);
    setListItems(prev => (prev.length && !fetchedListItems.length ? prev : fetchedListItems));
  }, []);

  const handleStepToggleComplete = useCallback(
    (completedGoalId, stepId) => {
      // eslint-disable-next-line fp/no-mutation
      steps[stepId].complete = !steps[stepId].complete;
      if (goal.complete) {
        db.saveGoal(profileId, completedGoalId, {complete: false});
      }
      db.updateStep(profileId, completedGoalId, stepId, {complete: steps[stepId].complete});
      setSteps(steps);
    },
    [profileId, steps, goal]
  );

  const handleListItemsChange = debounce(changedSteps => {
    if (!isEqual(listItems, changedSteps)) {
      changedSteps.map((step, index) => {
        db.updateStep(profileId, goalId, step.id, {sort: index});
        return null;
      });
    }
  }, 1000);

  useEffect(() => {
    fetchCounts(profileId, steps);
  }, [fetchCounts, profileId, steps]);

  if (!goal) return null;

  return (
    <div className="goal-details">
      <div className="goal-header">
        <div className="title">
          <h3>{goal.name}</h3>
        </div>
        <div className="description">
          <p>{goal.description}</p>
        </div>
        {isOwner && (
          <Link
            to={routes.generate(routes.profile.goal.edit, {
              profileId,
              goalId,
            })}
            className="button -tertiary -tiny">
            Edit goal and steps
          </Link>
        )}
      </div>
      <h4 className="goal-steps-header">Steps</h4>
      <div className="goal-steps">
        <DndProvider backend={HTML5Backend}>
          {listItems.length > 0 && (
            <GoalSteps goalId={goalId} listItems={listItems} onToggleComplete={handleStepToggleComplete} onListItemsChange={handleListItemsChange} />
          )}
        </DndProvider>
      </div>
    </div>
  );
}

export default GoalDetail;
