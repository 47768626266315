/* eslint-disable react/prop-types */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import BoxMUI from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import {Form} from 'informed';

import PermissionsSection from '../form/form.permissions';
import {Button, Field} from '../common';
import {validateEmail, validateRequired} from '../common/validate';

import '../../assets/scss/components/profile/profile-completion.onboarding.scss';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  added: {
    backgroundColor: '#ffffff',
    padding: 16,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chip: {
    borderWidth: 1,
    borderColor: '#40BDAF',
    backgroundColor: 'rgba(107, 214, 202, 0.15)',
    margin: theme.spacing(1),
    color: '#40BDAF',
  },
}));

function Box({label = '', value, callback, onClick, placeholder, defaultPermissions}) {
  const isChecked = useMemo(() => value.length > 0, [value]);
  const handleClick = useCallback(() => {
    onClick(label, value, callback, placeholder, defaultPermissions);
  }, [callback, label, value, onClick, placeholder, defaultPermissions]);
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={handleClick} className={`profile-completion__box ${isChecked ? 'profile-completion__checked' : ''}`}>
      <h4>+</h4>
      <h5>
        {label}
        {isChecked ? ` (${value.length})` : ''}
      </h5>
    </div>
  );
}

function InviteUsers({onFinishInviting, name}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [parents, setParents] = useState([]);
  const [grandparents, setGrandparents] = useState([]);
  const [siblings, setSiblings] = useState([]);
  const [otherFamily, setOtherFamily] = useState([]);
  const [supportCoordinator, setSupportCoordinator] = useState([]);
  const [alliedHealth, setAlliedHealth] = useState([]);
  const [gp, setGp] = useState([]);
  const [supportWorker, setSupportWorker] = useState([]);
  const [educator, setEducator] = useState([]);
  const [caseManager, setCaseManager] = useState([]);
  const [specialist, setSpecialist] = useState([]);
  const [otherProfessional, setOtherProfessional] = useState([]);

  const [added, setAdded] = useState([]);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(
      parents.length === 0 &&
        grandparents.length === 0 &&
        siblings.length === 0 &&
        otherFamily.length === 0 &&
        supportCoordinator.length === 0 &&
        alliedHealth.length === 0 &&
        gp.length === 0 &&
        supportWorker.length === 0 &&
        educator.length === 0 &&
        caseManager.length === 0 &&
        specialist.length === 0 &&
        otherProfessional.length === 0
    );
  }, [parents, grandparents, siblings, otherFamily, supportCoordinator, alliedHealth, gp, supportWorker, educator, caseManager, specialist, otherProfessional]);

  const handleOpenModal = useCallback((label, value, callback, placeholder, defaultPermissions) => {
    setShow({
      label,
      callback,
      placeholder,
      defaultPermissions,
    });
    setAdded(value);
    setOpen(true);
  }, []);

  const handleSubmit = useCallback(
    newItem => {
      if (!show || !show.callback) {
        return;
      }
      show.callback(prev => {
        setAdded([...prev, {...newItem}]);
        return [...prev, {...newItem}];
      });
      setOpen(false);
    },
    [show]
  );

  const handleCloseModal = useCallback(() => setOpen(false), []);

  const chips = useMemo(() => {
    if (!added) return [];
    return added.map(one => ({
      ...one,
      label: `${one.first_name} ${one.last_name}`,
      onRemove: () => {
        let filteredList = [];
        show.callback(prev => {
          // eslint-disable-next-line fp/no-mutation
          filteredList = prev.filter(old => one.email !== old.email);
          return filteredList;
        });
        setAdded(filteredList);
      },
    }));
  }, [added, show]);

  const modal = useMemo(
    () =>
      !show || !open ? null : (
        <Modal open={open} onClose={handleCloseModal}>
          <div className={classes.paper}>
            <h2 className="profile-completion__modal-title">{`${show?.label || ''}'s details`}</h2>
            <Form
              render={() => (
                <div>
                  <div className="profile-completion__name-container">
                    <Field
                      prefix="invite-form"
                      label="First Name"
                      field="first_name"
                      wrapperClassName="invite-form__name"
                      required
                      validate={validateRequired}
                      validateOnChange
                    />
                    <div />
                    <Field
                      prefix="invite-form"
                      label="Last Name"
                      field="last_name"
                      wrapperClassName="invite-form__name"
                      required
                      validate={validateRequired}
                      validateOnChange
                    />
                  </div>
                  <Field prefix="invite-form" label="Email" field="email" placeholder="Email address" required validate={validateEmail} validateOnChange />

                  <Field
                    prefix="invite-form"
                    label={`Relationship to ${name}:`}
                    field="relationship"
                    wrapperClassName="invite-form__relastionship"
                    required
                    validate={validateRequired}
                    validateOnChange
                    placeholder={show?.placeholder || ''}
                  />

                  <PermissionsSection field="permissions" prefix="invite-form" />
                  <Button type="primary" submit right>
                    Add to team
                  </Button>
                  {chips && chips.length > 0 && (
                    <BoxMUI className="profile-completion__chip-container">
                      Added:{' '}
                      {chips.map(one => (
                        <Chip className={classes.chip} onDelete={one.onRemove} label={one.label} key={one.email} />
                      ))}
                    </BoxMUI>
                  )}
                </div>
              )}
              initialValues={{permissions: show.defaultPermissions, message: ''}}
              onSubmit={handleSubmit}
            />
          </div>
        </Modal>
      ),
    [open, show, handleCloseModal, chips, classes, handleSubmit, name]
  );

  const handleSkip = useCallback(() => onFinishInviting([]), [onFinishInviting]);
  const handleNext = useCallback(() => {
    const users = [];
    const addUsers = (array = [], type = '') => {
      array.forEach(user => {
        // eslint-disable-next-line fp/no-mutating-methods
        users.push({...user, type});
      });
    };
    addUsers(parents, 'individual');
    addUsers(grandparents, 'individual');
    addUsers(siblings, 'individual');
    addUsers(otherFamily, 'individual');
    addUsers(supportCoordinator, 'professional');
    addUsers(alliedHealth, 'professional');
    addUsers(gp, 'professional');
    addUsers(supportWorker, 'professional');
    addUsers(educator, 'professional');
    addUsers(caseManager, 'professional');
    addUsers(specialist, 'professional');
    addUsers(otherProfessional, 'professional');
    onFinishInviting(users);
  }, [
    onFinishInviting,
    parents,
    grandparents,
    siblings,
    otherFamily,
    supportCoordinator,
    alliedHealth,
    gp,
    supportWorker,
    educator,
    caseManager,
    specialist,
    otherProfessional,
  ]);

  return (
    <div>
      <h3 className="user-onboard__heading">{`Let's add ${name}'s team members!`}</h3>
      <ol className="profile-completion">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>Family</label>
        <div>
          <Box
            defaultPermissions="owner"
            label="Parents / Primary Carers"
            placeholder="Eg. Mother, Father"
            value={parents}
            callback={setParents}
            onClick={handleOpenModal}
          />
          <Box
            defaultPermissions="owner"
            label="Grandparents"
            placeholder="Eg. Grandmother, Grandfather, Nana, "
            value={grandparents}
            callback={setGrandparents}
            onClick={handleOpenModal}
          />
          <Box
            defaultPermissions="owner"
            label="Siblings"
            placeholder="Eg. Brother, Sister"
            value={siblings}
            callback={setSiblings}
            onClick={handleOpenModal}
          />
          <Box
            defaultPermissions="owner"
            label="Other"
            placeholder="Eg. Mother-In-Law, Uncle"
            value={otherFamily}
            callback={setOtherFamily}
            onClick={handleOpenModal}
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>Supports</label>
        <div>
          <Box
            defaultPermissions="admin"
            label="Support Coordinator"
            placeholder="Eg. Community Services Coordinator, Health & Disability Support Coordinator"
            value={supportCoordinator}
            callback={setSupportCoordinator}
            onClick={handleOpenModal}
          />
          <Box
            defaultPermissions="admin"
            label="Allied Health"
            placeholder="Eg Allied Health, Allied Health Assistant"
            value={alliedHealth}
            callback={setAlliedHealth}
            onClick={handleOpenModal}
          />
          <Box defaultPermissions="admin" label="GP" value={gp} placeholder="Eg GP, Family Doctor" callback={setGp} onClick={handleOpenModal} />
          <Box
            defaultPermissions="admin"
            label="Support Worker"
            placeholder="Eg Support Worker, Family Support Worker"
            value={supportWorker}
            callback={setSupportWorker}
            onClick={handleOpenModal}
          />
          <Box
            defaultPermissions="admin"
            label="Educator"
            placeholder="Eg Teacher, Programme Coordinator, Early Childhood Educator"
            value={educator}
            callback={setEducator}
            onClick={handleOpenModal}
          />
          <Box
            defaultPermissions="admin"
            label="Case Manager"
            placeholder="Eg Case Manager, Service Case Manager"
            value={caseManager}
            callback={setCaseManager}
            onClick={handleOpenModal}
          />
          <Box
            defaultPermissions="admin"
            label="Specialist"
            placeholder="Eg. Cardiologists, Endocrinologists, Family Physicians"
            value={specialist}
            callback={setSpecialist}
            onClick={handleOpenModal}
          />
          <Box
            defaultPermissions="admin"
            label="Other"
            placeholder="Other"
            value={otherProfessional}
            callback={setOtherProfessional}
            onClick={handleOpenModal}
          />
        </div>
        {modal}
      </ol>
      <BoxMUI flexDirection="row" display="flex" justifyContent="space-between">
        <Button type="link" onClick={handleSkip}>
          Skip this step
        </Button>
        <Button type="primary" right onClick={handleNext} disabled={disabled}>
          Next
        </Button>
      </BoxMUI>
    </div>
  );
}

export default InviteUsers;
