/* eslint-disable react/prop-types */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Text from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ButtonMUI from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/dayjs';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

import dayjs from 'dayjs';
import {Button} from '../common';

import '../../assets/scss/components/profile/profile-completion.onboarding.scss';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  boxStyle: {
    padding: 18,
    backgroundColor: '#F5F6F7',
    borderRadius: 8,
  },
  textInputContainerStyle: {
    borderRadius: 16,
    marginTop: 6,
    marginBottom: 16,
  },
  textInputStyle: {
    backgroundColor: 'white',
  },
  textStyle: {
    borderWidth: 1,
  },
  buttonStyle: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: 12,
    padding: '6px 24px',
  },
  selectedButtonStyle: {
    backgroundColor: '#6BD4C9',
    color: 'white',
    pointerEvents: 'none',
  },
  dateSpaceStyle: {
    width: 32,
  },
  dateContainerStyle: {},
  dateStyle: {
    backgroundColor: 'white',
  },
  stepStyle: {
    backgroundColor: '#6BD6CA',
    borderWidth: 2,
    width: 26,
    height: 26,
    borderRadius: 13,
    borderColor: 'black',
    borderStyle: 'solid',
    alignItems: 'center',
    textAlign: 'center',
    marginRight: 12,
  },
  stepContainerStyle: {
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: 6,
  },
}));

function GoalStep({goal, step}) {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="row" className={classes.stepContainerStyle}>
      <Text className={classes.stepStyle}>{step}</Text>
      {/* <Text className={classes.text}>{goal}</Text> */}
      <TextField disabled label="" variant="outlined" fullWidth className={classes.textInputStyle} value={goal} contentEditable={false} />
    </Box>
  );
}

function InviteUsers({onCompleteGoal}) {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState('Get started in sameview 🎉🎉');
  const [description, setDescription] = useState('We want to complete our sameview profile setup');
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [saving, setSaving] = useState(false);

  const handleSkip = useCallback(() => onCompleteGoal(undefined), [onCompleteGoal]);
  const onCreateGoal = useCallback(async () => {
    setSaving(true);
    await onCompleteGoal({
      name,
      steps: [
        {complete: true, text: 'Invite team to sameview'},
        {complete: true, text: 'Share a welcome post'},
      ],
    });
    setSaving(false);
  }, [onCompleteGoal, name]);

  useEffect(() => {
    setDisabled(name.length === 0 || dayjs(start).isAfter(end, 'd'));
  }, [name, start, end]);

  const handleChangeBody = useCallback(e => setName(e.target.value), []);
  const handleChangeDescription = useCallback(e => setDescription(e.target.value), []);

  const shortButtonStyle = useMemo(() => `${classes.buttonStyle} ${classes.selectedButtonStyle}`, [classes.buttonStyle, classes.selectedButtonStyle]);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <h3 className="user-onboard__heading">We’ve created your first goal for you!</h3>
        <div className="user-onboard__section">
          <Box className={classes.boxStyle}>
            <Text variant="subtitle2" className={classes.textStyle}>
              Create a goal
            </Text>
            <Box className={classes.textInputContainerStyle}>
              <TextField disabled className={classes.textInputStyle} label="" variant="outlined" fullWidth value={name} onChange={handleChangeBody} />
            </Box>
            <Text variant="subtitle2" className={classes.textStyle}>
              Time Frame
            </Text>
            <Box flexDirection="row" display="flex" className={classes.dateContainerStyle}>
              <KeyboardDatePicker
                disabled
                disablePast
                className={classes.dateStyle}
                disableToolbar
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={start}
                onChange={setStart}
              />
              <Box className={classes.dateSpaceStyle} />
              <KeyboardDatePicker
                minDate={start}
                disabled
                className={classes.dateStyle}
                disableToolbar
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={end}
                onChange={setEnd}
              />
            </Box>
            <Box mt={2}>
              <Text variant="subtitle2" className={classes.textStyle}>
                Describe your goal
              </Text>
            </Box>
            <Box className={classes.textInputContainerStyle}>
              <TextField
                disabled
                label=""
                variant="outlined"
                fullWidth
                className={classes.textInputStyle}
                value={description}
                onChange={handleChangeDescription}
                multiline
                contentEditable={false}
              />
            </Box>
            <Text variant="subtitle2" className={classes.textStyle}>
              Goal Type*
            </Text>
            <Box flexDirection="row" display="flex" justifyContent="space-between" mb={3} mt={1}>
              <ButtonMUI size="small" variant="contained" className={shortButtonStyle}>
                Short Term
              </ButtonMUI>
              <ButtonMUI disabled size="small" variant="contained" className={classes.buttonStyle}>
                Long Term
              </ButtonMUI>
              <ButtonMUI disabled size="small" variant="contained" className={classes.buttonStyle}>
                Family Goal
              </ButtonMUI>
            </Box>
            <Text variant="subtitle2" className={classes.textStyle}>
              Steps to achieve goal:
            </Text>
            <Box mt={2}>
              <GoalStep goal="Invite team to sameview" step={1} />
              <GoalStep goal="Share a welcome post" step={2} />
            </Box>
          </Box>
        </div>
      </div>
      <div className="user-onboard__section">
        <Box flexDirection="row" display="flex" justifyContent="space-between">
          <Button type="link" onClick={handleSkip} disabled={saving}>
            Skip this step
          </Button>
          <Button type="primary" right onClick={onCreateGoal} disabled={disabled || saving} loading={saving}>
            Complete goal
          </Button>
        </Box>
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default InviteUsers;
