import React from 'react';
import PropType from 'prop-types';

export default function PostPinButton({active, className, disabled, onPinToggle}) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div className={`item -pin ${className ?? ''} ${active && !disabled ? 'active' : ''} ${disabled ? 'disabled' : ''}`} onClick={onPinToggle}>
      <i className="icon-bookmark" />
    </div>
  );
}

PostPinButton.propTypes = {
  active: PropType.bool,
  disabled: PropType.bool,
  className: PropType.string,
  onPinToggle: PropType.func.isRequired,
};

PostPinButton.defaultProps = {
  active: false,
  className: '',
  disabled: false,
};
