/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {Scope, Checkbox, useFormState, useFormApi, useFieldState} from 'informed';

import {Button, Field, DateField, FieldCountrySelect} from '../common';
import {usePreventDefault} from '../../hooks';
import {validateEmail, validateRequired, validateTerms, validatePassword, validateMobile} from '../common/validate';

import '../../assets/scss/components/signup/signup-form.scss';

function SignUpForm({saving, onCancel, onStep, ...props}) {
  const [section, setSection] = useState(0);
  const {validate, getState} = useFormApi();
  const {invalid} = useFormState();
  const {value: profileFirstname} = useFieldState('profile.firstname') ?? {};
  const {error: termsError} = useFieldState('terms-conditions') ?? {};

  const onProfileBack = usePreventDefault(() => {
    setSection(0);
    onStep(0);
  }, [setSection, onStep]);

  const onAccountNext = usePreventDefault(() => {
    validate();
    if (!getState().invalid) {
      setSection(1);
      onStep(1);
    }
  }, [setSection, onStep, validate, getState]);

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        onAccountNext(event);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [onAccountNext]);

  return (
    <div className="signup-form">
      <div className="signup-form__account" style={{display: section === 0 ? 'flex' : 'none'}}>
        <Field
          prefix="signup-form"
          label="First Name"
          field="firstname"
          placeholder="First Name"
          wrapperClassName="signup-form__name"
          validate={validateRequired}
          validateOnChange
        />

        <Field
          prefix="signup-form"
          label="Last Name"
          field="lastname"
          placeholder="Last Name"
          wrapperClassName="signup-form__name"
          validate={validateRequired}
          validateOnChange
        />

        <Field prefix="signup-form" label="Mobile" field="mobile" placeholder="Mobile Number" validate={validateMobile} validateOnChange />

        <Field prefix="signup-form" label="Email" field="email" placeholder="Email address" validate={validateEmail} validateOnChange />

        <Field prefix="signup-form" type="password" label="Password" field="password" placeholder="Password" validate={validatePassword} validateOnChange />

        <Button left type="tertiary" onClick={onCancel}>
          Back
        </Button>
        <Button right type="primary" onClick={onAccountNext} disabled={invalid}>
          Next
        </Button>
      </div>
      {section > 0 && (
        <div className="signup-form__profile">
          <Scope scope="profile">
            <h4 className="signup-form__heading">I am looking after...</h4>

            <Field
              prefix="signup-form"
              label="First Name"
              field="firstname"
              placeholder="First Name"
              wrapperClassName="signup-form__name"
              validate={validateRequired}
              validateOnChange
            />

            <Field
              prefix="signup-form"
              label="Last Name"
              field="lastname"
              placeholder="Last Name"
              wrapperClassName="signup-form__name"
              validate={validateRequired}
              validateOnChange
            />

            <DateField
              prefix="signup-form"
              field="birthdate"
              label="Date of Birth"
              placeholder="DD / MM / YYYY"
              dateFormat="dd/MM/yyyy"
              wrapperClassName="signup-form__date"
              validate={validateRequired}
              validateOnChange
              maxDate={new Date()}
            />

            <Field
              prefix="signup-form"
              field="relationship"
              placeholder="E.g. Mum, Uncle, Grandma"
              label={`Your relationship to ${profileFirstname || 'profile holder'}`}
              wrapperClassName="signup-form__relationship"
              validate={validateRequired}
              validateOnChange
            />

            <FieldCountrySelect
              prefix="signup-form"
              field="country"
              label="Country"
              wrapperClassName="signup-form__country"
              validate={validateRequired}
              validateOnChange
            />

            <Field
              prefix="signup-form"
              field="postcode"
              label="Postcode"
              wrapperClassName="signup-form__postcode"
              validate={validateRequired}
              validateOnChange
            />
          </Scope>

          <div className="signup-form__terms">
            <small className="signup-form__error">{termsError}</small>
            <Checkbox field="terms-conditions" id="terms-conditions" validate={validateTerms} validateOnChange />
            <label className="signup-form__label" htmlFor="terms-conditions">
              <span>
                I agree to the sameview{' '}
                <a href="https://www.sameview.com.au/privacy-policy" onClick={e => props.openPrivacyModal(e)}>
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="https://www.sameview.com.au/terms-of-service" onClick={e => props.openTermsModal(e)}>
                  Terms & Conditions
                </a>
              </span>
            </label>
          </div>

          <Button left type="tertiary" onClick={onProfileBack}>
            Back
          </Button>
          <Button submit right type="primary" loading={saving} disabled={invalid}>
            Next
          </Button>
        </div>
      )}
    </div>
  );
}

export default SignUpForm;
