import React, {useCallback} from 'react';
import {useField} from 'informed';
import classnames from 'classnames';
import {CountryDropdown} from 'react-country-region-selector';

import {getClassNames} from './field';

const FieldCountryselect = props => {
  const {fieldState, fieldApi, render, userProps} = useField(props);

  const {value, touched, error, focused} = fieldState;
  const {setValue, setTouched} = fieldApi;

  const {onChange, prefix, label, wrapperClassName, required, readOnly} = userProps;

  const onCountryChange = useCallback(
    values => {
      setValue(values);
      setTouched(true);
      if (onChange) onChange(values);
    },
    [setValue, setTouched, onChange]
  );

  const isError = touched && !!error;

  const {labelClassName, invalidClassName, errorClassName, focusClassName} = getClassNames(prefix);

  return render(
    <label
      htmlFor="CountryDropdown"
      className={classnames(labelClassName, wrapperClassName, {
        [invalidClassName]: isError,
        [focusClassName]: focused,
      })}>
      {!readOnly && required && <span className={`required ${labelClassName}_required`}> * </span>}
      {label} {isError && <small className={errorClassName}>{error}</small>}
      <div className="select">
        <CountryDropdown value={value} onChange={onCountryChange} priorityOptions={['AU', 'NZ']} />
      </div>
      {props.helpertext && <small>{props.helpertext}</small>}
    </label>
  );
};

export default FieldCountryselect;
