/* eslint-disable react/prop-types */
import {Link} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, {useState, useEffect} from 'react';
import {Card, Heading} from '../common';
import {db} from '../../firebase';
import {getFullName} from '../../helpers/getFullName';
import AccountTabs from './tabs';
import Billing from './billing';
import routes from '../../constants/routes';
import SubscriptionProfiles from './subscription.profiles';
import withAuthorization from '../session/withAuthorization';

function SubscriptionPage({authUser}) {
  const [, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [stripeCustomerID, setStripeCustomerID] = useState(false);
  const [stripe, setStripe] = useState({});

  const [stripeCard, setStripeCard] = useState({
    name: '',
    email: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'AU',
    },
    creditCard: 'XXXX XXXX XXXX XXXX',
    creditExp: 'MM / YY',
    creditCVC: 'CVC',
  });

  const creditCard = stripeCard.last4 ? `XXXX XXXX XXXX ${stripeCard.last4}` : stripeCard.creditCard;
  const creditExpMonth = ((stripeCard.exp_month ? stripeCard.exp_month : stripeCard.creditExp) || '').toString().substr(0, 2).padStart(2, '0');
  const creditExpYear = ((stripeCard.exp_year ? stripeCard.exp_year : stripeCard.creditExp) || '').toString().substr(-2).padStart(2, '0');

  const onAddressChange = event => {
    const {address} = stripeCard;
    console.log(address);
    const nextAddress = {...address};
    // eslint-disable-next-line fp/no-mutation
    nextAddress[event.target.name] = event.target.value;

    setStripeCard({
      ...stripeCard,
      address: nextAddress,
    });
  };

  const onInputChange = event => {
    const nextState = {...stripeCard};
    // eslint-disable-next-line fp/no-mutation
    nextState[event.target.name] = event.target.value;
    setStripeCard(nextState);
  };

  const onCreditCardChange = (event, newStripe) => {
    setStripe(newStripe);
  };

  const onSubscriptionSubmit = async values => {
    if (typeof stripe === 'object' && typeof stripe.createToken === 'function') {
      setSaving(true);

      // Tokenise the credit card with Stripe
      stripe
        .createToken()
        .then(response => {
          if (response.error) {
            NotificationManager.error(response.error.message);
            setSaving(false);
          } else {
            // Save the credit card to the database
            db.updateStripeCustomers(authUser.uid, {
              name: stripeCard.name,
              email: stripeCard.email,
              address: stripeCard.address,
              sources: response.token,
            })
              .then(() => {
                NotificationManager.success('Your billing details have been updated.');
                setSaving(false);
                setModalOpen(false);
              })
              .catch(error => {
                NotificationManager.error(error);
                setSaving(false);
              });
          }
        })
        .catch(error => {
          NotificationManager.error(error);
          setSaving(false);
        });
    } else {
      NotificationManager.error('Payments are not available at this time. Please try again late.');
      setSaving(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(stripeCard.email)) return;

    if (isEmpty(stripeCustomerID) && !!loading) {
      // Fetch the existing billing information from the database
      db.getStripeCustomers(authUser.uid, snapshot => {
        const newStripeCustomer = snapshot.val() || {};
        const newStripeCard = newStripeCustomer && newStripeCustomer.sources && newStripeCustomer.sources.card ? newStripeCustomer.sources.card : {};

        const newStripeCardFormatted = {
          ...stripeCard,
          ...newStripeCard,
          name: newStripeCustomer.name || getFullName(authUser),
          email: newStripeCustomer.email || authUser.email,
          address: newStripeCustomer.address || {},
        };

        // If billing information has been saved previously, set into state.
        if (!isEqual(stripeCard, newStripeCardFormatted)) setStripeCard(newStripeCardFormatted);

        setStripeCustomerID(newStripeCustomer && newStripeCustomer.customer_id ? newStripeCustomer.customer_id : null);
        setLoading(false);

        if (stripeCustomerID === null) NotificationManager.error("You don't seem to have a active credit card.");
      });
    }
  }, [authUser, authUser.uid, loading, setStripeCustomerID, stripeCard, stripeCustomerID]);

  return (
    <Card full>
      <Heading primary>
        <h4>Account Subscriptions</h4>
      </Heading>

      <AccountTabs />

      <section className="section">
        {isEmpty(stripeCustomerID) && (
          <div>
            Please update your <Link to={routes.user.payment}>credit card</Link>. Subscription features will be enabled once completed.
          </div>
        )}
        {!loading && (
          <Billing
            stripeCard={stripeCard}
            creditCard={creditCard}
            creditExpMonth={creditExpMonth}
            creditExpYear={creditExpYear}
            onSubscriptionSubmit={onSubscriptionSubmit}
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            onCreditCardChange={onCreditCardChange}
            onInputChange={onInputChange}
            onAddressChange={onAddressChange}
            canEdit
          />
        )}
      </section>

      <section className="section">
        <h4>Profiles</h4>
        <SubscriptionProfiles
          stripeCustomerID={stripeCustomerID}
          stripeCard={stripeCard}
          creditCard={creditCard}
          creditExpMonth={creditExpMonth}
          creditExpYear={creditExpYear}
          onSubscriptionSubmit={onSubscriptionSubmit}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          onCreditCardChange={onCreditCardChange}
          onInputChange={onInputChange}
          onAddressChange={onAddressChange}
        />
      </section>
    </Card>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(SubscriptionPage);
