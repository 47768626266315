/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
/**
 * Calendar Configuration
 */

import {StringHelper} from '@bryntum/calendar';
import Event from './EventModel';

const eventRenderer = ({eventRecord}) => `
          <span class="b-event-name"><b>${StringHelper.encodeHtml(eventRecord.name)}</b></span>
          <span>${StringHelper.encodeHtml(eventRecord.location)}</span>
        `;

const calendarConfig = (profileId, isMobile, isViewer) => ({
  // CrudManager arranges loading and syncing of data in JSON form from/to a web service
  crudManager: {
    autoLoad: true,
    autoSync: true,
    eventStore: {
      modelClass: Event,
    },
    transport: {
      load: {
        url: `${process.env.REACT_APP_FIREBASE_URL}calendarLoad/${profileId}`,
      },
      sync: {
        url: `${process.env.REACT_APP_FIREBASE_URL}calendarSync/${profileId}`,
      },
    },
  },
  dateFormat: 'D/MM/YYYY',
  readOnly: isViewer,
  // Modes are the views available in the Calendar.
  // An object may be used to configure the view.
  // null means do not include the view.
  mode: isMobile ? 'agenda' : 'week',
  modes: {
    // agenda: null,
    // month: null,
    // year: null,
    day: {
      // hourHeight: 64,
      // These two settings decide what time span is rendered
      dayStartTime: 0,
      dayEndTime: 24,
      // Scroll to 7am initially
      visibleStartTime: 7,
      eventRenderer,
    },
    week: {
      // hourHeight: 64,
      // These two settings decide what time span is rendered
      dayStartTime: 0,
      dayEndTime: 24,
      // Scroll to 7am initially
      visibleStartTime: 7,
      eventRenderer,
    },
    agenda: {
      range: 'week',
      eventRenderer,
    },
  },
  allowOverlap: true,
  // sidebar: {
  //   collapsed: true,
  // },
  eventEditFeature: {
    dateFormat: 'DD/MM/YYYY',
    items: {
      location: {
        type: 'textfield',
        name: 'location',
        label: 'Location',
        weight: 210,
      },
      noteField: {
        type: 'textarea',
        label: 'Notes',
        name: 'note',
      },
    },
  },
});

export default calendarConfig;
