/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import posed, {PoseGroup} from 'react-pose';

import Modal from '../utilities/modal';

const PosedModal = posed(Modal)({
  enter: {scale: 1, opacity: 1},
  exit: {scale: 0.6, opacity: 0},
});

function ModalPopper({isOpen, ...props}) {
  return <PoseGroup>{isOpen && <PosedModal {...props} key="modal" />}</PoseGroup>;
}

export default ModalPopper;
