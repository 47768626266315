/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-mutating-methods */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import {observable, action, decorate} from 'mobx';
import {Map} from 'core-js';

import {db} from '../firebase';
import updateFileUrl from '../helpers/updateFileUrl';

export class PostsStore {
  constructor(mainStore) {
    this.mainStore = mainStore;
  }

  mainStore = null;

  postHandlersBound = {};

  posts = new Map();

  goalPosts = [];

  addPostToStore = (profileId, newPost, isGoalPost) => {
    const existingPost = isGoalPost ? this.getGoalPostById(newPost.id) : this.getPostByProfileIdAndId(profileId, newPost.id);

    if (existingPost) {
      return;
    }

    if (!newPost.likes) {
      newPost.likes = {};
    }
    if (!newPost.comments) {
      newPost.comments = {};
    }

    // Add it to the store
    if (isGoalPost) {
      this.goalPosts.push(newPost);
    } else if (!this.posts.has(profileId)) {
      // This is the first post we're pushing for this profile
      // need to set an observable array
      this.posts.set(profileId, observable([]));
      this.posts.get(profileId).push(newPost);
    } else {
      this.posts.get(profileId).push(newPost);
    }
  };

  getPostByProfileIdAndId = (profileId, id) => {
    if (this.posts.has(profileId)) {
      return this.posts.get(profileId).find(u => u.id === id);
    }
    return null;
  };

  getGoalPostById = id => this.goalPosts.find(u => u.id === id);

  getPostsByProfileId = profileId => this.posts.filter(p => p.profileId === profileId);

  updatePost = (oldPost, newPost) => {
    if (!newPost) return;
    Object.keys(newPost).forEach(key => {
      oldPost[key] = newPost[key];
    });
    if (!newPost.likes) {
      oldPost.likes = {};
    }
    if (!newPost.comments) {
      oldPost.comments = {};
    }
    if (!newPost.goals) {
      oldPost.goals = {};
    }
    if (!newPost.steps) {
      oldPost.steps = {};
    }
  };

  getPostListForProfile = (profileId, numberToFetch = 5) => {
    if (this.postHandlersBound[profileId]) {
      // we've already got handlers for this profile
      return null;
    }

    this.postHandlersBound[profileId] = true;

    db.getProfilePostsReactive(profileId, numberToFetch, snapshot => {
      const postData = snapshot.val();

      updateFileUrl('post', {arrayOfFiles: postData?.uploads, profileId, postId: snapshot.key});

      const post = {
        ...postData,
        id: snapshot.key,
        profileId,
      };

      this.mainStore.usersStore.fetchUsersFromPostData(postData);

      this.addPostToStore(profileId, post);
    });

    db.getProfilePostsDeleteReactive(profileId, numberToFetch, snapshot => {
      const deletedPostId = snapshot.key;
      this.removePostFromStore(profileId, deletedPostId);
    });
    db.getProfilePostChangedReactive(profileId, numberToFetch, snapshot => {
      const post = this.getPostByProfileIdAndId(profileId, snapshot.key);
      if (!post) {
        return;
      }
      this.updatePost(post, snapshot.val());
    });

    return true;
  };

  getPostListForAllProfiles = (numberToFetch = 5) => {
    this.mainStore.profilesStore.profiles.forEach(profile => {
      this.getPostListForProfile(profile.id, numberToFetch);
    });
  };

  loadMorePostsForAllProfiles = (numberToFetch = 5) => {
    // Get the current number of posts for the profile and then add 5 to
    // get the next amount to fetch.
    const currentNumberOfPosts = Array.from(this.posts.values()).reduce((prev, posts) => prev + posts.length, 0);
    if (!currentNumberOfPosts) {
      // Somehow we've called this without any posts for this profile
      // Just do the default case here
      this.getPostListForAllProfiles();
      return;
    }

    // We first need to disable the previous handlers for this profile's posts
    this.mainStore.profilesStore.profiles.forEach(profile => {
      db.removeProfileReactiveHandlers(profile.id);
      this.postHandlersBound[profile.id] = false;
    });

    this.getPostListForAllProfiles(currentNumberOfPosts + numberToFetch);
  };

  loadMorePostsForProfile = (profileId, numberToFetch = 5) => {
    if (!profileId) {
      throw new Error('Must specify profile when loading more posts');
    }

    // Get the current number of posts for the profile and then add 5 to
    // get the next amount to fetch.
    const profilePosts = this.posts.get(profileId);
    if (!profilePosts) {
      // Somehow we've called this without any posts for this profile
      // Just do the default case here
      this.getPostListForProfile(profileId);
      return;
    }

    // We first need to disable the previous handlers for this profile's posts
    db.removeProfileReactiveHandlers(profileId);
    this.postHandlersBound[profileId] = false;

    const currentNumberOfPosts = profilePosts.length;
    this.getPostListForProfile(profileId, currentNumberOfPosts + numberToFetch);
  };

  fetchPost = async (profileId, postId) => {
    const existingPost = this.getPostByProfileIdAndId(profileId, postId);

    if (existingPost) {
      //   console.log("existing post");
      return existingPost;
    }

    const postData = await this.fetchPostFromServer(profileId, postId);

    const post = {
      ...postData,
      profileId,
    };

    this.mainStore.usersStore.fetchUsersFromPostData(postData);

    this.addPostToStore(profileId, post);
    // Make sure to get it here rather than returning 'post' so that
    // we get the mobx observable type
    return this.getPostByProfileIdAndId(profileId, postId);
  };

  fetchPostFromServer = (profileId, postId) => db.getPostById(profileId, postId);

  fetchGoalPosts = (profileId, goalId) => {
    // console.log(goalId);
    db.getGoalPostsReactive(profileId, goalId, snapshot => {
      //   console.log("found this");
      const postData = snapshot.val();

      const post = {
        ...postData,
        id: snapshot.key,
        profileId,
      };

      this.mainStore.usersStore.fetchUsersFromPostData(postData);

      this.addPostToStore(profileId, post, true);
    });

    db.getGoalPostsDeleteReactive(profileId, goalId, snapshot => {
      const deletedPostId = snapshot.key;
      this.removeGoalPost(deletedPostId);
    });
    db.getGoalPostChangedReactive(profileId, goalId, snapshot => {
      //   console.log("handling update goal post");
      const post = this.getGoalPostById(snapshot.key);
      if (!post) {
        // console.log(snapshot.key, snapshot.val());
        return;
      }
      this.updatePost(post, snapshot.val());
    });
  };

  removePostFromStore = (profileId, deletedPostId) => {
    this.posts.set(
      profileId,
      this.posts.get(profileId).filter(p => p.id !== deletedPostId)
    );
  };

  removeGoalPost = deletedPostId => {
    this.goalPosts = this.goalPosts.filter(p => p.id !== deletedPostId);
  };
}

decorate(PostsStore, {
  posts: observable,
  goalPosts: observable,
  addPostToStore: action,
  getPostByProfileIdAndId: action,
  getPostsByProfileId: action,
  updatePost: action,
  removePostFromStore: action,
});

export default PostsStore;
