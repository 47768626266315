/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {NotificationManager} from 'react-notifications';
import {Form} from 'informed';

import {Card, Heading, Footer, HelpPanel, VimeoEmbed, FormDirtyPrompt} from '../common';
import AccountScrollTabs from '../account/tabs_scroll';
import {updateProfile} from '../../firebase/db';
import routes from '../../constants/routes';
import getIsOwner from '../../helpers/getIsOwner';
import ProfileForm from './profile.form';
import ProfileExport from './profile.export';

import '../../assets/scss/components/profile/profile-form.scss';
import EditSections from '../sections/EditSections';

function ProfileAbout({profile, onboard, history, availableProfileSections}) {
  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const formRef = useRef();

  const initialValues = useMemo(() => {
    const obj = {};
    Object.keys(profile.profileSections).forEach(key => {
      if (key === 'id') return;
      // eslint-disable-next-line fp/no-mutation
      obj[key] = {};
      Object.keys(profile.profileSections[key]).forEach(o => {
        // eslint-disable-next-line fp/no-mutation
        obj[key][o] = profile.profileSections[key][o] || '';
      });
    });
    return obj;
  }, [profile.profileSections]);

  const onSubmit = useCallback(
    async values => {
      setSaving(true);
      await updateProfile(profile.id, {...values});
      NotificationManager.success('Profile Updated');
      if (onboard) {
        history.push(
          routes.generate(routes.profile.home, {
            profileId: profile.id,
          })
        );
      } else {
        setSaving(false);
      }
      formRef.current.reset();
    },
    [profile.id, onboard, history]
  );

  const canEdit = useMemo(() => getIsOwner(profile), [profile]);

  return (
    <div className="container">
      <Card help full sections>
        <div className="sticky_top">
          <Heading primary>{`${profile?.profileSections?.essentials?.firstname}'s profile`}</Heading>
          <div className="profile-export">
            <ProfileExport profile={profile} optionalSections={profile.profileSections} availableProfileSections={availableProfileSections} />
          </div>
          <AccountScrollTabs availableProfileSections={availableProfileSections} />
        </div>
        <Form className="profile-form" initialValues={initialValues} onSubmit={onSubmit} getApi={api => (formRef.current = api)}>
          {canEdit && <FormDirtyPrompt isSaving={saving} />}
          <div className="card__content">
            {canEdit && (
              <HelpPanel defaultsOpen={onboard}>
                <h3>Help with setting up your profile</h3>
                <p>
                  Here you can tell the story about you with your profile. You can customise the sections to suit and create a pdf report for sharing outside of
                  sameview.
                </p>
                <VimeoEmbed videoId="344929766" videoName="HOW TO ADD PROFILE" />
              </HelpPanel>
            )}
            <div className="card__body profile-form__card">
              <ProfileForm profile={profile} availableProfileSections={availableProfileSections} />
            </div>
          </div>
          {canEdit && (
            <div className="sticky_bottom">
              <Footer
                saving={saving}
                submitButtonLabel="Save"
                // exportProfile={profile}
                cancelButtonLabel="Add/Edit Sections"
                // hideAddSection={profile.type === 'group'}
                onCancel={e => {
                  e.preventDefault();
                  setOpen(true);
                }}
              />
            </div>
          )}
        </Form>
      </Card>
      <EditSections
        open={open}
        setOpen={setOpen}
        availableProfileSections={availableProfileSections}
        existingSections={profile.profileSections}
        firstname={profile.firstname}
        profileId={profile.id}
      />
    </div>
  );
}

export default observer(ProfileAbout);
