/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-mutating-methods */
/* eslint-disable no-param-reassign */
import {observable, action, decorate} from 'mobx';
import {db} from '../firebase';

// import { toJS } from 'mobx'

export class ProfilesStore {
  constructor(mainStore) {
    this.mainStore = mainStore;
  }

  mainStore = null;

  profiles = [];

  sections = [];

  availableProfileSections = {};

  getProfileById = id => this.profiles.find(u => u.id === id);

  getSectionById = id => this.sections.find(u => u.id === id);

  fetchProfiles = async uid => {
    const availableProfileSections = await db.getAvailableProfileSections();

    this.availableProfileSections = availableProfileSections;

    const profiles = await db.getProfiles(uid, profile => this.updateOrCreateProfile(profile));

    const response = {
      profiles: profiles.forEach(profile => {
        this.updateOrCreateProfile(profile);
      }),
      availableProfileSections: this.availableProfileSections,
    };
    return response;
  };

  static updateProfile = (oldProfile, newProfile) => {
    if (!newProfile) return;
    Object.keys(newProfile).forEach(key => {
      oldProfile[key] = newProfile[key];
    });
    if (('goals' in newProfile && !newProfile.goals) || !oldProfile.goals) {
      oldProfile.goals = {};
    }
    if (('steps' in newProfile && !newProfile.steps) || !oldProfile.steps) {
      oldProfile.steps = {};
    }
    if (('subscription' in newProfile && !newProfile.subscription) || !oldProfile.subscription) {
      oldProfile.subscription = null;
    }
    if (('permissions' in newProfile && !newProfile.permissions) || !oldProfile.permissions) {
      oldProfile.permissions = {};
    }
    if (('permission' in newProfile && !newProfile.permission) || !oldProfile.permission) {
      oldProfile.permission = {};
    }
    if (('team' in newProfile && !newProfile.team) || !oldProfile.team) {
      oldProfile.team = {};
    }
    if (('documents' in newProfile && !newProfile.documents) || !oldProfile.documents) {
      oldProfile.documents = {};
    }
  };

  static updateSection = (oldSection, newSection) => {
    if (!newSection) return;
    Object.keys(newSection).forEach(key => {
      oldSection[key] = newSection[key];
    });
    Object.keys(oldSection).forEach(key => {
      if (!newSection[key]) {
        oldSection[key] = {};
      }
    });
  };

  updateOrCreateProfile = profile => {
    const existingProfile = this.getProfileById(profile.id);
    if (existingProfile) {
      ProfilesStore.updateProfile(existingProfile, profile);
    } else {
      this.profiles.push(profile);
    }
  };

  updateOrCreateSection = section => {
    const existingSection = this.getSectionById(section.id);
    if (existingSection) {
      ProfilesStore.updateSection(existingSection, section);
    } else {
      this.sections.push(section);
    }
  };

  clearProfiles = () => {
    this.profiles = [];
    this.sections = [];
  };
}

decorate(ProfilesStore, {
  profiles: observable,
  sections: observable,
  fetchProfiles: action,
  updateOrCreateProfile: action,
});

export default ProfilesStore;
