const snapshotWithKey = snapshot => {
  if (snapshot.val()) {
    return {
      id: snapshot.key,
      ...snapshot.val(),
    };
  }
  return {};
};

export {snapshotWithKey};
export default snapshotWithKey;
