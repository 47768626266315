/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';

import {getAuth, getMultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier} from 'firebase/auth';
import {PasswordForgotLink} from './password.reset';
import {auth} from '../../firebase';
import routes from '../../constants/routes';
import {getUserById} from '../../firebase/db';

import {Heading, Card, Button} from '../common';

function SignInPage({history}) {
  return (
    <section className="page -account">
      <div className="container">
        <Card focus>
          <Heading secondary>
            <h1>Log In</h1>
          </Heading>
          <SignInForm history={history} />
        </Card>
        <Card focus deemphasise>
          <p style={{textAlign: 'center'}}>
            <PasswordForgotLink />
          </p>
        </Card>
      </div>
    </section>
  );
}

const INITIAL_STATE = {
  email: '',
  password: '',
  code: '',
  error: null,
  loading: false,
  displayCode: false,
};

function SignInForm({history}) {
  const [email, setEmail] = useState(INITIAL_STATE.email);
  const [password, setPassword] = useState(INITIAL_STATE.password);
  const [isInvalid, setIsInvalid] = useState(INITIAL_STATE.password === '' || INITIAL_STATE.email === '');
  const [error, setError] = useState(INITIAL_STATE.error);
  const [loading, setLoading] = useState(INITIAL_STATE.loading);
  const [displayCode, setDisplayCode] = useState(INITIAL_STATE.displayCode);
  const [code, setCode] = useState(INITIAL_STATE.code);
  const [verificationId, setVerificationId] = useState('');
  const [resolver, setResolver] = useState(null);

  useEffect(() => {
    const valid = email !== '' && password !== '';
    setIsInvalid(!valid);
  }, [email, password]);

  const onSubmitCode = async e => {
    try {
      e.preventDefault();
      setLoading(true);
      const cred = PhoneAuthProvider.credential(verificationId, code);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      const result = await resolver.resolveSignIn(multiFactorAssertion);
      const userData = await getUserById(result.user.uid);
      if (Object.entries(userData).length === 0 || userData.onboarding) {
        history.push(routes.user.inviteOnboard.intro);
      } else if (userData.type === 'professional') {
        history.push(routes.professional.clients.onboard.intro);
      } else {
        history.push(routes.home);
      }
    } catch (submitCodeError) {
      let newError = submitCodeError;
      switch (submitCodeError.code) {
        case 'auth/missing-code':
          // eslint-disable-next-line fp/no-mutation
          newError = {message: 'Please enter a verification code.'};
          break;
        case 'auth/invalid-verification-code':
          // eslint-disable-next-line fp/no-mutation
          newError = {message: 'Invalid verification code, please try again.'};
          break;
        default:
          break;
      }
      setError(newError);
      setLoading(false);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    try {
      const result = await auth.doSignInWithEmailAndPassword(email, password);

      setEmail(INITIAL_STATE.email);
      setPassword(INITIAL_STATE.password);
      setIsInvalid(INITIAL_STATE.password === '' || INITIAL_STATE.email === '');
      setError(INITIAL_STATE.error);
      setLoading(INITIAL_STATE.loading);
      setDisplayCode(INITIAL_STATE.displayCode);
      setCode(INITIAL_STATE.code);

      // Check if user has the invited flag, if so send them to the onboarding page instead of home page
      const userData = await getUserById(result.user.uid);
      // If the account doesn't exist or the user is an invite.
      if (Object.entries(userData).length === 0 || userData.onboarding) {
        history.push(routes.user.inviteOnboard.intro);
      } else if (userData.type === 'professional') {
        history.push(routes.professional.clients.onboard.intro);
      } else {
        history.push(routes.home);
      }
    } catch (submitError) {
      if (submitError.code === 'auth/multi-factor-auth-required') {
        const newAuth = getAuth();
        const newResolver = getMultiFactorResolver(newAuth, submitError);
        if (newResolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
          const phoneInfoOptions = {
            multiFactorHint: newResolver.hints[0],
            session: newResolver.session,
          };
          const phoneAuthProvider = new PhoneAuthProvider(newAuth);
          const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-mfa', {size: 'invisible'}, newAuth);
          const newVerificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);

          setDisplayCode(true);
          setLoading(false);
          setVerificationId(newVerificationId);
          setResolver(newResolver);
        }
      } else {
        setError(submitError);
        setLoading(false);
      }
    }
  };

  if (displayCode) {
    return (
      <form onSubmit={onSubmitCode}>
        <section className="section">
          <label htmlFor="code">
            Code
            <input id="code" value={code} onChange={event => setCode(event.target.value)} type="text" placeholder="123456" />
          </label>
          <Button submit right type="primary" disabled={isInvalid} loading={loading}>
            Code entered
          </Button>
        </section>
        {error && (
          <section className="article-errors">
            <div>{error.message}</div>
          </section>
        )}
      </form>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <div id="recaptcha-container-mfa" />
      <section className="section">
        <label htmlFor="email">
          Email Address
          <input id="email" value={email} onChange={event => setEmail(event.target.value)} type="text" autoComplete="username" placeholder="Email Address" />
        </label>
        <label htmlFor="password">
          Password
          <input
            id="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
            type="password"
            autoComplete="current-password"
            placeholder="Password"
          />
        </label>
        <p style={{float: 'left', margin: '8px 0'}}>
          <span>
            {`Don't have an account?`} <Link to={routes.user.sign_up}>Sign Up</Link>
          </span>
        </p>
        <Button submit right type="primary" disabled={isInvalid} loading={loading}>
          Log In
        </Button>
      </section>
      {error && (
        <section className="article-errors">
          <div>{error.message}</div>
        </section>
      )}
    </form>
  );
}

export default withRouter(SignInPage);

export {SignInForm};
