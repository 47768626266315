import React from 'react';
import {Link} from 'react-router-dom';
import routes from '../../constants/routes';

function AccountMenu() {
  return (
    <div>
      <h1>Account Menu</h1>
      <hr />
      <h2>Notifications</h2>
      <p>
        <Link to={routes.user.notifications}>Notifications</Link>
      </p>
      <p>
        <Link to={routes.user.settings}>Notification Settings</Link>
      </p>
      <h2>Account</h2>
      <p>
        <Link to={routes.user.edit}>Edit my account</Link>
      </p>
      <p>
        <Link to={routes.user.password_change}>Change my password</Link>
      </p>
      <h2>Support</h2>
      <p>
        <a href={routes.about.privacy} target="_blank" rel="noopener noreferrer">
          Privacy
        </a>
        <a href={routes.about.terms} target="_blank" rel="noopener noreferrer">
          Terms &amp; Conditions
        </a>
      </p>
      <p>
        <a href={routes.about.accessibility} target="_blank" rel="noopener noreferrer">
          {' '}
          Accessibility{' '}
        </a>
      </p>
      <p>
        <a href={routes.about.faq} target="_blank" rel="noopener noreferrer">
          {' '}
          FAQ{' '}
        </a>
      </p>
      <p>
        <a href={routes.about.help} target="_blank" rel="noopener noreferrer">
          {' '}
          Get help{' '}
        </a>
      </p>
    </div>
  );
}

export default AccountMenu;
