/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import {NotificationManager} from 'react-notifications';
import {CountryDropdown} from 'react-country-region-selector';
import {EmailAuthProvider, reauthenticateWithCredential, updateEmail} from 'firebase/auth';
import {createUUID} from '../../firebase/db';
import routes from '../../constants/routes';
import ImageUpload from '../file-upload/image-upload';
import withAuthorization from '../session/withAuthorization';
import {db} from '../../firebase';
import {Card, Heading, Avatar, Button} from '../common';
import Modal from '../utilities/modal';
import AccountTabs from './tabs';
import PasswordChangeForm from './password.change';
import {auth} from '../../firebase/firebase';

const newSection = {
  title: '',
  body: '',
};

function AccountEdit({authUser, history}) {
  const [openPasswordChange, setOpenPasswordChange] = useState(false);
  const [firstname, setFirstname] = useState(authUser?.firstname || '');
  const [lastname, setLastname] = useState(authUser?.lastname || '');
  const [mobile, setMobile] = useState(authUser?.mobile || '');
  const [description, setDescription] = useState(authUser?.description || '');
  const [sections, setSections] = useState(authUser?.sections || {});
  const [profileImage, setProfileImage] = useState(authUser?.profileImage || '');
  const [profileImagePublic, setProfileImagePublic] = useState(authUser?.profileImagePublic || '');
  const [type] = useState(authUser?.type || '');
  const [organisation, setOrganisation] = useState(authUser?.organisation || {});
  const [jobTitle, setJobTitle] = useState(authUser?.job_title || '');
  const [country, setCountry] = useState(authUser?.country || '');
  const [email, setEmail] = useState(authUser?.email || '');
  const [password, setPassword] = useState('');
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [emailError, setEmailError] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  const addSection = e => {
    e.preventDefault();
    setSections({...sections, [createUUID()]: {...newSection}});
  };

  const removeSection = (e, id) => {
    e.preventDefault();
    const newSections = {...sections};
    // eslint-disable-next-line fp/no-delete
    delete newSections[id];
    setSections(newSections);
  };

  const updateSectionByPropertyName = (propertyName, sectionId, value) => {
    setSections({
      ...sections,
      [sectionId]: {
        ...sections[sectionId],
        [propertyName]: value,
      },
    });
  };

  const onImageUpload = (file, url) => {
    // Get a public url for this file
    if (file) {
      setProfileImage(file.name);
      setProfileImagePublic(url);
    } else {
      setProfileImage('');
      setProfileImagePublic('');
    }
  };

  const removeImage = e => {
    e.preventDefault();
    setProfileImage('');
    setProfileImagePublic('');
  };

  const onSubmit = e => {
    e.preventDefault();
    db.saveUser(authUser.uid, {
      firstname,
      lastname,
      mobile,
      description,
      sections,
      profileImage,
      profileImagePublic,
      type,
      organisation,
      country,
      email,
      job_title: jobTitle,
    });

    NotificationManager.success('Account Updated');
    history.push(routes.user.edit);
  };

  const onChangeEmail = async e => {
    e.preventDefault();
    try {
      setLoadingEmail(true);
      const {currentUser} = auth;
      const cred = EmailAuthProvider.credential(currentUser.email, password);
      await reauthenticateWithCredential(currentUser, cred);
      await updateEmail(currentUser, email);
      await db.saveUser(authUser.uid, {email});
      setPassword('');
      NotificationManager.success('Email Updated');
    } catch (changeEmailError) {
      switch (changeEmailError.code) {
        case 'auth/wrong-password':
          // eslint-disable-next-line fp/no-mutation
          changeEmailError.message = 'Wrong password entered';
          break;
        case 'auth/multi-factor-auth-required':
          // eslint-disable-next-line fp/no-mutation
          changeEmailError.message = 'Please remove your 2FA first to update your email address, after adding you can restore your 2FA';
          break;
        default:
          break;
      }
      setEmailError(error);
    } finally {
      setLoadingEmail(false);
    }
  };

  return (
    <Card full>
      <Heading primary>
        <h4>Your Account</h4>
      </Heading>

      <AccountTabs />

      {/* TODO: Find out why changing email hide the posts in the profile */}
      {false && (
        <form onSubmit={onChangeEmail} className="account-form">
          <section className="section">
            <label htmlFor="email">
              Account Email Address
              <input id="email" value={email} onChange={event => setEmail(event.target.value)} type="text" placeholder="johndoe@example.com" />
            </label>
            <label htmlFor="password">
              Current password
              <input id="password" type="password" autoComplete="password" value={password} onChange={event => setPassword(event.target.value)} />
            </label>
          </section>

          <section className="section">
            <Button submit right type="primary" loading={loadingEmail}>
              Update Account Email
            </Button>

            {emailError && <p>{emailError.message}</p>}
          </section>
        </form>
      )}

      <form onSubmit={onSubmit} className="account-form">
        <section className="section">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>Account Image</label>
          {profileImagePublic ? (
            <div className="image">
              <Avatar className="image" imageClassName="img" src={profileImagePublic} />
              <button type="button" className="button -tertiary -tiny" onClick={removeImage}>
                Remove Image
              </button>
            </div>
          ) : (
            <ImageUpload
              inputId={`edit_account_image_${authUser.uid}`}
              inputName={`edit_account_image_${authUser.uid}`}
              onComplete={onImageUpload}
              value={profileImagePublic}
              filePath={`/uploads/users/${authUser.uid}/`}
            />
          )}
        </section>

        <section className="section">
          <label htmlFor="firstname">
            First name
            <input id="firstname" value={firstname} onChange={event => setFirstname(event.target.value)} type="text" placeholder="First Name" />
          </label>
          <label htmlFor="lastname">
            Last name
            <input id="lastname" value={lastname} onChange={event => setLastname(event.target.value)} type="text" placeholder="Last Name" />
          </label>
          <label htmlFor="mobile">
            Mobile phone
            <input id="mobile" value={mobile} onChange={event => setMobile(event.target.value)} type="text" placeholder="E.g. 021 123 4567" />
          </label>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="description">
            About
            <TextareaAutosize
              id="description"
              value={description}
              onChange={event => setDescription(event.target.value)}
              placeholder="Let people you're connected to on Sameview know a little about yourself"
            />
          </label>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="country">
            Country
            <div className="select">
              <CountryDropdown id="country" value={country} onChange={newCountry => setCountry(newCountry)} priorityOptions={['AU', 'NZ']} />
            </div>
          </label>
        </section>

        {Object.entries(sections).map(([sectionId, section]) => (
          <section className="section -separated" key={sectionId}>
            <label htmlFor="title">
              Section Title
              <input
                id="title"
                value={section.title}
                onChange={event => updateSectionByPropertyName('title', sectionId, event.target.value)}
                type="text"
                placeholder="Title"
              />
            </label>
            <TextareaAutosize value={section.body} onChange={event => updateSectionByPropertyName('body', sectionId, event.target.value)} placeholder="Body" />
            <button type="button" onClick={e => removeSection(e, sectionId)} className="button -tertiary -tiny">
              Remove Section
            </button>
          </section>
        ))}

        <section className="section -centered">
          <button className="button" type="button" onClick={addSection}>
            Add Section
          </button>
        </section>

        {type === 'professional' && (
          <section>
            <h4>Organisation details</h4>
            <label htmlFor="organisation-name">
              Company name
              <input
                id="organisation-name"
                value={organisation.name}
                onChange={event =>
                  setOrganisation({
                    ...organisation,
                    name: event.target.value,
                  })
                }
                type="text"
                placeholder="Organisation name"
              />
            </label>
            <label htmlFor="job-title">
              Job Title
              <input id="job-title" value={jobTitle} onChange={event => setJobTitle(event.target.value)} type="text" placeholder="Your job title" />
            </label>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="address">
              Address
              <TextareaAutosize
                id="address"
                value={organisation.address}
                onChange={event =>
                  setOrganisation({
                    ...organisation,
                    address: event.target.value,
                  })
                }
                type="text"
                placeholder="Organisation address"
              />
            </label>
            <label htmlFor="mobile">
              Work phone
              <input
                id="mobile"
                value={organisation.mobile}
                onChange={event =>
                  setOrganisation({
                    ...organisation,
                    mobile: event.target.value,
                  })
                }
                type="text"
                placeholder="E.g. 021 123 4567"
              />
            </label>
            <label htmlFor="website">
              Website
              <input
                id="website"
                value={organisation.website}
                onChange={event =>
                  setOrganisation({
                    ...organisation,
                    website: event.target.value,
                  })
                }
                type="text"
                placeholder="E.g. https://your.website.com"
              />
            </label>
          </section>
        )}

        <section className="footer-action">
          <button type="button" className="button -tertiary -left" onClick={() => history.push(routes.generate(routes.home))}>
            Close
          </button>
          <button type="submit" className="button -primary -right">
            Save
          </button>
          <button type="button" className="button -primary-light -right" onClick={() => setOpenPasswordChange(true)}>
            Change Password
          </button>
          {error && <p>{error.message}</p>}
        </section>
      </form>

      {openPasswordChange && (
        <Modal handleDismiss={() => setOpenPasswordChange(false)}>
          <PasswordChangeForm onDismiss={() => setOpenPasswordChange(false)} />
        </Modal>
      )}
    </Card>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(AccountEdit);
