import React from 'react';
import {inject, observer} from 'mobx-react';
import withAuthorization from '../session/withAuthorization';

import NotificationIcon from '../notifications';
import ProfilesSelect from '../profile/profiles.select';
import SubscriptionStatus from '../profile/subscription.status';

function StatusBar() {
  return (
    <div className="status-bar">
      <SubscriptionStatus />
      <NotificationIcon />
      <ProfilesSelect />
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(inject('stores')(observer(StatusBar)));
