import React from 'react';

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Toastify() {
  return <ToastContainer position="bottom-left" autoClose={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable />;
}

export default Toastify;
