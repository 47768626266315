const getClassNames = prefix => ({
  labelClassName: `${prefix}__label`,
  fieldClassName: `${prefix}__field`,
  invalidClassName: `${prefix}__invalid`,
  errorClassName: `${prefix}__error`,
  focusClassName: `${prefix}__focus`,
  toggleClassName: `${prefix}__toggle`,
  buttonClassName: `${prefix}__toggle-button`,
  selectedClassName: `${prefix}__toggle-selected`,
});

export default getClassNames;
