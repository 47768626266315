import {getDownloadURL, ref as storageRef} from 'firebase/storage';

/* eslint-disable fp/no-mutation */
const checkImageExists = async (path, storage, times = 15) => {
  let url = null;

  try {
    url = await getDownloadURL(storageRef(storage, path));
    return url;
  } catch (error) {
    if (times > 0) {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise(resolve => setTimeout(resolve, 1000));
      return checkImageExists(path, storage, times - 1);
    }
  }
  return url;
};

export default checkImageExists;
