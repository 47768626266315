import React from 'react';
import {PropTypes} from 'prop-types';

export const iconTypes = [
  'note',
  'cancel-circled',
  'cancel-squared',
  'note-beamed',
  'upload-cloud',
  'music',
  'plus',
  'bookmark',
  'chat',
  'bell',
  'light-down',
  'basket',
  'down',
  'left-dir',
  'left-circled',
  'search',
  'mail',
  'heart',
  'flashlight',
  'heart-empty',
  'star',
  'star-empty',
  'user',
  'users',
  'user-add',
  'video',
  'camera',
  'picture',
  'layout',
  'check',
  'tag',
  'cancel',
  'eye',
  'lock-open',
  'menu',
  'lock',
  'attach',
  'link',
  'home',
  'back',
  'info-circled',
  'info',
  'help-circled',
  'help',
  'minus-circled',
  'minus',
  'plus-squared',
  'plus-circled',
  'minus-squared',
  'bookmarks',
  'flag',
  'thumbs-up',
  'upload',
  'thumbs-down',
  'download',
  'reply',
  'reply-all',
  'forward',
  'quote',
  'code',
  'export',
  'pencil',
  'feather',
  'print',
  'retweet',
  'keyboard',
  'comment',
  'book',
  'book-open',
  'newspaper',
  'doc-text-inv',
  'doc-text',
  'doc-landscape',
  'doc',
  'trash',
  'cup',
  'compass',
  'direction',
  'map',
  'location',
  'address',
  'vcard',
  'alert',
  'attention',
  'folder',
  'archive',
  'box',
  'rss',
  'phone',
  'cog',
  'tools',
  'share',
  'shareable',
  'bag',
  'calendar',
  'login',
  'logout',
  'mic',
  'mute',
  'docs',
  'sound',
  'volume',
  'clock',
  'hourglass',
  'down-open-mini',
  'up-open',
  'right-open',
  'left-open',
  'down-open',
  'right-circled',
  'down-circled',
  'up-circled',
  'arrow-combo',
  'window',
  'publish',
  'popup',
  'resize-small',
  'resize-full',
  'block',
  'adjust',
  'light-up',
  'lamp',
  'left-open-mini',
  'right-open-mini',
  'up-open-mini',
  'down-open-big',
  'left-open-big',
  'right-open-big',
  'arrows-ccw',
  'cw',
  'ccw',
  'up-open-big',
  'level-down',
  'up-thin',
  'left-thin',
  'progress-1',
  'progress-2',
  'right-thin',
  'progress-3',
  'target',
  'palette',
  'list',
  'list-add',
  'signal',
  'level-up',
  'left',
  'shuffle',
  'trophy',
  'loop',
  'right',
  'battery',
  'back-in-time',
  'switch',
  'up',
  'down-dir',
  'play',
  'monitor',
  'mobile',
  'stop',
  'right-dir',
  'pause',
  'network',
  'cd',
  'record',
  'up-dir',
  'down-bold',
  'to-end',
  'inbox',
  'install',
  'to-start',
  'left-bold',
  'right-bold',
  'fast-forward',
  'globe',
  'cloud',
  'fast-backward',
  'down-thin',
  'progress-0',
  'up-bold',
  'cloud-thunder',
  'chart-area',
  'chart-bar',
  'flow-tree',
  'flow-line',
  'vimeo',
  'flickr-circled',
  'lastfm',
  'lastfm-circled',
  'vkontakte',
  'behance',
  'mixi',
  'stumbleupon',
  'stumbleupon-circled',
  'flickr',
  'github-circled',
  'flow-cascade',
  'flow-branch',
  'chart-line',
  'chart-pie',
  'erase',
  'key',
  'github',
  'dribbble-circled',
  'picasa',
  'dribbble',
  'infinity',
  'magnet',
  'bucket',
  'linkedin-circled',
  'paypal',
  'sina-weibo',
  'linkedin',
  'cc-zero',
  'drive',
  'brush',
  'dot-3',
  'database',
  'cc-pd',
  'tumblr-circled',
  'renren',
  'skype-circled',
  'tumblr',
  'cc-nd',
  'megaphone',
  'dot-2',
  'dot',
  'clipboard',
  'cc-sa',
  'pinterest-circled',
  'cc-remix',
  'thermometer',
  'soundcloud',
  'cc-share',
  'google-circles',
  'skype',
  'flattr',
  'cc-nc-jp',
  'cc-nc-eu',
  'cc-nc',
  'cc-by',
  'cc',
  'traffic-cone',
  'gauge',
  'rocket',
  'flow-parallel',
  'vimeo-circled',
  'twitter',
  'twitter-circled',
  'facebook',
  'facebook-circled',
  'facebook-squared',
  'gplus',
  'gplus-circled',
  'pinterest',
  'evernote',
  'dropbox',
  'instagram',
  'qq',
  'spotify-circled',
  'spotify',
  'rdio-circled',
  'rdio',
  'smashing',
  'sweden',
  'db-shape',
  'logo-db',
  'tape',
  'flash',
  'graduation-cap',
  'language',
  'flight',
  'moon',
  'paper-plane',
  'ticket',
  'leaf',
  'lifebuoy',
  'droplet',
  'water',
  'air',
  'mouse',
  'briefcase',
  'credit-card',
  'floppy',
  'suitcase',
];

// eslint-disable-next-line react/prop-types
function Icon({type, size}) {
  return <i className={`icon-${type}`} style={size ? {fontSize: size} : null} />;
}

Icon.propTypes = {
  type: PropTypes.oneOf(iconTypes),
};

Icon.defaultProps = {
  type: 'info',
};

export default Icon;
