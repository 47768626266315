/* eslint-disable fp/no-mutating-methods */
/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import {observer} from 'mobx-react';
import {Section} from 'react-smart-sections';
import {Scope} from 'informed';

import getIsOwner from '../../helpers/getIsOwner';
import ProfileSections from './profile.sections';

import '../../assets/scss/components/profile/profile-form.scss';

function ProfileForm({profile, availableProfileSections}) {
  const canEdit = useMemo(() => getIsOwner(profile), [profile]);

  if (profile.type === 'group') {
    return (
      <>
        {Object.keys(availableProfileSections)
          .sort((a, b) => availableProfileSections[a].index - availableProfileSections[b].index)
          .map(key =>
            (profile.profileSections[key] && !profile.profileSections[key].hiddenSection) || availableProfileSections[key].default ? (
              <Section key={key} name={key} className="profile-form__section">
                <h3>{`Group ${availableProfileSections[key].name}`}</h3>
                <hr />
                <Scope scope={key}>
                  <ProfileSections
                    profile={profile}
                    firstname={profile.firstname}
                    id={profile.id}
                    section={profile.profileSections[key] || {}}
                    questions={availableProfileSections[key]}
                    canEdit={canEdit}
                  />
                </Scope>
              </Section>
            ) : null
          )}
      </>
    );
  }

  return (
    <>
      {Object.keys(availableProfileSections)
        .sort((a, b) => availableProfileSections[a].index - availableProfileSections[b].index)
        .map(key =>
          (profile.profileSections[key] && !profile.profileSections[key].hiddenSection) || availableProfileSections[key].default ? (
            <Section key={key} name={key} className="profile-form__section">
              <h3>
                {availableProfileSections[key].addFirstNameToTitle ? `${profile.firstname}'s ` : ''}
                {availableProfileSections[key].name}
              </h3>
              <hr />
              <Scope scope={key}>
                <ProfileSections
                  firstname={profile.firstname}
                  id={profile.id}
                  section={profile.profileSections[key] || {}}
                  questions={availableProfileSections[key]}
                  canEdit={canEdit}
                />
              </Scope>
            </Section>
          ) : null
        )}
    </>
  );
}

export default observer(ProfileForm);
