/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {Elements, StripeProvider} from 'react-stripe-elements';

import BillingDetails from './billing.details';
import BillingModal from './billing.modal';
import config from '../../constants/config';

function Billing({
  canEdit,
  creditCard,
  creditExpMonth,
  creditExpYear,
  modalOpen,
  onAddressChange,
  onCreditCardChange,
  onInputChange,
  onSubscriptionSubmit,
  setModalOpen,
  stripeCard,
}) {
  return (
    <StripeProvider apiKey={config.stripePublishableKey}>
      <Elements>
        <>
          <BillingDetails
            address={stripeCard.address}
            cardName={stripeCard.name}
            creditCard={creditCard}
            creditExpMonth={creditExpMonth}
            creditExpYear={creditExpYear}
            email={stripeCard.email}
            onEditCard={() => setModalOpen(true)}
            canEdit={canEdit}
          />
          {canEdit && (
            <>
              <hr />
              <BillingModal
                isOpen={modalOpen}
                onDismiss={() => setModalOpen(false)}
                onSubscriptionSubmit={onSubscriptionSubmit}
                setModalOpen={setModalOpen}
                stripeCard={stripeCard}
                onCreditCardChange={onCreditCardChange}
                onAddressChange={onAddressChange}
                onInputChange={onInputChange}
              />
            </>
          )}
        </>
      </Elements>
    </StripeProvider>
  );
}

Billing.propTypes = {
  stripeCard: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      line1: PropTypes.string,
      line2: PropTypes.string,
      postal_code: PropTypes.string,
      state: PropTypes.string,
    }),
  }),
  canEdit: PropTypes.bool,
  creditCard: PropTypes.string,
  creditExpMonth: PropTypes.string,
  creditExpYear: PropTypes.string,
  modalOpen: PropTypes.bool,
  onAddressChange: PropTypes.func,
  onCreditCardChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onSubscriptionSubmit: PropTypes.func,
  setModalOpen: PropTypes.func,
};

Billing.defaultProps = {
  stripeCard: {
    name: '',
    email: '',
    address: {
      city: '',
      line1: '',
      line2: '',
      postal_code: '',
      state: '',
    },
  },
  canEdit: false,
  creditCard: '',
  creditExpMonth: '',
  creditExpYear: '',
  modalOpen: false,
  onAddressChange: () => {},
  onCreditCardChange: () => {},
  onInputChange: () => {},
  onSubscriptionSubmit: () => {},
  setModalOpen: () => {},
};

export default Billing;
