import {observable, decorate} from 'mobx';

export class UiStore {
  constructor(mainStore) {
    this.mainStore = mainStore;
  }

  mainStore = null;

  currentProfileId = null;
}

decorate(UiStore, {
  currentProfileId: observable,
});

export default UiStore;
