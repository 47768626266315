/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, {useState} from 'react';
import {inject, observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {MdPerson} from 'react-icons/md';
import withBreakpoints from '../utilities/withBreakpoints';
import withAuthorization from '../session/withAuthorization';
import ProfilesSingle from './profiles.single';
import routes from '../../constants/routes';
import LogoutButton from '../account/logout';
import {Icon} from '../common';

function ProfilesSelect({authUser, response, breakpoint, stores: {profilesStore}}) {
  const [isOpen, setIsOpen] = useState(false);
  const {profiles} = profilesStore;
  const myProfiles = profiles.filter(
    profile => (profile.id && authUser.type !== 'professional') || (profile.id && profile.permission && profile.permission.type !== 'professional')
  );
  const isDesktop = breakpoint === 'desktop';

  const handleClose = () => setIsOpen(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const profilesClassNames = () =>
    classNames({
      'profiles-select': true,
      '-open': isOpen,
    });

  return (
    <div className={profilesClassNames()}>
      <div className="profiles-single -toggle" onClick={toggleOpen}>
        <div className="notification-icon">
          <div className="notification-wrap">
            <MdPerson />
          </div>
        </div>
      </div>
      {isOpen && <div className="menu-underlay" onClick={handleClose} />}
      <ul className="list">
        {myProfiles.map(profile => (
          <ProfilesSingle onClick={handleClose} profile={profile} response={response} key={profile.id} />
        ))}
        {authUser.type === 'professional' && (
          <li className="profiles-single -create" onClick={handleClose}>
            <Link className="" to={routes.professional.clients}>
              <div className="image -icon">
                <Icon type="users" />
              </div>
              <div className="name">Clients</div>
            </Link>
          </li>
        )}
        <li className="profiles-single -create" onClick={handleClose}>
          <Link className="" to={routes.profile.create}>
            <div className="image -icon">
              <Icon type="user-add" />
            </div>
            <div className="name">Create New Profile</div>
          </Link>
        </li>
        {isDesktop && (
          <li className="profiles-single -settings" onClick={handleClose}>
            <Link className="" to={routes.user.edit}>
              <div className="image -icon">
                <Icon type="cog" />
              </div>
              <div className="name">Account Settings</div>
            </Link>
          </li>
        )}
        {!isDesktop && (
          <>
            <li className="profiles-single -settings" onClick={handleClose}>
              <Link className="" to={routes.user.edit}>
                <div className="image -icon">
                  <Icon type="vcard" />
                </div>
                <div className="name">Account</div>
              </Link>
            </li>
            <li className="profiles-single -settings" onClick={handleClose}>
              <Link className="" to={routes.user.settings}>
                <div className="image -icon">
                  <Icon type="cog" />
                </div>
                <div className="name">Settings</div>
              </Link>
            </li>
            <li className="profiles-single -settings" onClick={handleClose}>
              <Link
                className=""
                to={routes.generate(routes.user.subscription, {
                  profileId: '',
                })}>
                <div className="image -icon">
                  <Icon type="credit-card" />
                </div>
                <div className="name">Billing</div>
              </Link>
            </li>
          </>
        )}
        <li className="profiles-single -logout">
          <LogoutButton />
        </li>
      </ul>
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(withBreakpoints()(inject('stores')(observer(ProfilesSelect))));
