/* eslint-disable fp/no-mutating-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
/* eslint-disable react/prop-types */
import React, {useState, useCallback, useMemo} from 'react';
import {Form} from 'informed';
import {DndProvider} from 'react-dnd';
import dayjs from 'dayjs';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {toJS} from 'mobx';
import {Card, Button, HelpPanel, VimeoEmbed} from '../common';
import routes from '../../constants/routes';
import {saveGoal, deleteGoal, createGoal, createUUID} from '../../firebase/db';

import '../../assets/scss/components/goals/goal-edit.scss';

import GoalSteps from './goals.steps';
import TextField from '../Inputs/TextField';
import FieldToggle from '../Inputs/ToggleField';
import FieldTextarea from '../Inputs/TextAreaField';
import DateRangeField from '../Inputs/DateRangeField';

const goalTypes = [
  {
    value: 'ndis_report',
    label: 'Short Term Goal',
  },
  {
    value: 'ndis_long',
    label: 'Long Term Goal',
  },
  {
    value: 'family',
    label: 'Family Goal',
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

function GoalEdit({onboard, history, profile, goalId, goal}) {
  const [error, setError] = useState(null);

  const initialValues = useMemo(
    () =>
      goal
        ? {
            ...toJS(goal),
            // eslint-disable-next-line fp/no-mutating-methods
            steps: Object.entries(goal.steps || {})
              .map(([, value]) => ({id: createUUID(), ...value}))
              .sort((a, b) => a.sort - b.sort),
            team: goal.team ? Object.assign(...goal.team.map(key => ({[key]: true}))) : {},
            timeframe: goal.timeframe
              ? {
                  begin: goal.timeframe.begin && new Date(goal.timeframe.begin),
                  end: goal.timeframe.end && new Date(goal.timeframe.end),
                }
              : {},
          }
        : undefined,
    [goal]
  );

  const onSubmit = useCallback(
    async values => {
      setError(null);
      const submittedGoal = {
        ...values,
        steps: Object.entries(values.steps).reduce((result, [key, step], index) => {
          step.sort = index;
          if (!('complete' in step)) {
            step.complete = false;
          }
          result[key] = step;
          return result;
        }, {}),
        timeframe: !values.timeframe
          ? {}
          : {
              begin: dayjs(values.timeframe.begin).toISOString(),
              end: dayjs(values.timeframe.end).toISOString(),
            },
      };

      try {
        if (goalId) {
          await saveGoal(profile.id, goalId, submittedGoal);
        } else {
          await createGoal(profile.id, submittedGoal);
        }

        const nextRoute = onboard ? routes.profile.onboard.intro : routes.profile.goals;

        history.push(
          routes.generate(nextRoute, {
            profileId: profile.id,
          })
        );
      } catch (e) {
        console.error(e);
        setError(e.message);
      }
    },
    [onboard, profile, goalId, history]
  );

  const onDeleteGoal = useCallback(
    async e => {
      e.preventDefault();
      // eslint-disable-next-line no-alert
      if (!window.confirm('Are you sure you want to delete this goal? All posts related to this goal will also be deleted. This cannot be undone.')) {
        return false;
      }
      // Note: this triggers a cloud function to clean up any posts that reference this goal
      await deleteGoal(profile.id, goalId);

      history.push(
        routes.generate(routes.profile.goals, {
          profileId: profile.id,
        })
      );

      return true;
    },
    [profile, goalId, history]
  );

  const onCancel = useCallback(
    e => {
      e.preventDefault();

      const returnRoute = onboard ? routes.profile.onboard.intro : routes.profile.goals;

      history.push(
        routes.generate(returnRoute, {
          profileId: profile.id,
        })
      );
    },
    [onboard, profile, history]
  );

  return (
    <div className="container goal-edit">
      <Card help full>
        <div className="card__content">
          <HelpPanel defaultsOpen={onboard}>
            <h3>Help setting your goals</h3>
            <p>
              Goals give everyone in your team a common objective to work towards. Goals are broken down into smaller steps, and progress is tracked against
              these later when we post.
            </p>
            <p>
              Whether you have short term (less than 12 months), long term (more than 12 months) or specific family goals on sameview, your whole team will help
              you towards achieving them.
            </p>
            <VimeoEmbed videoId="344929662" videoName="HOW TO GOALS" />
          </HelpPanel>
          <div className="card__body">
            <h3 className="card__heading">{`${profile?.firstname}'s Goals`}</h3>
            <Formik className="goal-edit__form" onSubmit={onSubmit} initialValues={initialValues} enableReinitialize validationSchema={validationSchema}>
              {({handleSubmit}) => (
                <Form>
                  <div className="goal-edit__row">
                    <TextField
                      prefix="goal-edit"
                      name="name"
                      label="Goal Title"
                      wrapperClassName="goal-edit__title"
                      placeholder="Any goal you and your team are working towards"
                    />
                    <div className="goal-edit__timeframe">
                      <DateRangeField prefix="goal-edit" field="timeframe" label="Time Frame" placeholder="DD/MM/YY" />
                    </div>
                  </div>
                  <div style={{maxWidth: 600}}>
                    <FieldToggle prefix="goal-edit" name="type" label="Goal Type" wrapperClassName="goal-edit__type" options={goalTypes} />
                  </div>

                  <FieldTextarea
                    prefix="goal-edit"
                    name="description"
                    label="Describe your goal"
                    wrapperClassName="goal-edit__describe"
                    placeholder="A summary of what you want you and your team to achieve"
                  />
                  {/*  eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="goal-edit__label">
                    Steps to Achieve Goal
                    <div className="goal-edit__section">
                      <DndProvider backend={HTML5Backend}>
                        <GoalSteps goalId={goalId} />
                      </DndProvider>
                    </div>
                  </label>
                  <div className="goal-edit__actions">
                    <Button left type="tertiary" onClick={onCancel}>
                      Cancel
                    </Button>
                    {goalId && (
                      <Button left type="tertiary" onClick={onDeleteGoal}>
                        Delete Goal
                      </Button>
                    )}
                    <Button submit right type="primary" onClick={handleSubmit}>
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {error && (
            <section className="article-errors">
              <div>{error}</div>
            </section>
          )}
        </div>
      </Card>
    </div>
  );
}

export default GoalEdit;
