/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {NotificationManager} from 'react-notifications';
import {getAuth, multiFactor, sendEmailVerification, unlink} from 'firebase/auth';
import withAuthorization from '../session/withAuthorization';
import {db} from '../../firebase';
import routes from '../../constants/routes';
import {Icon, Button, Card, Heading} from '../common';
import AccountTabs from './tabs';
import Modal from '../utilities/modal';
import {set2FA} from '../../firebase/db';
import Security2FAForm from './security.2fa';

const logout = async (auth, profilesStore, notificationsStore) => {
  profilesStore.clearProfiles();
  notificationsStore.clearNotifications();
  await auth.signOut();
};

function NotificationSettings({authUser, history, stores: {profilesStore, notificationsStore}}) {
  const auth = getAuth();
  const mfa = useMemo(() => multiFactor(auth.currentUser), [auth.currentUser]);
  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delay, setDelay] = useState(0);

  const handleNotificationsToggle = useCallback(
    e => {
      const {checked} = e.target;
      db.setUserEmailNotificationsState(authUser.uid, checked)
        .then(() => {
          NotificationManager.success('Notification settings updated');
        })
        .catch(err => {
          console.warn(err);
          NotificationManager.error('Notification settings update failed');
        });
    },
    [authUser.uid]
  );

  const handle2FAToggle = useCallback(async () => {
    if (mfa.enrolledFactors.length > 0) {
      try {
        setLoading(true);
        const hasPhone = auth.currentUser.providerData.find(obj => obj.providerId === 'phone');
        if (hasPhone) {
          await unlink(auth.currentUser, 'phone');
        }
        mfa.unenroll(mfa.enrolledFactors[0]);
        await logout(auth, profilesStore, notificationsStore);
      } catch (twoFaError) {
        console.log(`TESTING :: twoFaError = `, twoFaError);
      }
    } else {
      setOpenPhoneModal(true);
    }
  }, [mfa, auth, profilesStore, notificationsStore]);

  const handleModalClose = useCallback(() => setOpenPhoneModal(false), []);

  const handleTypeChange = useCallback(
    e => {
      const {value} = e.target;
      db.setUserAccountType(authUser.uid, value)
        .then(() => {
          NotificationManager.success('Account type updated');
        })
        .catch(err => {
          console.warn(err);
          NotificationManager.error('Account type update failed');
        });
    },
    [authUser.uid]
  );

  const handleSendEmail = useCallback(async () => {
    setDelay(60);
    await set2FA(null);
    await sendEmailVerification(auth.currentUser);
  }, [auth]);

  useEffect(() => {
    if (delay) {
      setTimeout(() => setDelay(prev => prev - 1), 1000);
    }
  }, [delay]);

  useEffect(() => {
    if (auth.currentUser.providerData.find(obj => obj.providerId === 'phone') && mfa.enrolledFactors.length === 0) {
      unlink(auth.currentUser, 'phone');
    }
  }, [auth, mfa]);

  return (
    <Card full>
      <Heading primary>
        <h1>Account Settings</h1>
      </Heading>
      <AccountTabs />
      <section>
        <h4>Notifications</h4>
        <input
          id="email-notifications"
          type="checkbox"
          name="notifications"
          onChange={handleNotificationsToggle}
          checked={authUser.notificationsEnabled !== false}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="email-notifications">Email Notifications Enabled</label>
        <br />
        <br />
        <h4>Two Factor Authentication</h4>
        {authUser?.emailVerified ? (
          <>
            <input
              type="checkbox"
              id="two-fa"
              name="two-fa"
              onChange={handle2FAToggle}
              checked={openPhoneModal || mfa.enrolledFactors.length > 0}
              disabled={loading}
            />
            <label htmlFor="two-fa">
              Two factor authentication enabled<sup>*</sup>
            </label>
            <br />
            <div className="complete__steps-container">
              <Icon type="info" /> Disabling or Enabling two factor authentication will <strong>log you out</strong> and will require you to log in again
            </div>
          </>
        ) : (
          <>
            <p>Before you can turn two factor authentication on you will need to verify you email account. </p>
            <Button disabled={delay > 0} type="primary" onClick={handleSendEmail}>
              Send email to {authUser.email} {delay > 0 && `(send again in ${delay})`}
            </Button>
          </>
        )}
        <br />
        <br />
        <h4>Account Type</h4>

        <input
          type="radio"
          id="account-type-individual"
          name="account-type"
          value="individual"
          checked={authUser.type === 'individual' || !authUser.type}
          onChange={handleTypeChange}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="account-type-individual">Individual</label>
        <br />
        <input
          type="radio"
          id="account-type-professional"
          name="account-type"
          value="professional"
          checked={authUser.type === 'professional'}
          onChange={handleTypeChange}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="account-type-professional">Professional</label>

        <section className="footer-action">
          <button type="button" className="button -tertiary -left" onClick={() => history.push(routes.generate(routes.home))}>
            Close
          </button>
        </section>
      </section>
      {openPhoneModal && (
        <Modal handleDismiss={handleModalClose}>
          <Security2FAForm auth={auth} authUser={authUser} onDismiss={handleModalClose} />
        </Modal>
      )}
    </Card>
  );
}

const authCondition = authUser => !!authUser;

export default inject('stores')(withAuthorization(authCondition)(observer(NotificationSettings)));
