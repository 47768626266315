import * as Sentry from '@sentry/react';

export const errorLogger = (error, options) => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.captureException(error, options);
  } else {
    throw error;
  }
};

export default errorLogger;
