/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, {useCallback, useEffect, useState} from 'react';
import {observer, inject} from 'mobx-react';

import Loading from '../utilities/loading';
import NotFound from '../errors/notfound';
import withAuthorization from '../session/withAuthorization';
import {db} from '../../firebase';

import ModalExpired from '../subscription/modal.expired';
import ModalOwnerHold from '../subscription/modal.owner-hold';
import ModalContributorHold from '../subscription/modal.contributor-hold';
import ProfileContext from './ProfileContext';
import SectionContext from './SectionContext';
import ProfileRoutes from './profile.routes';
import routes from '../../constants/routes';
import ModalExpiring from '../subscription/modal.expiring';
import getIsOwner from '../../helpers/getIsOwner';
import getIsSubscriptionOwner from '../../helpers/getIsSubscriptionOwner';
import getIsDeleted from '../../helpers/getIsDeleted';

function Profile(props) {
  const {
    authUser,
    stores: {postsStore, profilesStore, uiStore},
    match,
    history,
  } = props;

  const [loading, setLoading] = useState(true);
  const [profileId, setProfileId] = useState(null);
  const [profile, setProfile] = useState(null);
  const [profileCreationTriggered, setProfileCreationTriggered] = useState(false);
  const [availableProfileSections, setAvailableProfileSections] = useState(null);

  const handleUpdate = useCallback(
    async updateProfileId => {
      // eslint-disable-next-line fp/no-mutation
      uiStore.currentProfileId = updateProfileId;
      const newProfile = await profilesStore.getProfileById(updateProfileId);

      try {
        if (newProfile) {
          postsStore.getPostListForProfile(updateProfileId);

          const profileHasSubscription =
            'subscription' in newProfile &&
            typeof newProfile.subscription === 'object' &&
            newProfile.subscription !== null &&
            Object.keys(newProfile.subscription).length > 0;

          console.log(
            `${new Date().getTime()} : PROFILE : updateStripeSubscriptions : CONDITIONS = `,
            JSON.stringify({
              profileNotCancelled: !newProfile.cancelled,
              profileHasSubscription,
              profileCreationTriggered,
              isOwner: getIsOwner(newProfile),
            })
          );

          // Some older accounts haven't been synced with Stripe, they aren't a customer or subscription.
          // If this is the case, crate the Stripe customer and store a trial subscription.
          if (!newProfile.cancelled && !profileHasSubscription && profileCreationTriggered === false && getIsOwner(newProfile)) {
            setProfileCreationTriggered(true);

            await db.updateStripeCustomers(authUser.uid, {
              name: `${authUser.firstname} ${authUser.lastname}`,
              email: authUser.email,
            });
            const customer = await db.getStripeCustomerId(authUser.uid);
            const plan = await db.getStripePlanId();

            // Create or edit the subscription plan.
            const subscriptionData = {
              cancel_at_period_end: false,
              coupon: '',
              customer,
              id: '',
              item: '',
              order_id: updateProfileId,
              plan,
              trial_from_plan: true,
              user_id: authUser.uid,
            };

            // Save the subscription to the database
            await db.updateStripeSubscriptions(updateProfileId, subscriptionData);

            // Now fetch the user to put it in the profilesStore
            await profilesStore.fetchProfiles(authUser.uid);

            return;
          }
        }
      } catch (error) {
        // An error has likely occurred as the user is a viewer and doesn't have write permissions.
        console.log(`${new Date().getTime()} : handleUpdate error : `, error);
      }

      setProfile(newProfile);
      setAvailableProfileSections(profilesStore.availableProfileSections);
      setLoading(false);
    },
    [authUser.email, authUser.firstname, authUser.lastname, authUser.uid, postsStore, profileCreationTriggered, profilesStore, uiStore]
  );

  const onSubscribe = () => {
    history.push(
      routes.generate(routes.user.subscription, {
        profileId: match.params.profileId,
      })
    );
  };

  useEffect(() => {
    const {profileId: newProfileId} = match.params;

    if (profileId !== newProfileId) {
      setProfileId(newProfileId);
      handleUpdate(newProfileId);
    }
  }, [handleUpdate, match.params, profileId]);

  if (loading) return <Loading />;
  if (!profile || getIsDeleted(profile)) return <NotFound />;

  // const statuses = ['incomplete', 'incomplete_expired', 'trialing', 'active', 'past_due', 'canceled', 'unpaid']
  const isNearlyExpired = false; // profile.subscription.status === 'trialing' && profile.subscription.trial_end... hmm
  const isExpired = profile.subscription.status === 'incomplete_expired';
  const isHold = !!profile.cancelled || profile.subscription.status === 'unpaid' || profile.subscription.status === 'canceled';
  const isSubscriptionOwner = getIsSubscriptionOwner(profile);

  return (
    <SectionContext.Provider value={availableProfileSections}>
      <ProfileContext.Provider value={profile}>
        <div>
          <ProfileRoutes {...props} />
          <ModalExpiring isOpen={isNearlyExpired} onSubscribe={onSubscribe} />
          <ModalExpired isOpen={isExpired} onSubscribe={onSubscribe} />
          <ModalOwnerHold isOpen={isHold && isSubscriptionOwner} onSubscribe={onSubscribe} />
          <ModalContributorHold isOpen={isHold && !isSubscriptionOwner} />
        </div>
      </ProfileContext.Provider>
    </SectionContext.Provider>
  );
}

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(inject('stores')(observer(Profile)));
