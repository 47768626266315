/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {inject, observer} from 'mobx-react';

import routes from './constants/routes';

import HomePage from './components/home';
import PageNotFound from './components/errors/pagenotfound';
import AccountIndex from './components/account';
import Profile from './components/profile';
import ProfileCreate from './components/profile/create';
import Professional from './components/professional';
import withAuthorization from './components/session/withAuthorization';
import Loading from './components/utilities/loading';

const redirectToHome = ({history}) => history.push(routes.home);

class AuthedRoutes extends Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    const {
      authUser,
      history,
      stores: {profilesStore},
    } = this.props;

    await profilesStore.fetchProfiles(authUser.uid);

    const {profiles} = profilesStore;
    const myProfiles = profiles.filter(
      profile => (profile.id && authUser.type !== 'professional') || (profile.id && profile.permission && profile.permission.type !== 'professional')
    );

    // If the user doesn't have any profiles, push them into creating one.
    if (myProfiles.length === 0 && authUser.type !== 'professional') history.push(routes.profile.create);

    this.setState({
      loading: false,
    });
  }

  render() {
    const {location} = this.props;
    const {loading} = this.state;

    if (loading) return <Loading />;

    return (
      <Switch location={location}>
        <Route exact path={routes.user.sign_up} render={redirectToHome} />
        <Route exact path={routes.user.sign_up_family} render={redirectToHome} />
        <Route exact path={routes.user.sign_up_individual} render={redirectToHome} />
        <Route exact path={routes.user.sign_up_provider} render={redirectToHome} />
        <Route exact path={routes.user.log_in} render={redirectToHome} />
        <Route exact path={routes.user.password_reset} render={redirectToHome} />

        <Route exact path={routes.home} component={HomePage} />
        <Route exact path={routes.user.index} component={AccountIndex} />
        <Route exact path={routes.profile.create} component={ProfileCreate} />
        <Route exact path={routes.profile.index} component={Profile} />

        <Route exact path={routes.professional.index} component={Professional} />

        <Route component={PageNotFound} />
      </Switch>
    );
  }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(inject('stores')(observer(AuthedRoutes)));
