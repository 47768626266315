/* eslint-disable react/prop-types */
import {getAuth, sendEmailVerification} from 'firebase/auth';
import {inject, observer} from 'mobx-react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {set2FA} from '../../firebase/db';
import {Footer, Heading} from '../common';
import Modal from '../utilities/modal';

function VerifyEmail({stores: {usersStore}}) {
  const user = getAuth();
  const userData = useMemo(() => usersStore.getUserById(user?.currentUser?.uid), [usersStore, user]);

  const [option, setOption] = useState(undefined);
  const [disabled, setDisabled] = useState(false);
  const [close, setClose] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [allowToSend, setAllowToSend] = useState(true);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (countdown) {
      setTimeout(() => setCountdown(prev => prev - 1), 1000);
    } else {
      setAllowToSend(true);
    }
  }, [countdown]);

  const handleSendEmail = useCallback(async () => {
    try {
      setShowSuccess(true);
      setAllowToSend(false);
      await sendEmailVerification(user.currentUser);
    } catch (error) {
      setShowSuccess(false);
      setShowError(true);
    } finally {
      setCountdown(120);
    }
  }, [user]);

  const handleCancel = useCallback(() => {
    setOption(false);
    setShowError(false);
  }, []);

  const handleSave = useCallback(async () => setOption(true), []);

  useEffect(() => {
    const updateDatabase = async () => {
      setDisabled(true);
      await set2FA(option);
      if (option) {
        handleSendEmail();
      }
      setDisabled(false);
      setClose(true);
    };
    if (option !== undefined) {
      updateDatabase();
    }
  }, [option, handleSendEmail]);

  if (!user.currentUser || user.currentUser.emailVerified || !userData) {
    return null;
  }

  if (showError) {
    return (
      <Modal handleDismiss={handleCancel} showDismiss>
        <Heading primary>
          <h4>Improve security</h4>
        </Heading>
        <p>An error has occurred when trying to sent to your email account ({user.currentUser.email}).</p>
        <p>You can try again in 2 minutes, please contact us if the error persists</p>
        {allowToSend && <Footer onSave={handleSendEmail} saveButtonLabel="Resend" />}
      </Modal>
    );
  }

  if ((userData.enable2FA || showSuccess) && !user.currentUser.emailVerified && option !== false) {
    return (
      <Modal handleDismiss={handleCancel} showDismiss>
        <Heading primary>
          <h4>Improve security</h4>
        </Heading>
        <p>A verification email was successfully sent to your email account ({user.currentUser.email}).</p>
        <p>{`If you don't receive it in 2 minutes, please check your spam or press resend for a new email`}</p>
        {allowToSend && <Footer onSave={handleSendEmail} saveButtonLabel="Resend" onCancel={handleCancel} cancelButtonLabel="Close" />}
      </Modal>
    );
  }

  if (userData.enable2FA !== undefined || close) {
    return null;
  }

  return (
    <Modal handleDismiss={handleCancel} showDismiss>
      <Heading primary>
        <h4>Improve security</h4>
      </Heading>
      <p>
        As a part of our continuous efforts to increase security, we have added a two factor authentication option. Enabling Two Factor authentication means
        that each time you log in, a code will be sent to your phone that you will need to use to complete the login process. You can choose to enable or
        disable two factor authentication anytime by going to user settings.
      </p>
      {!user.currentUser.emailVerified && <p>To enable 2 factor authentication you will need to verify your email first.</p>}
      <p>Would you like to turn 2 factor authentication on?</p>
      <Footer cancelButtonLabel="Not now" onCancel={handleCancel} onSave={handleSave} saveButtonLabel="Yes" saving={option && disabled} />
    </Modal>
  );
}

export default inject('stores')(observer(VerifyEmail));
