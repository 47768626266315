/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import distanceInWords from 'date-fns/formatDistanceStrict';
import {Link} from 'react-router-dom';
import {Avatar} from '../common';
import {getNotificationText} from './helpers/getNotificationText';

function Notification({type, link, avatar, profile, time, name, text, onClick}) {
  const splitName = name ? String(name).match(/\b\w/g) : null;
  const abbreviation = Array.isArray(splitName) ? splitName.join('') : '';
  const subtitle = getNotificationText(type, profile, name);

  if (subtitle === '') return null;

  return (
    <div className="notification-single" onClick={onClick}>
      <Link to={link}>
        <div className="header">
          <div className="avatar">
            {avatar && <Avatar src={avatar} />}
            {!avatar && <span>{abbreviation}</span>}
          </div>
          <div className="time">{distanceInWords(new Date(), time)}</div>
        </div>
        <div className="action">
          <span>{subtitle}</span>:
        </div>
        <div className="reaction">{text && `“${text}”`}</div>
        <hr />
      </Link>
    </div>
  );
}

Notification.propTypes = {
  type: PropTypes.string.isRequired,
  link: PropTypes.string,
  avatar: PropTypes.string,
  time: PropTypes.number,
  name: PropTypes.string,
  profile: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

Notification.defaultProps = {
  link: '',
  avatar: '',
  time: 1589857162045,
  name: '',
  profile: '',
  text: '',
  onClick: () => {},
};

export default Notification;
