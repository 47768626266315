import {getDownloadURL, listAll, ref as storageRef} from 'firebase/storage';
import {set, ref as databaseRef} from 'firebase/database';
import {firebase} from '../firebase';

const updateFileUrl = (destination, {arrayOfFiles, profileId, postId}) => {
  if (!arrayOfFiles?.length) {
    return;
  }

  if (destination === 'post') {
    arrayOfFiles.forEach((item, index) => {
      if (!item.postFilePublic && item.storagePath) {
        const fileRef = storageRef(firebase.storage, item.storagePath);
        getDownloadURL(fileRef).then(url => {
          set(databaseRef(firebase.database, `posts/${profileId}/${postId}/uploads/${index}/postFilePublic`), url);
        });
      }
    });
  }

  if (destination === 'documents') {
    arrayOfFiles.forEach(item => {
      if (!item.filelink && item.filename) {
        listAll(storageRef(firebase.storage, `uploads/documents/${profileId}`)).then(res => {
          res.items.forEach(newItem => {
            if (newItem.fullPath.includes(item.filename)) {
              const fileRef = storageRef(firebase.storage, newItem.fullPath);
              getDownloadURL(fileRef).then(url => {
                set(databaseRef(firebase.database, `documents/${profileId}/${item.id}/filelink`), url);
              });
            }
          });
        });
      }
    });
  }
};

export default updateFileUrl;
