import React from 'react';
import {Switch, Route} from 'react-router-dom';
import withAuthorization from '../session/withAuthorization';
import NotificationsSettings from './notifications.settings';
import routes from '../../constants/routes';
import AccountMenu from './menu';
import AccountEdit from './edit';
import PaymentPage from './payment';
import SubscriptionPage from './subscription';
import NotificationPage from '../notifications';

import AccountOnboardIntro from './onboard.intro';
import AccountOnboardName from './onboard.name';
import AccountOnboardAbout from './onboard.about';
import AccountOnboardPhoto from './onboard.photo';

function AccountPage() {
  return (
    <div className="page">
      <div className="container">
        <Switch>
          <Route exact path={routes.user.edit} component={AccountEdit} />
          <Route exact path={routes.user.settings} component={NotificationsSettings} />
          <Route exact path={routes.user.notifications} component={NotificationPage} />
          <Route exact path={routes.user.edit} component={AccountMenu} />

          {/* Payment Gateway */}
          <Route exact path={routes.user.payment} component={PaymentPage} />
          <Route exact path={routes.user.subscription} component={SubscriptionPage} />

          {/* Onboard */}
          <Route exact path={routes.user.inviteOnboard.intro} component={AccountOnboardIntro} />
          <Route exact path={routes.user.inviteOnboard.name} component={AccountOnboardName} />
          <Route exact path={routes.user.inviteOnboard.photo} component={AccountOnboardPhoto} />
          <Route exact path={routes.user.inviteOnboard.about} component={AccountOnboardAbout} />
        </Switch>
      </div>
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(AccountPage);
