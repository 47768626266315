/* eslint-disable react/prop-types */
import React from 'react';
import {ModalImage} from '../common';

import holdSrc from '../../assets/svg/communication.svg';

function ModalContributorHold({isOpen, onDismiss, email, name}) {
  return (
    <ModalImage type="primary" src={holdSrc} isOpen={isOpen} alt="Dancing Aliens" handleDismiss={onDismiss}>
      <h3>This plan is on hold</h3>
      <p>It looks like the person you were following has stopped their subscrition.</p>
      <p>You won’t be able to post or contribute till the account is reactivated.</p>
      <p>
        Contact <a href={`mailto:${email}`}>{name}</a> for more details.
      </p>
    </ModalImage>
  );
}

export default ModalContributorHold;
