import {initializeApp} from 'firebase/app';
import {getDatabase, ref, onValue} from 'firebase/database';
import {getAuth} from 'firebase/auth';
import {getAnalytics} from 'firebase/analytics';
import {getFunctions} from 'firebase/functions';
import {getStorage} from 'firebase/storage';
import {toast} from 'react-toastify';

const devConfig = {
  apiKey: 'AIzaSyA_PNwooGxklzbtf4IgOGxhU9ITiOAol_0',
  authDomain: 'sameview-staging.firebaseapp.com',
  databaseURL: 'https://sameview-staging.firebaseio.com',
  projectId: 'sameview-staging',
  storageBucket: 'sameview-staging.appspot.com',
  messagingSenderId: '893349184423',
  appId: '1:893349184423:web:455f29b6fb3231e353ec82',
  measurementId: 'G-WH1FDRKED3',
};

const prodConfig = {
  apiKey: 'AIzaSyDaH-zMZabtJxGlY3thx0kQu6hXU7eMIss',
  authDomain: 'sameview-production.firebaseapp.com',
  databaseURL: 'https://sameview-production.firebaseio.com',
  projectId: 'sameview-production',
  storageBucket: 'sameview-production.appspot.com',
  messagingSenderId: '733130844991',
  appId: '1:733130844991:web:b565e9d110f73e45d6418a',
  measurementId: 'G-3WCT8803GC',
};

// eslint-disable-next-line no-nested-ternary
const config = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_ENV === 'staging' ? devConfig : prodConfig) : devConfig;

const app = initializeApp(config);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

let PROCESS_NETWORK_ERROR = false;

const showNetworkError = (networkWorking = true) => {
  if (networkWorking) {
    toast.dismiss();
  } else {
    toast.error(`Internet connection lost. Please wait as any changes will be lost unless your connection is reastablished and they have a chance to save.`, {
      closeOnClick: false,
      draggable: false,
      closeButton: false,
    });
  }
};

const connectedRef = ref(database, '.info/connected');
onValue(connectedRef, snap => {
  const networkWorking = snap.val() === true;

  setTimeout(() => {
    // eslint-disable-next-line fp/no-mutation
    PROCESS_NETWORK_ERROR = true;
  }, 3000);

  if (PROCESS_NETWORK_ERROR) showNetworkError(networkWorking);
});

export {auth, storage, database, functions, analytics};
