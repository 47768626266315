const devConfig = {
  sameviewTeamUid: '3FGKmjlFlgYoXQLgwjFwbTdUb453',
  stripePublishableKey: 'pk_test_m7WMe6UZjUpzLHUBuQQjzi6T00cMawrAAc',
  environment: 'staging',
  googleAnalyticsId: 'UA-111523022-3',
  segmentSourceID: 'vELU8pJtEbxgavQ5AKu8oo',
  segmentWriteKey: 'Ykhwmx3YUaWHLI58Iz7feF1X2BTJb6n8',
};

const prodConfig = {
  sameviewTeamUid: '3FGKmjlFlgYoXQLgwjFwbTdUb453',
  stripePublishableKey: 'pk_live_KRfOX7KguU1W4jABF1NtN59m004a2I3Lfw',
  environment: 'production',
  googleAnalyticsId: 'UA-111523022-2',
  segmentSourceID: '6TNo47tGawMc5oSfKZ7aYh',
  segmentWriteKey: 'MrMUgGwrXqBXXE2VMyXomUCkMkSt2B83',
};

// eslint-disable-next-line no-nested-ternary
const config = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_ENV === 'staging' ? devConfig : prodConfig) : {...devConfig, debugging: true};

if (!config.sameviewTeamUid || config.sameviewTeamUid === '') {
  throw new Error('Make sure to set sameview team config');
}

export default config;
