import React from 'react';

function Terms() {
  return (
    <div>
      <p>This is an agreement between:</p>
      <p>
        A person with a disability who receives services to assist with that disability, or a parent, friend or family member acting on behalf of that person{' '}
        <strong>(you)</strong>
      </p>
      <p>and</p>
      <p>
        Same View Pty Ltd, trading as sameview ABN 63 616 065 868 (<strong>sameview, we, us</strong> or <strong> our</strong>).
      </p>
      <p>
        This agreement contains the terms of service that govern your access to and use of the sameview application (the <strong>Service</strong>). You accept
        this agreement when you create an account for the Service.
      </p>

      <h4>1. The Service</h4>
      <p>The Service helps you to coordinate your disability support, by allowing you to:</p>
      <p>(a) track information relating to the person with a disability and their support services;</p>
      <p>
        (b) send secure messages to, and receive secure message from, service providers to a person with a disability (<strong>service providers</strong>); and
      </p>
      <p>
        (c) send and receive secure chat messages, saved and accessible, including photos, voice notes, PDFs and other common file types, to and from your
        service providers.
      </p>

      <h4>2. Your obligations</h4>
      <p>
        You must create an account for the Service. You must provide an email address and password to create your account with us and to access the Service. You
        must keep the password secure and not disclose it to anyone. We are not liable for any issues or liabilities that may arise out of your failure to keep
        your password secure. You will need your email address and password to log into your account to use the Service. When you log into your account you will
        be able to access the Service, manage your appointments, discussion notes, goals, actions, member profiles, care information and online chat
        discussions. You are solely responsible for all activities that occur under your account and for the security of your mobile device from which the
        Service is accessed. You must use the Service legally and appropriately. You agree that you will not use the Service:
      </p>
      <p>(a) to infringe upon or violate any intellectual property rights;</p>
      <p>(b) in breach of any laws or regulations;</p>
      <p>
        (c) to create, upload or transmit a virus, malware or any other computer code designed to interfere with the normal function of or data stored on a
        computer, mobile device or network; or
      </p>
      <p>
        {`(d) to engage in any fraudulent or objectionable activity including by pretending or holding yourself out to be somebody you are not, 'phishing',
        'spoofing' or spamming.`}
      </p>
      <p>
        If you engage in any of these activities, we may close or suspend your account and restrict your access to and use of the Service. We may also remove
        any data stored in connection with your account or disclose information in relation to your account to law enforcement, regulatory bodies or other
        appropriate parties. We may do these things without telling you.
      </p>

      <h4>3. Intellectual property</h4>
      <p>
        You retain all proprietary rights you have in the content, including any videos, photos, voice notes and PDF files you upload in connection with the
        Service. You grant us, and have ensured that any third party holders of intellectual property in the content that you upload have granted to us, a
        licence to use, store, host, copy, modify and transmit this information for the purposes of providing you with the Service. If you contact us with
        comments or suggestions about the Service, you grant us a perpetual, fully paid, royalty-free, irrevocable, transferable license, with right of
        sublicense, to use your ideas.
      </p>
      <p>
        You agree that we or our third party suppliers own all intellectual property rights in connection with the Service. We give you a limited personal,
        royalty-free, non-assignable and non-exclusive license to use any software or content provided in connection with the Service. You must not copy,
        modify, distribute, sell, lease, reverse engineer or attempt to extract the source code of any software or content provided in connection with the
        Service. Some software or content may be provided to you under a separate licence. In the event that the separate licence conflicts with the licence
        under this agreement, the terms of the separate licence prevail to the extent of the conflict.
      </p>

      <h4>4. Closing your account</h4>
      <p>
        You can close your account at any time. If your account is closed, other than for your failure to comply with the terms of this agreement, we will make
        reasonable efforts to make any data you stored in connection with the Service available to you for a period of 30 calendar days. After this period, we
        may delete your data and you will no longer have access to it.
      </p>

      <h4>5. Breaching this agreement</h4>
      <p>
        If you breach this agreement, we may close or suspend your account. If we close your account in this way, we may delete some or all of your data and you
        will no longer have access to it.
      </p>

      <h4>6. Disclaimer of warranties</h4>
      <p>
        Subject to any condition, warranty or right implied by law which cannot be excluded by agreement, all implied conditions, warranties and rights are
        excluded. Where any condition, warranty or right is implied by law and cannot be excluded, we limit our liability for breach of that implied condition,
        warranty or right, in connection with the Service to one of the following, as we reasonably determine:
      </p>
      <p>(a) the supplying of the Service again; or</p>
      <p>(b) the payment of the costs of having the Service supplied again.</p>

      <h4>7. Limitation of liability and indemnity</h4>
      <p>To the extent permitted by law, you agree that we will not be liable for any:</p>
      <p>(a) indirect or consequential loss or damage;</p>
      <p>(b) loss of profits, reputation, business, goodwill, patients or labour costs;</p>
      <p>(c) damage or loss arising from any negligence, willful or fraudulent act or omission of any other person besides us; and</p>
      <p>(d) loss or damage arising from our acts or omissions, where we have acted or omitted to act on your instructions.</p>
      <p>You agree to keep us indemnified against all loss or damage that we suffer arising as a result of:</p>
      <p>(a) any breach of your obligations under this agreement;</p>
      <p>(b) any negligent, wrongful or fraudulent act or omission by you in relation to this agreement; and</p>
      <p>(c) any negligent, wrongful or fraudulent activity occurring under your account or mobile device for which you can reasonably be held responsible,</p>
      <p>except to the extent that we are responsible for that loss or damage.</p>

      <h4>8. Confidentiality, privacy and security</h4>
      <p>
        We collect personal information to provide the Service, including troubleshooting, technical support and handling complaints; to allow users to share
        and view their information; to contact users of the Service; to maintain, protect, improve and promote the Service, or to develop new products or
        services; to provide users with information about new products and services that may be of interest to them; to generate data for internal analysis; for
        any purpose required or authorised by law; and to otherwise run our business.
      </p>
      <p>
        If you do not provide us with certain required personal information we may not be able to provide you with access to the Service. Unless you tell us not
        to, we may use information we collect about you to tell you about products and services that might interest you.
      </p>
      <p>
        In order to provide the Service to you, we may disclose your information to our service providers, professional advisers and consultants. These persons
        will be bound by duties of confidentiality, where appropriate. We will not disclose your information for any purpose that is inconsistent with this
        agreement unless we are required or authorised by law to do so.
      </p>
      <p>
        Messages are automatically deleted from your account and our system once your account is closed, so you will not be able to retrieve them after that
        period.
      </p>
      <p>
        Our privacy policy explains how you can seek to access and/or correct the personal information that we hold about you, as well as how to make a
        complaint about the way in which we handle your personal information and how we deal with complaints. You can access our privacy policy. If you have any
        questions about our personal information collection and handling practices please contact out Privacy Officer at info@sameview.com.au.
      </p>
      <p>
        We take appropriate steps to protect the information or data you store in connection with the Service from loss or unauthorised access or disclosure.
        You should also take steps to protect yourself including by regularly backing up important data and having antivirus software on the mobile device on
        which you use the Service.
      </p>

      <h4>9. General</h4>
      <p>
        We can discontinue the Service or change the Service including by adding or removing features or functions at anytime. When we have a legitimate
        business interest, we can also amend this agreement at any time. If we amend this agreement, we will put the amended agreement on our website.{' '}
      </p>
      <p>You agree not to use the Service in contravention of any relevant laws in Australia, particularly the Privacy Act 1988 (Cth).</p>
      <p>This agreement is governed by the laws of New South Wales and you submit to the exclusive jurisdiction of the courts of New South Wales. </p>
      <p>
        If any part of this agreement is for any reason unenforceable, that part must be read down to the extent necessary to preserve its operation. If it
        cannot be read down, it must be deleted.
      </p>
    </div>
  );
}

export default Terms;
