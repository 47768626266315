/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, {useState, useEffect, useMemo} from 'react';
import {inject, observer} from 'mobx-react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {NotificationManager} from 'react-notifications';
import classnames from 'classnames';
import {isEmpty} from 'lodash';
import isEqual from 'lodash/isEqual';
import withProfile from '../profile/withProfile';
import withAuthorization from '../session/withAuthorization';
import ModalDelete from '../common/modal.delete';
import {Icon, Avatar, Button} from '../common';
import {adjustUserPermissions, deletePermissions} from '../../firebase/db';
import {getFullName} from '../../helpers/getFullName';
import {getPermissions, getPermissionsValue} from '../../helpers/getPermissions';
import {getRelationship} from '../../helpers/getRelationship';
import {getPermissionsType, getPermissionsInviteAccepted} from '../../helpers/getPermissionsType';
import Modal from '../utilities/modal';
import TeamSettingsCard from '../profile/team.settings.card';
import TeamProfessionalCard from '../profile/team.professional.card';
import '../../assets/scss/components/team/user-card.scss';
import getIsOwner from '../../helpers/getIsOwner';
import config from '../../constants/config';

dayjs.extend(advancedFormat);

const TeamSettingsDropdown = React.memo(({onDismiss, ...props}) => (
  <Modal type="mini" handleDismiss={onDismiss} showDismiss>
    <TeamSettingsCard {...props} />
  </Modal>
));

const TeamProfessionalDropdown = React.memo(({onDismiss, ...props}) => (
  <Modal type="mini" handleDismiss={onDismiss} showDismiss>
    <TeamProfessionalCard {...props} />
  </Modal>
));

function User({user, profile, authUser, isProfessional, open = false}) {
  const [permissionsModalOpen, setPermissionsModalOpen] = useState(false);
  const [professionalModalOpen, setProfessionalModalOpen] = useState(false);
  const [loadingPermissions, setLoadingPermissions] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [fullName, setFullName] = useState('');
  const [permissionValue, setPermissionValue] = useState('');
  const [permission, setPermission] = useState('');
  const [relationship, setRelationship] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [inviteAccepted, setInviteAccepted] = useState('');
  const [isOwner, setIsOwner] = useState('');
  const [userProfile, setUserProfile] = useState({});
  const [removeAccessTooltip, setRemoveAccessTooltip] = useState('');

  useEffect(() => {
    if (!isEqual(profile, userProfile)) {
      setUserProfile(profile);

      if (open) setPermissionsModalOpen(open);
    }
  }, [open, profile, userProfile]);

  useEffect(() => {
    setFullName(getFullName(user));
    setJobTitle((isProfessional && user.job_title) || relationship);
  }, [isProfessional, relationship, user]);

  useEffect(() => {
    if (!isEmpty(userProfile)) {
      setPermissionValue(getPermissionsValue(userProfile.permissions[user.id]));
      setPermission(getPermissions(userProfile.permissions[user.id]));
      setRelationship(getRelationship(userProfile.permissions[user.id]) || 'Team Member');
      setInviteAccepted(getPermissionsInviteAccepted(userProfile.permissions[user.id], user));
      setIsOwner(getIsOwner(userProfile));
      let newRemoveAccessTooltip = user.id === userProfile.subscription.user_id ? 'You are unable to remove the user paying for the subscription' : '';
      // eslint-disable-next-line fp/no-mutation
      newRemoveAccessTooltip =
        user.id === config.sameviewTeamUid
          ? 'By removing support access, you accept that sameview will not be able to help you if an issue occurs'
          : newRemoveAccessTooltip;

      setRemoveAccessTooltip(newRemoveAccessTooltip);
    }
  }, [authUser, userProfile, user]);

  const openPermissionsModal = e => {
    e.stopPropagation();
    setPermissionsModalOpen(true);
  };

  const closePermissionsModal = e => {
    e.stopPropagation();
    setPermissionsModalOpen(false);
  };

  const openProfessionalModal = e => {
    e.stopPropagation();
    setProfessionalModalOpen(true);
  };

  const closeProfessionalModal = e => {
    e.stopPropagation();
    setProfessionalModalOpen(false);
  };

  const toggleCancelModal = e => {
    if (e) e.preventDefault();
    setPermissionsModalOpen(!permissionsModalOpen);
    setCancelModalOpen(!cancelModalOpen);
  };

  const onSavePermissions = async values => {
    setLoadingPermissions(true);
    setPermissionValue(values.permissions);

    const newPermissionValue = getPermissionsValue(userProfile.permissions[user.id]);
    const newPermission = getPermissions(userProfile.permissions[user.id]);
    const newRelationship = getRelationship(userProfile.permissions[user.id]) || 'Team Member';
    const newType = getPermissionsType(userProfile.permissions[user.id]);

    if (newPermissionValue === values.permissions) {
      NotificationManager.info(`${user.firstname} already has ${newPermission} permissions`);
      setLoadingPermissions(false);
      setPermissionsModalOpen(false);
      return true;
    }

    await adjustUserPermissions({
      memberId: user.id,
      type: newType,
      relationship: newRelationship,
      permissionLevel: values.permissions,
      profileId: userProfile.id,
    });

    const displayPermisson = getPermissions(values.permissions);
    NotificationManager.success(`Set ${user.firstname}'s permissions to ${displayPermisson}`);
    setLoadingPermissions(false);
    setPermissionsModalOpen(false);

    return true;
  };

  const onRemoveAccess = async values => {
    setCancelModalOpen(false);
    setPermissionsModalOpen(false);

    // Revoke permissions to this user on the profile
    await deletePermissions(userProfile.id, user.id);

    NotificationManager.success(`Removed ${user.firstname}'s permissions from this profile`);

    setCancelModalOpen(false);
    setPermissionsModalOpen(false);
  };

  const isGroup = useMemo(() => profile.type === 'group', [profile]);
  const isSameviewSupport = useMemo(() => user.id === '3FGKmjlFlgYoXQLgwjFwbTdUb453', [user]);

  const canDisplayDetails = useMemo(() => !(isSameviewSupport || isGroup), [isSameviewSupport, isGroup]);

  // If the user has been removed from the team, do not show them.
  if (permissionValue === 'deleted') return null;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classnames('user-card user-card__avatar', {
        'user-card--professional': canDisplayDetails,
      })}
      onClick={canDisplayDetails ? openProfessionalModal : undefined}>
      <div className="user-card__avatar">
        <Avatar src={user && user.profileImagePublic} />
      </div>
      <div className="user-card__details">
        <h4 className="user-card__name">{fullName}</h4>
        <p className="user-card__relationship">
          {jobTitle}
          &nbsp;&nbsp;&nbsp;
          {!isSameviewSupport && <span className="user-card__permission label">{permission}</span>}
          &nbsp;&nbsp;&nbsp;
          {inviteAccepted && <span className="user-card__invite_sent label">Invite Sent</span>}
          {!inviteAccepted && <span className="user-card__invite_accepted label">Invite Accepted</span>}
        </p>
      </div>
      <div className="user-card__action">
        {user.id !== authUser.uid && isOwner && (
          <Button type="tertiary" toggle size="small" onClick={openPermissionsModal}>
            <Icon type="cog" />
          </Button>
        )}
      </div>
      {permissionsModalOpen && (
        <TeamSettingsDropdown
          name={fullName}
          relationship={relationship}
          onDismiss={closePermissionsModal}
          onSubmit={onSavePermissions}
          onRemoveAccess={toggleCancelModal}
          onRemoveDisabled={user.id === userProfile.subscription.user_id}
          onRemoveAccessTooltip={removeAccessTooltip}
          permissions={permissionValue}
          loading={loadingPermissions}
          isOwner={isOwner}
        />
      )}

      {professionalModalOpen && (
        <TeamProfessionalDropdown
          name={fullName}
          relationship={relationship}
          email={user.email}
          companyName={user.organisation ? user.organisation.name : null}
          companyAddress={user.organisation ? user.organisation.address : null}
          phoneNumber={user.organisation ? user.organisation.mobile : null}
          url={user.organisation ? user.organisation.website : null}
          onDismiss={closeProfessionalModal}
          onRemoveAccess={toggleCancelModal}
          onRemoveDisabled={user.id === userProfile.subscription.user_id}
          onRemoveAccessTooltip={removeAccessTooltip}
          isOwner={isOwner}
        />
      )}

      <ModalDelete
        isOpen={cancelModalOpen}
        title="Manage Profile Access"
        body={`If you remove ${fullName}'s access, they will no longer be able to view ${getFullName(
          userProfile
        )}'s profile and related content. Are you sure?`}
        labelDismiss="Keep Access"
        onDismiss={event => toggleCancelModal(event)}
        labelConfirm="Remove Access"
        onConfirm={event => onRemoveAccess(event)}
      />
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default inject('stores')(withAuthorization(authCondition)(withProfile(observer(User))));
