/* eslint-disable react/prop-types */
import React from 'react';
import {Link} from 'react-router-dom';
import routes from '../../constants/routes';

function Empty({text, profileLink, linkTo, linkLabel}) {
  return (
    <section className="section-empty">
      <p>{text || 'Not Found'}</p>
      <div className="actions">
        {profileLink && (
          <Link className="button -small -primary" to={routes.profile.create}>
            Create Profile
          </Link>
        )}
        {linkTo && (
          <Link className="button -small -primary" to={linkTo}>
            {linkLabel}
          </Link>
        )}
      </div>
    </section>
  );
}

export default Empty;
