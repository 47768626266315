/* eslint-disable react/prop-types */
import React from 'react';
import {Form} from 'informed';
import {Modal, Heading, Field, Footer} from '.';
import SubscriptionPlans from '../account/subscription.plans';
import Billing from '../account/billing';

function ModalSubscription({
  selectedProfile,
  selectedPlan,
  subscriptionModalOpen,
  toggleSubscriptionModal,
  couponValue,
  onSubscriptionSubmit,
  onPlanClick,
  products,
  couponMessage,
  stripeCard,
  creditCard,
  creditExpMonth,
  creditExpYear,
  saving,
  setCouponValue,
  onCouponChange,
  couponClass,
}) {
  return (
    <Modal isOpen={subscriptionModalOpen} handleDismiss={e => toggleSubscriptionModal(e)} showDismiss={false}>
      <Heading primary>
        <h4>Edit {selectedProfile ? `${selectedProfile.firstname}'s` : ''} Subscription</h4>
      </Heading>

      <Form className="" initialValues={{...selectedProfile, coupon: couponValue}} onSubmit={onSubscriptionSubmit}>
        <section className="section">
          <SubscriptionPlans selectedProfile={selectedProfile} selectedPlan={selectedPlan} onPlanClick={onPlanClick} products={products} />
        </section>
        <section className="section">
          <div className="profile-form__name">
            <Field
              id="promotional-code"
              prefix="profile-form"
              label="Promotional Code"
              field="coupon"
              placeholder=""
              onKeyUp={e => {
                e.preventDefault();
                setCouponValue(e.target.value);
                onCouponChange(e.target.value);
              }}
            />

            <label htmlFor="promotional-code" className={`profile-form__label ${couponClass}`}>
              {couponMessage}
            </label>
          </div>
        </section>
        <hr />

        <Billing stripeCard={stripeCard} creditCard={creditCard} creditExpMonth={creditExpMonth} creditExpYear={creditExpYear} />

        <div className="sticky_bottom">
          <Footer saving={saving} submitButtonLabel="Subscribe" cancelButtonLabel="Cancel" onCancel={toggleSubscriptionModal} />
        </div>
      </Form>
    </Modal>
  );
}

// ModalSubscription.propTypes = {};

// ModalSubscription.defaultProps = {};

export default ModalSubscription;
