import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'informed';
import {Footer} from '../common';
import CardForm from './billing.payment.card.element';

function BillingPaymentForm({saving, elementFontSize, stripeCard, onInputChange, onAddressChange, onCreditCardChange, onSubscriptionSubmit, setModalOpen}) {
  return (
    <Form className="account-form" initialValues={stripeCard} onSubmit={onSubscriptionSubmit}>
      <h4>Billing Details</h4>

      <section className="input -half">
        <input type="text" name="name" placeholder="Name" value={stripeCard.name} onChange={onInputChange} required />
      </section>

      <section className="input -half">
        <input type="text" name="email" placeholder="Email" value={stripeCard.email} onChange={onInputChange} required />
      </section>

      <div className="input">
        <input type="text" name="line1" placeholder="Address Line One" value={stripeCard.address.line1} onChange={onAddressChange} required />
      </div>

      <div className="input">
        <input type="text" name="line2" placeholder="Address Line Two" value={stripeCard.address.line2} onChange={onAddressChange} />
      </div>

      <div className="input">
        <input type="text" name="city" placeholder="Suburb" value={stripeCard.address.city} onChange={onAddressChange} required />
      </div>

      <div className="input -half">
        <input
          type="text"
          name="state"
          placeholder={stripeCard.address.country === 'AU' ? 'City' : 'State'}
          value={stripeCard.address.state}
          onChange={onAddressChange}
          required
        />
      </div>

      <div className="input -half">
        <input type="text" name="postal_code" placeholder="Postcode" value={stripeCard.address.postal_code} onChange={onAddressChange} required />
      </div>

      <div className="input">
        <select type="text" name="country" placeholder="Country" value={stripeCard.address.country} onChange={onAddressChange} required>
          <option value="AU">Australia</option>
          <option value="NZ">New Zealand</option>
        </select>
      </div>

      <hr />
      <h4>Card Details</h4>

      <CardForm fontSize={elementFontSize} onCreditCardChange={onCreditCardChange} />

      <div className="sticky_bottom">
        <Footer saving={saving} submitButtonLabel="Save" cancelButtonLabel="Cancel" onCancel={event => setModalOpen(false)} />
      </div>
    </Form>
  );
}

BillingPaymentForm.propTypes = {
  stripeCard: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      line1: PropTypes.string,
      line2: PropTypes.string,
      postal_code: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
    }),
  }),
  elementFontSize: PropTypes.string,
  onAddressChange: PropTypes.func,
  onCreditCardChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onSubscriptionSubmit: PropTypes.func,
  saving: PropTypes.bool,
  setModalOpen: PropTypes.func,
};

BillingPaymentForm.defaultProps = {
  stripeCard: {
    name: '',
    email: '',
    address: {
      city: '',
      line1: '',
      line2: '',
      postal_code: '',
      state: '',
      country: 'AU',
    },
  },
  elementFontSize: '16px',
  onAddressChange: () => {},
  onCreditCardChange: () => {},
  onInputChange: () => {},
  onSubscriptionSubmit: () => {},
  saving: false,
  setModalOpen: () => {},
};

export default BillingPaymentForm;
