/* eslint-disable react/prop-types */
import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {isEqual, debounce, isEmpty} from 'lodash';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {db} from '../../firebase';

import {Card} from '../common';
import routes from '../../constants/routes';
import ProfileGoal from '../goal/goal.card';
import ProfileAside from './profile.aside';
import withProfile from './withProfile';
import NotFound from '../errors/notfound';
import getIsOwner from '../../helpers/getIsOwner';
import getIsViewer from '../../helpers/getIsViewer';

function ProfileGoals({profile}) {
  const [prevGoals, setPrevGoals] = useState();
  const [sortItems, setSortItems] = useState([]);
  const isOwner = getIsOwner(profile);
  const isViewer = getIsViewer(profile);

  useEffect(() => {
    // eslint-disable-next-line fp/no-mutating-methods
    const newSortItems = Object.keys(profile.goals)
      .map(goalId => ({...profile.goals[goalId], id: goalId}))
      .sort((a, b) => a.sort - b.sort);
    setSortItems(newSortItems);
    setPrevGoals(newSortItems);
  }, [profile]);

  const onMoveGoal = useCallback((dragIndex, hoverIndex) => {
    setSortItems(prevSortItems =>
      update(prevSortItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevSortItems[dragIndex]],
        ],
      })
    );
  }, []);

  const onDropGoal = debounce(goals => {
    goals.map((goal, index) => {
      db.saveGoal(profile.id, goal.id, {...goal, sort: index});
      return true;
    });
  }, 2000);

  useEffect(() => {
    if (!isEmpty(sortItems) && !isEqual(sortItems, prevGoals)) {
      onDropGoal(sortItems);
    }
  }, [onDropGoal, prevGoals, sortItems]);

  return (
    <div className="container">
      <aside className="aside">
        <ProfileAside showProgress={!isViewer} profileId={profile.id} />
      </aside>

      <DndProvider backend={HTML5Backend}>
        <Card feed>
          {Object.keys(profile.goals || {}).length === 0 && <NotFound text="This profile has no goals set. Add one!" />}

          {sortItems.map((goal, index) => (
            <ProfileGoal goal={{...goal}} key={goal.id} index={index} goalId={goal.id} profileId={profile.id} onMoveGoal={onMoveGoal} />
          ))}
        </Card>
      </DndProvider>
      {isOwner && (
        <Link
          to={routes.generate(routes.profile.goal.create, {
            profileId: profile.id,
          })}
          className="page-action">
          <span className="button -toggle -small -inverse">
            <i className="icon-plus" />
          </span>
          Add Goal
        </Link>
      )}
    </div>
  );
}

export default withProfile(observer(ProfileGoals));
