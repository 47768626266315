/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import * as Sentry from '@sentry/react';
import {onAuthStateChanged} from 'firebase/auth';
import {logEvent} from 'firebase/analytics';
import AuthUserContext from './AuthUserContext';
import {firebase, db} from '../../firebase';
import {analytics} from '../../firebase/firebase';

const withAuthentication = Component =>
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        authLoading: true,
      };
    }

    componentDidMount() {
      onAuthStateChanged(firebase.auth, async authUser => {
        if (authUser) {
          logEvent(analytics, 'signed in');
          Sentry.configureScope(scope => {
            scope.setUser({email: authUser.email, uid: authUser.uid});
          });
          db.getUserByIdReactive(authUser.uid, this.handleUserUpdate);
          this.setState(() => ({
            authUser,
            authLoading: false,
          }));
        } else {
          this.setState(() => ({authUser: null, authLoading: false}));
        }
      });
    }

    handleUserUpdate = update => {
      this.setState(prevState => ({
        authUser: {
          ...prevState.authUser,
          ...update.val(),
          uid: update.key,
          authLoading: false,
        },
      }));
    };

    render() {
      const {authLoading, authUser, ...rest} = this.state;

      return (
        <AuthUserContext.Provider value={authLoading ? undefined : authUser}>
          <Component {...rest} />
        </AuthUserContext.Provider>
      );
    }
  };

export default withAuthentication;
