/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import routes from '../../constants/routes';

import {Card, Button} from '../common';
import Illustration from '../utilities/illustration';
import withAuthorization from '../session/withAuthorization';
import {db} from '../../firebase';
import ImageUpload from '../file-upload/image-upload';

function AccountOnboardPhoto({authUser, history}) {
  const [profileImage, setProfileImage] = useState('');
  const [profileImagePublic, setProfileImagePublic] = useState('');
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  useEffect(() => {
    setProfileImage(authUser.profileImage || '');
    setProfileImagePublic(authUser.profileImagePublic || '');
  }, [authUser, setProfileImage, setProfileImagePublic]);

  const onImageUpload = (file, url) => {
    // Get a public url for this file
    if (file) {
      setProfileImage(file.name);
      setProfileImagePublic(url);
    } else {
      setProfileImage('');
      setProfileImagePublic('');
    }
  };

  const removeImage = e => {
    e.preventDefault();
    setProfileImage('');
    setProfileImagePublic('');
  };

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    await db.saveUser(authUser.uid, {profileImage, profileImagePublic});
    history.push(routes.user.inviteOnboard.about);
  };

  return (
    <div className="page -account">
      <div className="container">
        <Card focus>
          <section className="section">
            <Illustration type="flag" size="large" />

            <h3>Now add a photo of yourself</h3>
            <form onSubmit={onSubmit} className="account-form">
              {profileImagePublic ? (
                <div className="image">
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${profileImagePublic})`,
                    }}
                    alt={`Profile of ${authUser.firstname}`}
                  />
                  <div>
                    <button type="button" className="button -tertiary -tiny" onClick={removeImage}>
                      Remove Image
                    </button>
                  </div>
                </div>
              ) : (
                <div style={{marginBottom: '16px'}}>
                  <ImageUpload
                    inputId={`onboard_photo_image_${authUser.uid}`}
                    inputName={`onboard_photo_image_${authUser.uid}`}
                    onComplete={onImageUpload}
                    value={profileImage}
                    filePath={`/uploads/users/${authUser.uid}/`}
                  />
                </div>
              )}

              <Button submit type="primary" loading={loading}>
                Next
              </Button>

              {error && <p>{error.message}</p>}
            </form>
          </section>
        </Card>
      </div>
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(inject('stores')(observer(AccountOnboardPhoto)));
