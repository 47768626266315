/* eslint-disable react/prop-types */
import React from 'react';

import routes from '../../constants/routes';
import withBreakpoints from '../utilities/withBreakpoints';
import {Tabs} from '../common';

const tabs = [
  {url: routes.user.edit, title: 'Account'},
  {url: routes.user.settings, title: 'Settings'},
  // { url: routes.user.payment, title: 'Payment' },
  {
    url: routes.generate(routes.user.subscription, {profileId: ''}),
    title: 'Subscriptions',
  },
];

function AccountTabs({breakpoint}) {
  return breakpoint === 'desktop' ? <Tabs tabs={tabs} /> : null;
}

export default withBreakpoints()(AccountTabs);
