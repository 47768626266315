export const getPermissionsType = permission => {
  if (typeof permission === 'object') {
    return permission.type;
  }

  return 'family';
};

export const getPermissionsInviteAccepted = (permission, user) => {
  /**
   * Originally the invited flag was set on the user but this didn't work as the user could be
   * invited to mulitple profiles and if they accept one, all where flagged as accepted.
   * We moved the invited flag to the permission table to correct this issue.
   *
   * To handle the old and new user invites, check both locations.
   *
   * The secondary issue is that the flag was renamed at some point from invite to invited,
   * so we need to handle both.
   */
  if (typeof permission === 'object') {
    return permission.invited ?? permission.invite ?? user.invited ?? user.invite;
  }

  return 'family';
};
