import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useField} from 'formik';
import TextareaAutosize from 'react-autosize-textarea';
import classNames from 'classnames';
import {useFallback} from '../../hooks';
import getClassNames from './styleFields';

function FieldTextarea({name, helpertext, prefix, onFocus, label, wrapperClassName, required, readOnly, printing}) {
  const [{value, onChange, onBlur}, {touched, error}, {setValue, setTouched}] = useField(name);

  const [focused, setFocus] = useState(false);

  const memoizedFocus = useFallback(() => setFocus(true), onFocus, [setFocus]);
  const memoizedBlur = useFallback(
    () => {
      setTouched(true);
      setFocus(false);
    },
    onBlur,
    [setTouched, setFocus]
  );
  const memoizedChange = useFallback(e => setValue(e.target.value), onChange, [setValue]);

  const isError = touched && !!error;

  const {labelClassName, invalidClassName, errorClassName, focusClassName} = getClassNames(prefix);

  return (
    <label
      htmlFor="textarea"
      className={classNames(labelClassName, wrapperClassName, {
        [invalidClassName]: isError,
        [focusClassName]: focused,
      })}>
      {!readOnly && required && <span className={`required ${labelClassName}_required`}> * </span>}
      {label}
      {isError && <small className={errorClassName}>{error}</small>}
      {!printing && (
        <TextareaAutosize id="textarea" readOnly={readOnly} value={value} onFocus={memoizedFocus} onBlur={memoizedBlur} onChange={memoizedChange} />
      )}
      {printing && <p>{value}</p>}
      {helpertext && <small>{helpertext}</small>}
    </label>
  );
}

FieldTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  helpertext: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  printing: PropTypes.bool,
  onFocus: PropTypes.func,
};

FieldTextarea.defaultProps = {
  prefix: '',
  helpertext: '',
  wrapperClassName: '',
  required: false,
  readOnly: false,
  printing: false,
  onFocus: null,
};

export default FieldTextarea;
