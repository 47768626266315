/* eslint-disable react/prop-types */
import React, {useCallback, useState, useEffect} from 'react';
import update from 'immutability-helper';
import GoalStep from './goal.step';

function GoalSteps({goalId, listItems, onToggleComplete, onListItemsChange}) {
  const [sortItems, setSortItems] = useState(listItems);

  const onMoveGoalStep = useCallback((dragIndex, hoverIndex) => {
    setSortItems(prevSortItems =>
      update(prevSortItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevSortItems[dragIndex]],
        ],
      })
    );
  }, []);

  useEffect(() => onListItemsChange(sortItems), [onListItemsChange, sortItems]);

  const renderSortItem = useCallback(
    (sortItem, index, sortGoalId) => (
      <GoalStep
        key={sortItem.id}
        index={index}
        id={sortItem.id}
        goalId={sortGoalId}
        text={sortItem.text}
        complete={sortItem.complete}
        count={sortItem.count}
        onToggleComplete={onToggleComplete}
        onMoveGoalStep={onMoveGoalStep}
      />
    ),
    [onMoveGoalStep, onToggleComplete]
  );

  return <div>{sortItems && sortItems.map((sortItem, i) => renderSortItem(sortItem, i, goalId))}</div>;
}

export default GoalSteps;
