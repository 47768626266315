/* eslint-disable react/prop-types */
import React, {useCallback, useMemo} from 'react';
import {Heading, Modal} from '../common';
import SectionItem from './SectionItem';

export default function EditSections({open, setOpen, availableProfileSections, existingSections, firstname, profileId}) {
  const onClose = useCallback(() => setOpen(false), [setOpen]);

  const listOfSections = useMemo(
    () =>
      // eslint-disable-next-line fp/no-mutating-methods
      Object.keys(availableProfileSections)
        .map(key => ({key, ...availableProfileSections[key]}))
        .sort((a, b) => a.index - b.index),
    [availableProfileSections]
  );

  return (
    <div>
      <Modal type="medium" isOpen={open} handleDismiss={onClose} showDismiss>
        <Heading primary>
          <h4>Add / Edit sections</h4>
        </Heading>
        <div>
          {listOfSections.map(item => (
            <SectionItem
              key={item.key}
              item={item}
              firstname={firstname}
              availableProfileSections={availableProfileSections}
              existingSections={existingSections}
              profileId={profileId}
            />
          ))}
        </div>
      </Modal>
    </div>
  );
}
