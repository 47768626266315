/* eslint-disable fp/no-mutating-methods */
/* eslint-disable react/prop-types */
import update from 'immutability-helper';
import React, {useCallback} from 'react';
import {useField} from 'formik';
import GoalStep from './goals.step';
import {Button} from '../common';
import {createUUID} from '../../firebase/db';

function GoalSteps({goalId}) {
  const [{value}, , {setValue}] = useField('steps');

  const onMoveSortItem = useCallback(
    (dragIndex, hoverIndex) => {
      setValue(
        update(value, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, value[dragIndex]],
          ],
        })
      );
    },
    [setValue, value]
  );

  const onRemoveStep = useCallback(
    id => {
      setValue(value.filter(step => step.id !== id));
    },
    [setValue, value]
  );

  const onAddSteps = useCallback(
    e => {
      e.preventDefault();
      const newValue = [...value, {text: '', complete: false, id: createUUID()}];
      console.log('newValue', newValue);
      setValue(newValue);
    },
    [setValue, value]
  );

  const renderSortItem = useCallback(
    (sortItem, index, id) => (
      <GoalStep
        key={sortItem.id}
        index={index}
        stepId={sortItem.id}
        goalId={id}
        text={sortItem.text}
        complete={sortItem.complete}
        count={sortItem.count}
        onRemoveStep={onRemoveStep}
        onMoveGoalStep={onMoveSortItem}
      />
    ),
    [onMoveSortItem, onRemoveStep]
  );

  return (
    <div>
      {value && value.map((sortItem, i) => renderSortItem(sortItem, i, goalId))}
      <div className="goal-edit__section-action">
        <Button size="small" type="primary-light" onClick={onAddSteps}>
          Add Step
        </Button>
      </div>
    </div>
  );
}

export default GoalSteps;
