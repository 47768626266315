/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {inject, observer} from 'mobx-react';

import withProfile from '../profile/withProfile';
import Post from '../post';
import Feed from './index';
import Loading from '../utilities/loading';
import NotFound from '../errors/notfound';

function ProfileFeed(props) {
  const {
    title,
    profile,
    stores: {postsStore, profilesStore},
  } = props;

  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const fetchPosts = async () => {
      await postsStore.getPostListForProfile(profile.id, limit);
      setLoading(false);
    };

    fetchPosts();
  }, [postsStore, profile.id, setLoading, setLimit, limit]);

  const handleClickMorePosts = () => {
    postsStore.loadMorePostsForProfile(profile.id, limit);
    setOffset(offset + limit);
  };

  const profilePosts = postsStore.posts.get(profile.id);

  if (loading && !profilePosts) return <Loading />;
  if (!profilePosts) return <NotFound text="This profile has no posts yet." />;

  // eslint-disable-next-line fp/no-mutating-methods
  const dateSortedFeedData = profilePosts
    .slice()
    .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
    .slice(0, limit + offset)
    .map(post => {
      const newProfile = profilesStore.getProfileById(post.profileId);
      if (!newProfile) {
        return null;
      }
      return <Post data={post} profile={newProfile} key={`post-${post.id}`} />;
    });

  const showLoadMoreButton = true; // profilePosts.length > limit + offset; this is commented out because it doesn't work. We don't have a full profilePosts length.

  return (
    <Feed
      key={`feed-${profile.id}`}
      title={title}
      posts={dateSortedFeedData}
      handleClickMorePosts={handleClickMorePosts}
      showLoadMoreButton={showLoadMoreButton}
    />
  );
}

export default inject('stores')(withProfile(observer(ProfileFeed)));
