/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

import '../../assets/scss/components/_tabs.scss';

function Tabs({tabs}) {
  return (
    <div className="tabs">
      {tabs.map((tab, i) => (
        <NavLink key={i} to={tab.url} className="tab" activeClassName="-active">
          {' '}
          {tab.title}
        </NavLink>
      ))}
    </div>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      active: PropTypes.bool,
    })
  ).isRequired,
};

export default Tabs;
