import React from 'react';
import PropTypes from 'prop-types';
import Button from './button';

function Footer({cancelButtonLabel, onCancel, saveButtonLabel, onSave, submitButtonLabel, saving, children}) {
  return (
    <section className="footer-action">
      {cancelButtonLabel && (
        <Button left disabled={saving} type="primary-light" size="standard" onClick={onCancel}>
          {cancelButtonLabel}
        </Button>
      )}

      {saveButtonLabel && (
        <Button right loading={saving} disabled={saving} type="primary" size="standard" onClick={onSave}>
          {saveButtonLabel}
        </Button>
      )}

      {submitButtonLabel && (
        <Button submit right loading={saving} disabled={saving} type="primary" size="standard">
          {submitButtonLabel}
        </Button>
      )}

      {children}
    </section>
  );
}

Footer.propTypes = {
  cancelButtonLabel: PropTypes.string,
  onCancel: PropTypes.func,
  saveButtonLabel: PropTypes.string,
  onSave: PropTypes.func,
  submitButtonLabel: PropTypes.string,
  saving: PropTypes.bool,
  children: PropTypes.node,
};

Footer.defaultProps = {
  cancelButtonLabel: '',
  onCancel: () => {},
  saveButtonLabel: '',
  onSave: () => {},
  submitButtonLabel: '',
  saving: false,
  children: null,
};

export default Footer;
