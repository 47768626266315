/* eslint-disable react/prop-types */
import React from 'react';

const vimeoWrapperStyle = {padding: '56.25% 0 0 0', position: 'relative'};
const vimeoFrameStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

function VimeoEmbed({videoId, videoName}) {
  return (
    <>
      <div style={vimeoWrapperStyle}>
        <iframe
          title={`${videoName || videoId} by Danny Hui on Vimeo`}
          src={`https://player.vimeo.com/video/${videoId}`}
          style={vimeoFrameStyle}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js" />
      {videoName && (
        <p>
          <a href={`https://vimeo.com/${videoId}`}>{videoName}</a> from <a href="https://vimeo.com/user72205101">Danny Hui</a> on{' '}
          <a href="https://vimeo.com">Vimeo</a>.
        </p>
      )}
    </>
  );
}

export default VimeoEmbed;
