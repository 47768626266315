/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {inject, observer} from 'mobx-react';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  // updateEmail,
  updatePassword,
} from 'firebase/auth';
import {auth} from '../../firebase/firebase';
import {Button} from '../common';

function PasswordChangeForm({onDismiss, stores: {profilesStore, notificationsStore}}) {
  const [passwordCurrent, setPasswordCurrent] = useState('');
  const [passwordOne, setPasswordOne] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async event => {
    event.preventDefault();

    if (!passwordOne || !passwordTwo || passwordOne.length < 6 || passwordTwo.length < 6) {
      setError({message: 'Passwords must be six characters in length'});
      return;
    }
    if (passwordOne !== passwordTwo) {
      setError({message: 'Passwords must match'});
      return;
    }

    try {
      setLoading(true);
      const {currentUser} = auth;
      const cred = EmailAuthProvider.credential(currentUser.email, passwordCurrent);

      await reauthenticateWithCredential(currentUser, cred);

      await updatePassword(currentUser, passwordOne).then(() => {
        setPasswordCurrent('');
        setPasswordOne('');
        setPasswordTwo('');
        setError(null);
        NotificationManager.success('Password updated');
        onDismiss();
      });
    } catch (changePasswordError) {
      switch (changePasswordError.code) {
        case 'auth/wrong-password':
          // eslint-disable-next-line fp/no-mutation
          changePasswordError.message = 'Wrong password entered';
          break;
        case 'auth/multi-factor-auth-required':
          // eslint-disable-next-line fp/no-mutation
          changePasswordError.message = 'Please remove your 2FA first to update your email address, after adding you can restore your 2FA';
          break;
        default:
          break;
      }
      NotificationManager.error(changePasswordError.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="form-password" onSubmit={onSubmit}>
      <section className="section -centered">
        <input value={passwordCurrent} onChange={event => setPasswordCurrent(event.target.value)} type="password" placeholder="Current Password" />
        <input value={passwordOne} onChange={event => setPasswordOne(event.target.value)} type="password" placeholder="New Password" />
        <input value={passwordTwo} onChange={event => setPasswordTwo(event.target.value)} type="password" placeholder="Confirm New Password" />
      </section>

      <section className="footer-action">
        <Button submit right type="primary" loading={loading}>
          Update Password
        </Button>
        <button type="button" className="button -tertiary -left" onClick={onDismiss}>
          Close
        </button>
      </section>

      {error && (
        <section className="article-errors">
          <p>{error.message}</p>
        </section>
      )}
    </form>
  );
}

export default inject('stores')(observer(PasswordChangeForm));
