/* eslint-disable fp/no-mutating-methods */
/* eslint-disable fp/no-mutation */
/* eslint-disable camelcase */
import {v1 as uuid} from 'uuid';
import axios from 'axios';
import dayjs from 'dayjs';
import {
  equalTo,
  get,
  limitToLast,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  remove,
  set,
  update,
  off,
} from 'firebase/database';

import {getDownloadURL, getMetadata, ref as storageRef, uploadBytesResumable, uploadString} from 'firebase/storage';

import {httpsCallable} from 'firebase/functions';
import fileDownload from 'js-file-download';

import routes from '../constants/routes';
import config from '../constants/config';
import {snapshotWithKey} from './utils';
import {database as db, storage, functions, auth} from './firebase';
import checkImageExists from '../helpers/checkImageExists';
import {addResizeSuffixToImage} from '../helpers/functionHelpers';

// eslint-disable-next-line no-promise-executor-return
const delay = ms => new Promise(res => setTimeout(res, ms));

// UUID
export const createUUID = () => uuid();

export const changePermissions = (userId, profileId, data) =>
  set(ref(db, `permissions/${profileId}/${userId}`), data).then(() => set(ref(db, `userPermissions/${userId}/${profileId}`), data));

// Permissions
export const updatePermissions = (profileId, userId, level, type, relationship) =>
  // console.log(new Date().getTime() + ' : DB PERMISSIONS : updatePermissions : ' + profileId);
  changePermissions(userId, profileId, {permission: level, type, relationship});

export const deletePermissions = (profileId, userId) =>
  // console.log(new Date().getTime() + ' : DB PERMISSIONS : deletePermissions : ' + profileId);
  changePermissions(userId, profileId, {permission: 'deleted'});
// Users

export const doCreateUser = (id, firstname, lastname, email, mobile, other = {}) =>
  // console.log(new Date().getTime() + ' : DB USERS : doCreateUser : ' + id);
  set(ref(db, `users/${id}`), {
    firstname,
    lastname,
    email,
    mobile,
    type: 'individual',
    notificationsEnabled: true,
    ...other,
  });

export const doCreateProfessional = (id, firstname, lastname, email, mobile, jobTitle, description, organisation) =>
  // console.log(new Date().getTime() + ' : DB USERS : doCreateUser : ' + id);
  set(ref(db, `users/${id}`), {
    firstname,
    lastname,
    email,
    mobile,
    organisation,
    job_title: jobTitle,
    description,
    type: 'professional',
    notificationsEnabled: true,
  });

export const setUserAccountType = (id, type) => {
  console.log(`${new Date().getTime()} : DB USERS : setUserAccountType : ${id}`);
  return set(ref(db, `users/${id}/type`), type);
};

export const setUserProfileReport = (id, value) => {
  console.log(`${new Date().getTime()} : DB USERS : setUserProfileReport : ${id}`);
  return set(ref(db, `users/${id}/profileReport`), value);
};

export const onceGetUsers = () => {
  console.log(`${new Date().getTime()} : DB USERS : onceGetUsers : `);
  return get(ref(db, 'users'));
};

export const onceGetUser = id => {
  console.log(`${new Date().getTime()} : DB USERS : onceGetUser : ${id}`);
  return get(ref(db, `users/${id}`));
};

export const getUserById = id =>
  // console.log(new Date().getTime() + ' : DB USERS : getUserById : ' + id);
  get(ref(db, `users/${id}`)).then(snapshotWithKey);

export const set2FA = value => {
  const {currentUser} = auth;
  return set(ref(db, `users/${currentUser.uid}/enable2FA`), value);
};

export const getUserByIdReactive = (id, handler) =>
  // console.log(new Date().getTime() + ' : DB USERS : getUserByIdReactive : ' + id);
  onValue(ref(db, `users/${id}`), handler);

export const getSubscriptionReactive = (userId, subscriptionId, handler) =>
  // console.log(new Date().getTime() + ' : DB STRIPE : getSubscriptionReactive : ' + userId);
  onValue(ref(db, `stripe_customers/${userId}/subscriptions/${subscriptionId}`), handler);

export const getSubscriptionById = (userId, subscriptionId) =>
  // console.log(new Date().getTime() + ' : DB STRIPE : getSubscriptionById : ' + userId);
  get(ref(db, `stripe_customers/${userId}/subscriptions/${subscriptionId}`)).then(snapshotWithKey);

export const getProfileTeam = id =>
  // console.log(new Date().getTime() + ' : DB PERMISSIONS : getProfileTeam : ' + id);
  new Promise((resolve, reject) => {
    get(ref(db, `permissions/${id}`)).then(permisionSnapshot => {
      // Fetch all of the profiles you have access to
      const permissions = permisionSnapshot.val();
      if (!permissions) {
        resolve([[], []]);
        return;
      }

      // Reduce the profiles down to ones that the user has permissions for.
      const activePermissions = Object.keys(permissions).reduce((result, e) => {
        if (e === config.sameviewTeamUid || permissions[e].permission !== 'deleted') {
          return {
            ...result,
            [e]: permissions[e],
          };
        }
        return result;
      }, {});

      const profileIds = Object.keys(activePermissions);

      Promise.all(profileIds.map(profileId => get(ref(db, `users/${profileId}`))))
        .then(res => {
          // Build up an object where key = userId value = user details with id.
          const teamMemberObject = {};
          res.forEach((snapshot, idx) => {
            teamMemberObject[profileIds[idx]] = {
              ...snapshot.val(),
              id: profileIds[idx],
            };
          });

          // resolve([permissions, teamMemberObject]);
          resolve([activePermissions, teamMemberObject]);
        })
        .catch(err => {
          reject(err);
        });
    });
  });

export const getProfileTeamReactive = (id, handler) =>
  // console.log(new Date().getTime() + ' : DB PERMISSIONS : getProfileTeamReactive : ' + id);
  onValue(ref(db, `permissions/${id}`), async () => {
    const [permissions, team] = await getProfileTeam(id);
    handler(permissions, team);
  });

export const getAvailableProfileSections = async () => {
  const all = await get(ref(db, 'availableProfileSections')).then(snapshot => snapshot.val());
  Object.keys(all)
    .filter(key => !all[key].active)
    .forEach(key => {
      // eslint-disable-next-line fp/no-delete
      delete all[key];
    });
  return all;
};

// Profiles

export const getProfiles = (userId, handler) =>
  // console.log(new Date().getTime() + ' : DB PERMISSIONS : getProfiles : ' + userId);
  new Promise((resolve, reject) => {
    // NP: We might need to restructure permissions to make this query efficient
    get(ref(db, `userPermissions/${userId}`))
      .then(permissionSnapshot => {
        const permissionData = permissionSnapshot.val();

        if (!permissionData) {
          resolve([]);
          return;
        }

        // Reduce the profiles down to ones that the user has permissions for.
        const arrayOfDeletedProfiles = Object.keys(permissionData).filter(profileId => permissionData[profileId].permission === 'deleted');

        arrayOfDeletedProfiles.forEach(profileId => {
          // eslint-disable-next-line fp/no-delete
          delete permissionData[profileId];
        });

        // Fetch all of the profiles you have access to
        Promise.all(
          Object.keys(permissionData).map(async id => {
            // If the logged in user has been invited to this profile and it hasn't been excepted yet.
            if (typeof permissionData[id] === 'string' || permissionData[id].invited === 'true' || 'invite' in permissionData[id]) {
              const markInviteAcceptedCloudFunction = httpsCallable(functions, 'markInviteAccepted');
              await markInviteAcceptedCloudFunction({id, userId, permission: permissionData[id]});
            }

            onValue(ref(db, `profileSections/${id}`), sectionSnapshot =>
              handler({
                id: sectionSnapshot.key,
                profileSections: {
                  id: sectionSnapshot.key,
                  ...sectionSnapshot.val(),
                },
              })
            );

            onValue(ref(db, `profiles/${id}`), profileSnapshot =>
              handler({
                id: profileSnapshot.key,
                ...profileSnapshot.val(),
              })
            );
            return get(ref(db, `profiles/${id}`));
          })
        ).then(snapshots => {
          Promise.all(
            Object.keys(permissionData).map(profileId => {
              getProfileTeamReactive(profileId, (permissions, team) => {
                handler({
                  id: profileId,
                  team,
                  permissions,
                  permission: permissions[userId],
                });
              });
              return getProfileTeam(profileId);
            })
          ).then(teamInfo => {
            Promise.all(
              Object.values(snapshots).map(teamSnapshot => {
                const {subscription = {}} = teamSnapshot.val();

                getSubscriptionReactive(userId, teamSnapshot.key, async subscriptionSnapshot => {
                  const profileSubscription = await get(ref(db, `profiles/${subscriptionSnapshot.key}/subscription`));
                  const {user_id: subscriptionUserId, saving} = profileSubscription.val() || {user_id: userId, saving: false};

                  handler({
                    id: subscription.order_id,
                    subscription: {
                      ...subscriptionSnapshot.val(),
                      user_id: subscriptionUserId,
                      saving,
                    },
                  });
                });
                return getSubscriptionById(subscription.user_id, subscription.order_id);
              })
            ).then(subscription => {
              Promise.all(Object.keys(permissionData).map(id => get(ref(db, `appointments/${id}`)).then(snapshotWithKey))).then(appointments => {
                Promise.all(
                  Object.keys(permissionData).map(id => {
                    getProfileDocumentsReactive(id, async documentSnapshot => {
                      const nextDocuments = documentSnapshot.val() || {};
                      const nextProfile = {
                        id,
                        documents: Object.entries(nextDocuments)
                          .sort(([, a], [, b]) => b.created_at - a.created_at)
                          .map(([key, values]) => ({id: key, ...values})),
                      };
                      // console.log(nextProfile);
                      handler(nextProfile);
                    });

                    return getDocumentsByProfileId(id);
                  })
                ).then(documents => {
                  const profiles = Object.keys(permissionData).map((profileId, ix) => {
                    const [permissions, team] = teamInfo[ix];
                    const currentProfile = snapshots[ix].val();
                    const currentDocuments = documents[ix].val() || {};
                    const currentSubscription = currentProfile.subscription
                      ? {
                          ...subscription[ix],
                          user_id: currentProfile.subscription.user_id,
                          saving: currentProfile.subscription.saving,
                        }
                      : {};

                    return {
                      ...currentProfile,
                      id: profileId,
                      permission: permissions[userId],
                      documents: Object.entries(currentDocuments)
                        .sort(([, a], [, b]) => b.created_at - a.created_at)
                        .map(([key, values]) => ({id: key, ...values})),
                      subscription: currentSubscription,
                      appointments: appointments[ix],
                      permissions,
                      team,
                    };
                  });
                  resolve(profiles);
                });
              });
            });
          });
        });
      })
      .catch(err => {
        reject(err);
      });
  });

export const createAppointment = (profileId, userId, timestamp) => set(ref(db, `appointments/${profileId}/${userId}`), {timestamp});

export const createProfile = async (id, firstname, lastname, isMyself, type, description = '', other = {}) => {
  // console.log(new Date().getTime() + ' : DB PROFILES : createProfile : ' + id);
  await set(ref(db, `profileSections/${id}/essentials`), {
    firstname,
    lastname,
    description,
    type,
    // other is be country and postcode
    ...other,
  });
  return set(ref(db, `profiles/${id}`), {
    firstname,
    lastname,
    description,
    self: isMyself,
    type,
    ...other,
  });
};

export const createGroup = async (id, firstname, description, other = {}) => {
  // console.log(new Date().getTime() + ' : DB PROFILES : createProfile : ' + id);
  await set(ref(db, `profileSections/${id}/essentials`), {
    firstname,
    lastname: '',
    description,
    self: false,
    type: 'group',
    birthdate: dayjs().unix() * 1000,
    relationship: 'group owner',
    // other is be country and postcode
    ...other,
  });
  return set(ref(db, `profiles/${id}`), {
    firstname,
    lastname: '',
    description,
    self: false,
    type: 'group',
    birthdate: dayjs().unix() * 1000,
    relationship: 'group owner',
    ...other,
  });
};

export const getProfile = id =>
  // console.log(new Date().getTime() + ' : DB PROFILES : getProfile : ' + id);
  get(ref(db, `profiles/${id}`));

export const getProfileWithTeamMembers = id =>
  getProfile(id).then(res =>
    getProfileTeam(id).then(teamRes => ({
      ...res.val(),
      team: teamRes,
    }))
  );

export const saveProfile = async (profileId, profileData) =>
  // console.log(new Date().getTime() + ' : DB PROFILES : saveProfile : ' + profileId);
  update(ref(db, `profileSections/${profileId}/essentials`), {...profileData});
// return update(ref(db, `profiles/${profileId}`), { ...profileData });

export const updateProfile = async (profileId, profileData) =>
  // console.log(new Date().getTime() + ' : DB PROFILES : saveProfile : ' + profileId);
  // await update(ref(db, `profiles/${profileId}`), { ...profileData.essentials });
  update(ref(db, `profileSections/${profileId}`), {...profileData});

export const saveUser = (userId, userData) =>
  // console.log(new Date().getTime() + ' : DB USERS : saveUser : ' + userId);
  update(ref(db, `users/${userId}`), {
    ...userData,
  });

// Goals
export const createGoal = (profileId, goal) =>
  // console.log(new Date().getTime() + ' : DB PROFILES : createGoal : ' + profileId);
  push(ref(db, `profiles/${profileId}/goals/`), {...goal});

export const saveStep = (profileId, goalId, stepId, step) => set(ref(db, `profiles/${profileId}/goals/${goalId}/steps/${stepId}`), step);
export const updateStep = (profileId, goalId, stepIndex, stepData) => update(ref(db, `profiles/${profileId}/goals/${goalId}/steps/${stepIndex}`), stepData);

export const saveMultipleSteps = (profileId, goalId, steps) => set(ref(db, `profiles/${profileId}/goals/${goalId}/steps`), steps);

export const getGoalSteps = (profileId, goalId) =>
  // console.log(new Date().getTime() + ' : DB PROFILES : getGoalSteps : ');
  get(ref(db, `profiles/${profileId}/goals/${goalId}/steps/`));

export const saveGoal = (profileId, goalId, goalData) => {
  // console.log(new Date().getTime() + ' : DB PROFILES : saveGoal : ' + profileId);
  if (!goalId) {
    throw new Error(`Save goal was triggered without a goal ID, profile id = ${profileId}`);
  }
  return update(ref(db, `profiles/${profileId}/goals/${goalId}`), {
    ...goalData,
  });
};

export const deleteGoal = (profileId, goalId) => set(ref(db, `profiles/${profileId}/goals/${goalId}`), null);

export const getStepStarCount = (profileId, stepIdArray) =>
  // console.log(new Date().getTime() + ' : DB POSTS : getStepStarCount : ' + profileId);

  // Count the number of times a step has been used in all the profile's posts
  get(ref(db, `posts/${profileId}/`)).then(snapshot => {
    const posts = snapshot.val();
    if (!posts) return 0;
    return Object.values(posts).reduce((total, currPost) => {
      let count = 0;
      // Early exit in the case of no steps
      if (!currPost.steps) return total;

      // We check in this way so that any steps that have "falsey" names still count
      // e.g "0" etc.  Since the structure is key based we don't need to check for multiple
      // since a post can only have one step. (i.e if we somehow selected 2 steps in the UI
      // it would just map to the one key in the currPost.steps object)
      stepIdArray.forEach(stepId => {
        if (Object.prototype.hasOwnProperty.call(currPost.steps, stepId)) {
          count += 1;
        }
      });
      return total + count;
    }, 0);
  });

// Files
export const uploadFile = (filePath, file, progressCallback, errorCallback, completeCallback, willBeResized = true) => {
  const metadata = {
    name: file.name,
    size: file.size,
    contentType: file.type,
  };

  const fileRef = storageRef(storage, filePath);
  const uploadTask = uploadBytesResumable(fileRef, file, metadata);

  uploadTask.on(
    'state_changed',
    snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      if (progressCallback && typeof progressCallback === 'function') {
        progressCallback(file, progress);
      }
    },
    err => {
      // Success
      if (errorCallback && typeof errorCallback === 'function') {
        errorCallback(file, err);
      }
    },
    async () => {
      // Success
      try {
        if (completeCallback && typeof completeCallback === 'function') {
          const resizedFileName = willBeResized && file.type.match(/(image)/) ? addResizeSuffixToImage(filePath, '_1024x1024') : filePath;
          const url = await checkImageExists(resizedFileName, storage);
          completeCallback(file, url, filePath);
        }
      } catch (error) {
        console.log(error);
      }
    }
  );

  return uploadTask;
};

export const getFileUrl = filePath => getDownloadURL(storageRef(storage, filePath));
export const getFileType = filePath => getMetadata(storageRef(storage, filePath));

// PDF
export const uploadPdf = (filePath, file, progressCallback, errorCallback, completeCallback) => {
  const fileRef = storageRef(storage, filePath);
  const uploadTask = uploadString(fileRef, file.replace('data:application/pdf;base64,', ''), 'base64', {contentType: 'application/pdf'});

  uploadTask.on(
    'state_changed',
    snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      if (progressCallback && typeof progressCallback === 'function') {
        progressCallback(file, progress);
      }
    },
    err => {
      // Error
      if (errorCallback && typeof errorCallback === 'function') {
        errorCallback(file, err);
      }
    },
    async () => {
      // Success
      if (completeCallback && typeof completeCallback === 'function') {
        const url = await getDownloadURL(fileRef);
        completeCallback(file, url, filePath);
      }
    }
  );

  return uploadTask;
};

export const inviteUser = data => {
  const inviteUserCloudFunction = httpsCallable(functions, 'inviteUser');
  return inviteUserCloudFunction(data);
};

export const inviteClient = data => {
  const inviteClientCloudFunction = httpsCallable(functions, 'inviteClient');
  return inviteClientCloudFunction(data);
};

export const adjustUserPermissions = data => {
  const adjustUserPermissionsCloudFunction = httpsCallable(functions, 'adjustUserPermissions');
  return adjustUserPermissionsCloudFunction(data);
};

// Documents

export const createDocument = ({id, profileId, authorId, filename, filelink, ext, description, visibility, metadata}) => {
  const data = {
    filename,
    filelink,
    ext,
    description,
    visibility,
    authorId,
    created_at: dayjs().unix() * 1000,
    ...metadata,
  };

  return set(ref(db, `documents/${profileId}/${id}`), data);
};

export const downloadDocument = (url, name) => {
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then(res => {
      fileDownload(res.data, name);
    });
};

export const updateDocumentVisibility = (id, profileId, visibility) => update(ref(db, `documents/${profileId}/${id}`), {visibility, parentId: 'root'});
export const updateDocumentDescription = (id, profileId, description) => set(ref(db, `documents/${profileId}/${id}/description`), description);

export const removeDocument = (profileId, id) => set(ref(db, `documents/${profileId}/${id}`), null);

export const getDocumentsByProfileId = profileId => get(query(ref(db, `documents/${profileId}`), orderByChild('created_at')));

export const getProfileDocumentsReactive = (profileId, callback) => {
  onValue(query(ref(db, `documents/${profileId}`), orderByChild('created_at')), callback);
};

// Disables the three above handlers
export const removeProfileDocumentsReactiveHandlers = profileId => off(ref(db, `documents/${profileId}`));

// Posts

export const createPost = ({
  id,
  profileId,
  authorId,
  text = null,
  steps = null,
  goals = null,
  members = null,
  uploads = null,
  postImagePublic = null,
  goalCreated = null,
  goalCompleted = null,
  memberInvited = null,
  newUserWelcome = null,
  created_at = undefined,
}) =>
  // console.log(new Date().getTime() + ' : DB POSTS : createPost : ' + id);

  set(ref(db, `posts/${profileId}/${id}`), {
    authorId,
    text,
    steps,
    goals,
    members,
    uploads,
    postImagePublic,
    created_at: created_at || dayjs().unix() * 1000,
    goalCreated,
    goalCompleted,
    memberInvited,
    newUserWelcome,
  });

export const updatePost = ({profileId, id, text, pinned}) => {
  if (pinned) {
    set(ref(db, `pinnedPosts/${profileId}/${id}`), text);
  }
  update(ref(db, `posts/${profileId}/${id}`), {
    updated_at: dayjs().unix() * 1000,
    text,
  });
};

export const deletePost = ({profileId, postId}) => {
  if (!profileId) throw new Error('no profile id');
  if (!postId) throw new Error('no post id');
  set(ref(db, `pinnedPosts/${profileId}/${postId}`), null);
  return set(ref(db, `posts/${profileId}/${postId}`), null);
};

export const setPostPin = ({profileId, postId, pinned, text}) => {
  if (!profileId) throw new Error('no profile id');
  if (!postId) throw new Error('no post id');
  set(ref(db, `posts/${profileId}/${postId}/pinned`), pinned);
  return set(ref(db, `pinnedPosts/${profileId}/${postId}`), pinned && text ? text : null);
};

export const getPinnedPosts = (profileId, handler) => {
  if (!profileId) throw new Error('no profile id');
  return onValue(ref(db, `pinnedPosts/${profileId}`), handler);
};

export const unmountPinnedPosts = profileId => {
  if (!profileId) {
    return;
  }
  off(ref(db, `pinnedPosts/${profileId}`));
};

// Feed

export const getProfilePosts = (profileId, limit) => {
  console.log(`${new Date().getTime()} : DB POSTS : getProfilePosts : ${profileId}`);
  return get(query(ref(db, `posts/${profileId}`), orderByChild('created_at'), limitToLast(limit)));
};

export const getProfilePostsReactive = (profileId, limit, callback) => {
  console.log(`${new Date().getTime()} : DB POSTS : getProfilePostsReactive : ${profileId}`);
  return onChildAdded(query(ref(db, `posts/${profileId}`), orderByChild('created_at'), limitToLast(limit)), callback);
};

export const getProfilePostChangedReactive = (profileId, limit, callback) => {
  console.log(`${new Date().getTime()} : DB POSTS : getProfilePostChangedReactive : ${profileId}`);
  return onChildChanged(query(ref(db, `posts/${profileId}`), orderByChild('created_at'), limitToLast(limit)), callback);
};

export const getProfilePostsDeleteReactive = (profileId, limit, callback) => {
  console.log(`${new Date().getTime()} : DB POSTS : getProfilePostsDeleteReactive : ${profileId}`);
  return onChildRemoved(query(ref(db, `posts/${profileId}`), orderByChild('created_at'), limitToLast(limit)), callback);
};

// Disables the three above handlers
export const removeProfileReactiveHandlers = profileId => {
  console.log(`${new Date().getTime()} : DB POSTS : removeProfileReactiveHandlers : ${profileId}`);
  return off(ref(db, `posts/${profileId}`));
};

export const getPostById = (profileId, postId) => {
  console.log(`${new Date().getTime()} : DB POSTS : getPostById : ${profileId}`);
  return get(ref(db, `posts/${profileId}/${postId}`)).then(snapshotWithKey);
};

export const getGoalPostsReactive = (profileId, goalId, callback) => {
  console.log(`${new Date().getTime()} : DB POSTS : getGoalPostsReactive : ${profileId}`);
  return onChildAdded(query(ref(db, `posts/${profileId}`), orderByChild(`goals/${goalId}`), equalTo(true)), callback);
};

export const getGoalPostChangedReactive = (profileId, goalId, callback) => {
  console.log(`${new Date().getTime()} : DB POSTS : getGoalPostChangedReactive : ${profileId}`);
  return onChildChanged(query(ref(db, `posts/${profileId}`), orderByChild(`goals/${goalId}`), equalTo(true)), callback);
};

export const getGoalPostsDeleteReactive = (profileId, goalId, callback) => {
  console.log(`${new Date().getTime()} : DB POSTS : getGoalPostsDeleteReactive : ${profileId}`);
  return onChildRemoved(query(ref(db, `posts/${profileId}`), orderByChild(`goals/${goalId}`), equalTo(true)), callback);
};

export const getPostChildCreateHandler = (profileId, postId, callback) => onChildAdded(ref(db, `posts/${profileId}/${postId}`), callback);
export const getPostChildUpdateHandler = (profileId, postId, callback) => onChildChanged(ref(db, `posts/${profileId}/${postId}`), callback);
export const getPostChildDeleteHandler = (profileId, postId, callback) => onChildRemoved(ref(db, `posts/${profileId}/${postId}`), callback);

export const getFeedDataByProfile = profileId => {
  console.log(`${new Date().getTime()} : DB POSTS : getFeedDataByProfile : ${profileId}`);
  return get(ref(db, `posts/${profileId}`))
    .then(snapshots => snapshots.val())
    .then(feedObject =>
      Object.keys(feedObject || {}).map(postKey => ({
        ...feedObject[postKey],
        id: postKey,
      }))
    );
};

// Comments

export const createPostComment = ({id, profileId, postId, comment, authorId}) => {
  if (!id) return Promise.reject(new Error('Create Post Error: No comment id'));
  if (!profileId) return Promise.reject(new Error('Create Post Error: No profile id'));
  if (!postId) return Promise.reject(new Error('Create Post Error: No post id'));
  if (!comment) return Promise.reject(new Error('Create Post Error: No comment'));
  if (!authorId) return Promise.reject(new Error('Create Post Error: No author'));

  // note: we pass profileId for efficiency on server side when sending notifications
  // not ideal but saves pulling in all profiles just to send notifications in cloud function
  return set(ref(db, `comments/${postId}/${id}`), {
    comment,
    authorId,
    profileId,
    created_at: dayjs().unix() * 1000,
  });
};

export const subscribeToPostComments = (postId, handler) => onValue(ref(db, `comments/${postId}`), handler);

export const unsubscribeToPostComments = (postId, handler) => off(ref(db, `comments/${postId}`), handler);

export const resolveCommentData = comment => onceGetUser(comment.authorId).then(snapshotWithKey);

export const deleteComment = ({profileId, postId, commentId}) => {
  if (!profileId) return Promise.reject(new Error('no profile id'));
  if (!postId) return Promise.reject(new Error('no post id'));
  if (!commentId) return Promise.reject(new Error('no comment id'));
  return set(ref(db, `comments/${postId}/${commentId}`), null);
};

export const createPostLike = ({uid, profileId, postId}) => set(ref(db, `posts/${profileId}/${postId}/likes/${uid}`), true);

export const removePostLike = ({uid, profileId, postId}) => set(ref(db, `posts/${profileId}/${postId}/likes/${uid}`), null);

// Notifications

export const getNotifications = (uid, handlers) => {
  const {createHandler, changeHandler, deleteHandler} = handlers;

  onChildAdded(ref(db, `notifications/${uid}`), createHandler);
  onChildChanged(ref(db, `notifications/${uid}`), changeHandler);
  onChildRemoved(ref(db, `notifications/${uid}`), deleteHandler);
};

export const addNotification = (uid, data) => {
  const notificationId = createUUID();
  set(ref(db, `notifications/${uid}/${notificationId}`), {
    ...data,
    created_at: dayjs().unix() * 1000,
  });
};

export const setNotificationSeen = (uid, notificationId, value) => {
  console.log(`${new Date().getTime()} : DB NOTIFICATIONS : setNotificationSeen : ${uid} : ${notificationId}`);
  const seen = value ? Date.now() : false;
  return set(ref(db, `notifications/${uid}/${notificationId}/seen`), seen);
};

export const setUserEmailNotificationsState = (uid, value) => set(ref(db, `users/${uid}/notificationsEnabled`), value);

// Payment Gateway

export const getStripeCustomers = (uid, callback) => {
  console.log(`${new Date().getTime()} : DB STRIPE : getStripeCustomers : ${uid}`);
  return onValue(ref(db, `stripe_customers/${uid}`), callback);
};

export const addStripeCustomers = (uid, customer) => {
  console.log(`${new Date().getTime()} : DB STRIPE : addStripeCustomers : ${uid}`);
  return set(ref(db, `/stripe_customers/${uid}`), customer);
};

export const updateStripeCustomers = (uid, customer) => {
  console.log(`${new Date().getTime()} : DB STRIPE : updateStripeCustomers : ${uid}`);
  return update(ref(db, `/stripe_customers/${uid}`), customer);
};

export const getStripeProducts = callback => {
  console.log(`${new Date().getTime()} : DB STRIPE : getStripeProducts : `);
  return onValue(ref(db, `stripe_products`), callback);
};

export const getStripeCustomerId = async uid => {
  console.log(`${new Date().getTime()} : DB STRIPE : getStripeCustomerId : ${uid}`);
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => reject(new Error('Please contact sameview support to set up your billing account')), 15000);
    try {
      getStripeCustomers(uid, snapshot => {
        try {
          const stripeCustomer = snapshot.val();
          if (stripeCustomer && stripeCustomer.customer_id) {
            clearTimeout(timeout);
            resolve(stripeCustomer.customer_id);
          }
        } catch (e) {
          clearTimeout(timeout);
          reject(e);
        }
      });
    } catch (e) {
      clearTimeout(timeout);
      reject(e);
    }
  });
};

export const getStripePlanId = async () => {
  console.log(`${new Date().getTime()} : DB STRIPE : getStripePlanId : `);
  return new Promise((resolve, reject) => {
    try {
      getStripeProducts(snapshot => {
        try {
          const products = snapshot.val();
          const [productId] = Object.keys(products);
          const [planId] = Object.entries(products[productId].plans).find(([, plan]) => plan.active && plan.metadata && plan.metadata.default === 'true');

          if (!planId) {
            reject(new Error("Couldn't find the default plan"));
          }

          resolve(products[productId].plans[planId].id);
        } catch (e) {
          reject(e);
        }
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const getStripeSubscriptions = (uid, limit, callback) => {
  console.log(`${new Date().getTime()} : DB STRIPE : getStripeSubscriptions : ${uid}`);
  return onValue(ref(db, `stripe_customers/${uid}/subscriptions`), callback);
};

export const updateStripeSubscriptions = (profileId, plan) => {
  console.log(`${new Date().getTime()} : DB PROFILES : updateStripeSubscriptions : ${profileId}`);
  remove(ref(db, `profiles/${profileId}/cancelled`));
  return update(ref(db, `profiles/${profileId}/subscription`), {
    ...plan,
    saving: true,
  });
};

export const syncStripeSubscriptions = data => {
  const doSyncStripeSubscriptions = httpsCallable(functions, 'syncStripeSubscriptions');
  return doSyncStripeSubscriptions(data);
};

export const getStripeCoupon = id => {
  console.log(`${new Date().getTime()} : DB STRIPE : getStripeCoupon : ${id}`);
  return get(ref(db, `stripe_coupons/${id}`)).then(snapshotWithKey);
};

export async function onOnboardingComplete(profileId, invites, posts, goal = undefined) {
  const promises = [];
  const authorId = auth.currentUser.uid;
  const stepsPost = {};

  // goal start
  if (goal) {
    const steps = {};
    goal.steps.forEach(step => {
      const id = createUUID();
      steps[id] = step;
      stepsPost[id] = true;
    });
    const obj = {name: goal.name, steps, authorId, complete: true};
    promises.push(createGoal(profileId, obj));
  }

  await delay(2000);

  const time = dayjs().unix() * 1000;
  const invitesTime = time - 120000;
  const postsTime = time - 60000;

  // invites
  const loginUrl = `${window.location.origin}${routes.user.log_in}`;
  invites.forEach(person => {
    const obj = {
      firstName: person.first_name,
      lastName: person.last_name,
      email: person.email,
      relationship: person.relationship,
      type: person.type,
      inviteMessage: person.message,
      permissionLevel: person.permissions,
      profileId,
      loginUrl,
      created_at: invitesTime, // For posting about it
    };
    promises.push(inviteUser(obj));
  });
  // posts
  if (posts.body) {
    const id = createUUID();
    promises.push(
      createPost({
        created_at: postsTime,
        id,
        profileId,
        authorId,
        text: posts.body,
        newUserWelcome: true,
      })
    );
  }

  promises.push(saveProfile(profileId, {onboardingCompleted: time}));

  await Promise.all(promises);
}
