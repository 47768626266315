/* eslint-disable fp/no-mutation */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'informed';

import {Button, Avatar} from '../common';
import PermissionsSection from '../form/form.permissions';

import '../../assets/scss/components/team/family-permissions.scss';

function TeamFamilyForm({loading, onRemoveAccess, onRemoveDisabled, onRemoveAccessTooltip, isOwner}) {
  const opts = {};
  if (onRemoveAccess) opts.onClick = onRemoveAccess;
  if (onRemoveAccessTooltip) opts['data-tooltip'] = onRemoveAccessTooltip;
  if (onRemoveDisabled) opts.disabled = true;

  return (
    <>
      <PermissionsSection field="permissions" prefix="family-permissions" isOwner={isOwner} />

      {isOwner && onRemoveAccess && (
        <button type="button" className="button -link -mini" {...opts}>
          Remove Access
        </button>
      )}

      {isOwner && (
        <Button type="secondary-light" submit right loading={loading}>
          Save
        </Button>
      )}
    </>
  );
}

function TeamFamilyCard({
  name,
  relationship,
  permissions = 'owner',
  avatarSrc,
  onSubmit,
  onRemoveAccess,
  onRemoveDisabled,
  onRemoveAccessTooltip,
  loading,
  forwardedRef,
  isOwner,
}) {
  return (
    <div className="family-permissions" ref={forwardedRef}>
      <Avatar className="family-permissions__profile" src={avatarSrc} />
      <div className="family-permissions__detail">
        <h3 className="family-permissions__name">{name}</h3>
        <h4 className="family-permissions__relationship">{relationship}</h4>
      </div>
      <Form
        key={permissions}
        className="family-permissions__form"
        render={() => (
          <TeamFamilyForm
            loading={loading}
            onRemoveAccess={onRemoveAccess}
            onRemoveDisabled={onRemoveDisabled}
            onRemoveAccessTooltip={onRemoveAccessTooltip}
            isOwner={isOwner}
          />
        )}
        initialValues={{permissions}}
        onSubmit={values => onSubmit({...values})}
      />
    </div>
  );
}

TeamFamilyCard.defaultProps = {
  permissions: 'owner',
  avatarSrc: '',
  onSubmit: () => {},
  onRemoveAccess: () => {},
  isOwner: false,
};

TeamFamilyCard.propTypes = {
  name: PropTypes.string.isRequired,
  relationship: PropTypes.string.isRequired,
  permissions: PropTypes.oneOf(['owner', 'admin', 'viewer', 'deleted']),
  avatarSrc: PropTypes.string,
  onSubmit: PropTypes.func,
  onRemoveAccess: PropTypes.func,
  isOwner: PropTypes.bool,
};

export default React.forwardRef((props, ref) => <TeamFamilyCard {...props} forwardedRef={ref} />);
