/* eslint-disable react/prop-types */
import React, {useRef, useCallback, useEffect} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {RiArrowUpDownLine} from 'react-icons/ri';

import {Scope} from 'informed';
import {Icon} from '../common';
import TextField from '../Inputs/TextField';

function GoalStep({stepId, index, onRemoveStep, onMoveGoalStep, autoFocus = false}) {
  const ref = useRef(null);
  const focusInputRef = useRef();

  useEffect(() => {
    if (autoFocus) {
      focusInputRef.current.focus();
    }
  }, [stepId, autoFocus]);

  const [{handlerId}, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      onMoveGoalStep(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{isDragging}, drag] = useDrag({
    type: 'card',
    item: () => ({stepId, index}),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleRemoveStep = useCallback(
    e => {
      e.preventDefault();
      onRemoveStep(stepId);
    },
    [onRemoveStep, stepId]
  );

  const opacity = isDragging ? 0.3 : 1;

  drag(drop(ref));

  return (
    <div className="goal-edit__step step__draggable" ref={ref} style={{opacity}} data-handler-id={handlerId}>
      <Scope scope={`steps[${stepId}]`}>
        <TextField
          prefix="goal-edit"
          name={`steps[${index}].text`}
          placeholder="Details on what steps you're working on towards this goal"
          ref={focusInputRef}
        />

        <RiArrowUpDownLine className="draggable" />
        <button type="button" style={{paddingTop: 0, paddingBottom: 0}} className="button -icon" onClick={handleRemoveStep}>
          <Icon type="trash" />
        </button>
      </Scope>
    </div>
  );
}

export default GoalStep;
