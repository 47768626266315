/* eslint-disable react/prop-types */
import React, {useState, useCallback, useMemo} from 'react';
import {withRouter} from 'react-router';

import {Card, Panel} from '../common';

import ProfileCompletionOnboarding from './profile.completion.onboarding';
import routes from '../../constants/routes';
import OnboardingSteps from './OnboardingSteps';
import {onOnboardingComplete} from '../../firebase/db';

import '../../assets/scss/components/onboarding/user-onboard.scss';

function UserOnboard({profileId, history}) {
  const [step, setStep] = useState(1);

  const handleSteps = useCallback(value => setStep(value), []);

  const onComplete = useCallback(
    async ({invites, posts, goal}) => {
      onOnboardingComplete(profileId, invites, posts, goal);
      // eslint-disable-next-line react/prop-types
      history.push(routes.generate(routes.profile.onboard.done, {profileId, loading: true}));
    },
    [profileId, history]
  );

  // eslint-disable-next-line no-nested-ternary
  const link = useMemo(() => `https://player.vimeo.com/video/${step === 3 ? '344929662' : step === 2 ? '344929749' : '344929775'}`, [step]);

  return (
    <div>
      <OnboardingSteps step={step} />
      <Card panels wide className="user-onboard">
        <Panel secondary third compact>
          <h2 className="user-onboard__heading">Build your team</h2>
          <p>Let&apos;s begin with adding your team so everyone can be on the same page.</p>
          <p>You can invite: doctors, therapists, educators, support workers, family, and friends.</p>
          <div className="user-onboard_video">
            <iframe title="vimeo-player" src={link} width="100%" height="250" frameBorder="0" allowFullScreen />
          </div>
        </Panel>
        <Panel className="user-onboard__panel">
          <ProfileCompletionOnboarding onChangeData={handleSteps} profileId={profileId} onComplete={onComplete} />
        </Panel>
      </Card>
    </div>
  );
}

export default React.memo(withRouter(UserOnboard));
